import { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import {
  BodyRegular,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";

export type DistributionChartItem = {
  name: string;
  value: number;
};

type DistributionItemStyleProps = {
  width: string;
};

const DistributionItemStyle = styled.div<DistributionItemStyleProps>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: ${(props) => props.width};
  cursor: pointer;
  transition: 0.2s;
  border-radius: 8px;
  border: 8px solid;
  border-color: transparent;
  background-color: ${(props) => props.theme.bg2};
  &:hover {
    background-color: ${(props) => props.theme.blue100};
    border-color: ${(props) => props.theme.blue100};
  }
`;

type Props = {
  data: DistributionChartItem[];
  onClickItem: (dataItemName: string) => void;
};

export const DistributionChart = (props: Props) => {
  const { data, onClickItem } = props;
  const theme = useContext(ThemeContext);

  const totalData = data.reduce((acc, curr) => acc + curr.value, 0);
  const dataWithPercentage = data
    .map((tech) => ({
      ...tech,
      percentage: (tech.value / totalData) * 100,
    }))
    .filter((tech) => tech.percentage > 5);

  const colors = [
    theme.blue700,
    theme.blue600,
    theme.blue500,
    theme.blue300,
    theme.blue100,
  ];

  return (
    <Flex align="center" gap="8px" className="w-100">
      {dataWithPercentage.map((dataItem, i) => (
        <DistributionItemStyle
          width={`${dataItem.percentage}%`}
          onClick={() => onClickItem(dataItem.name)}
        >
          <Tooltip
            isTextTruncate
            content={
              <span>
                {dataItem.name}: <strong>{dataItem.value}</strong>
              </span>
            }
          >
            <Flex column gap="4" style={{ overflowX: "clip" }}>
              <HeaderSecondary
                className="text-truncate"
                style={{ color: theme.textSecondary }}
              >
                {dataItem.value}
              </HeaderSecondary>
              <BodyRegular
                className="text-truncate"
                style={{ color: theme.textSub }}
              >
                {dataItem.name}
              </BodyRegular>
            </Flex>
          </Tooltip>

          <div
            className="w-100"
            style={{
              height: "8px",
              backgroundColor: colors[i],
              borderRadius: "24px",
            }}
          />
        </DistributionItemStyle>
      ))}
    </Flex>
  );
};
