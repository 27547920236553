import { Flex } from "../../../components/layouts/flex/Flex";
import { Switch } from "../../../components/elements/switch/Switch";
import {
  BodyRegular,
  HeaderMain,
} from "../../../components/elements/typography/Typography";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { useContext, useEffect, useRef, useState } from "react";
import {
  AdminFindingEdit,
  Attachment,
  Finding,
  FindingLoadingMap,
  emptyFinding,
  initialFindingLoadingMap,
} from "../../../types/Finding";
import { useApiCustomers } from "../../../hooks/queries/customersContext";
import { useApiProjects } from "../../../hooks/queries/projectsContext";
import {
  useApiCreateFinding,
  useApiCreateFindingAttachments,
  useApiCreateFindingImages,
  useApiFindingAttachments,
  useApiSingleFinding,
  useApiUpdateFinding,
} from "../../../hooks/queries/findingContext";
import { uploadFindingTextAndImages } from "./ImageUtils";
import { BasicInformation } from "./sub-components/BasicInformation";
import { ProjectDetails } from "./sub-components/ProjectDetails";
import { AttackDetails } from "./sub-components/AttackDetails";
import { RiskAssessment } from "./sub-components/RiskAssessment";
import { MitigationAssessment } from "./sub-components/MitigationAssessment";
import { ThemeContext } from "styled-components";
import { WarningModal } from "../../../components/composed/warningModal/WarningModal";
import { ConfirmModal } from "../../../components/composed/confirmModal/ConfirmModal";
import { LoadingLine } from "../../../components/elements/loading/LoadingLine";
import {
  FormModeState,
  FormSubmissionState,
  getEmptyAssigneeInsertedFinding,
  getEmptyCustomerInsertedFinding,
  useUpdateFindingInPlace,
} from "../../../shared/formUtils";
import { useLocalStorage } from "../../../hooks/localStorageHooks";
import AlertBanner from "../../../components/elements/toastTypes/AlertBanner";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import { useParams } from "react-router";
import { Loading } from "../../../components/elements/loading/Loading";
import { Link } from "react-router-dom";
import { useIsSuperuser } from "../../../hooks/useIsSuperuser";
import { useApiMe } from "../../../hooks/queries/meContext";
import { FindingAttachments } from "./sub-components/FindingAttachments";
import useToastContext from "../../../hooks/toastHook";

export const FindingForm = () => {
  const theme = useContext(ThemeContext);
  const { data: me } = useApiMe();
  const addToast = useToastContext();
  const isSuperuser = useIsSuperuser();
  const { id: updateFindingId } = useParams();

  const { data: customers } = useApiCustomers();
  const { data: projects } = useApiProjects({ "admin-mode": true });

  const { mutate: createFinding } = useApiCreateFinding();
  const { mutate: updateFinding } = useApiUpdateFinding({ "admin-mode": true });
  const { mutate: createImage } = useApiCreateFindingImages();
  const { mutate: createAttachments } = useApiCreateFindingAttachments();
  const { data: currentAttachmentsNames } = useApiFindingAttachments(
    updateFindingId ? parseInt(updateFindingId) : undefined
  );

  const {
    data: fetchedFindingData,
    isFetching: isFetchingFinding,
    refetch,
  } = useApiSingleFinding(
    updateFindingId ? parseInt(updateFindingId) : undefined,
    true
  );

  // FORM STATES
  const [formMode, setFormMode] = useState<FormModeState>(
    updateFindingId ? FormModeState.Update : FormModeState.Create
  );
  const [submissionState, setSubmissionState] = useState<FormSubmissionState>(
    FormSubmissionState.Initial
  );
  const [loadingStates, setLoadingStates] = useState<FindingLoadingMap>(
    initialFindingLoadingMap
  );
  const [findingAttachments, setAttachments] = useState<Attachment[]>([]);

  // RTE HANDLING
  const clearBasicInfoRTE = useRef(false);
  const clearAttackDetailsRTE = useRef(false);
  const clearMitigationRTE = useRef(false);
  const initialValueTriggerBasicInfoRTE = useRef(true);
  const initialValueTriggerAttackDetailsRTE = useRef(true);
  const initialValueTriggerMitigationRTE = useRef(true);

  const defaultFinding = me?.is_superuser
    ? getEmptyAssigneeInsertedFinding(me)
    : me?.customer.is_multi_tenant
      ? getEmptyCustomerInsertedFinding(me)
      : emptyFinding;

  // FORM HANDLING STATES
  const [
    createFindingData,
    setCreateFinding,
    deleteCreateFinding,
    isSizeLimitExceeded,
  ] = useLocalStorage<AdminFindingEdit>("findingForm", defaultFinding, false);

  const [updateFindingData, setUpdateFinding] = useState<Finding | undefined>(
    undefined
  );

  const [editableUpdateFindingData, setEditableUpdateFinding] =
    useState<AdminFindingEdit | null>(null);

  const [updateInPlace] = useUpdateFindingInPlace(
    parseInt(updateFindingId ? updateFindingId : "0"),
    setUpdateFinding,
    updateFindingData,
    setLoadingStates
  );

  // Quick & dirty solution for down scrolling
  // Bug caused by RTE.
  const refTopOfPage = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setTimeout(() => {
      if (refTopOfPage.current)
        refTopOfPage.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
    }, 400);
  }, []);

  // Check form mode on initial render
  useEffect(() => {
    if (!updateFindingId) {
      setFormMode(FormModeState.Create);
      clearBasicInfoRTE.current = true;
      clearAttackDetailsRTE.current = true;
      clearMitigationRTE.current = true;
      initialValueTriggerBasicInfoRTE.current = true;
      initialValueTriggerAttackDetailsRTE.current = true;
      initialValueTriggerMitigationRTE.current = true;
    } else {
      setFormMode(FormModeState.Update);
    }
    // eslint-disable-next-line
  }, [updateFindingId]);

  // Once we get the finding we want to update - create 2 copies
  useEffect(() => {
    if (fetchedFindingData && updateFindingData === undefined) {
      setEditableUpdateFinding({ ...fetchedFindingData });
      setUpdateFinding({ ...fetchedFindingData });
    }
    // eslint-disable-next-line
  }, [fetchedFindingData]);

  // FORM VALIDATION STATES
  const [isSubmitClicked, setIsSubmitClicked] = useState<boolean>(false);
  const [isCorrectTitle, setCorrectTitle] = useState<boolean>(
    formMode === FormModeState.Create
      ? createFindingData.title !== ""
      : updateFindingData?.title !== ""
  );
  const [isCorrectCustomer, setCorrectCustomer] = useState<boolean>(
    me?.customer.is_multi_tenant
      ? true
      : formMode === FormModeState.Create
        ? createFindingData.customer === 0
        : updateFindingData?.customer === 0
  );
  const [isCorrectProject, setCorrectProject] = useState<boolean>(
    formMode === FormModeState.Create
      ? createFindingData.project !== 0
      : updateFindingData?.project !== 0
  );
  const [isCorrectAssignee, setCorrectAssignee] = useState<boolean>(
    me?.customer.is_multi_tenant
      ? true
      : formMode === FormModeState.Create
        ? !!createFindingData.op_jira_assignee
        : !!updateFindingData?.op_jira_assignee
  );
  const [isCorrectImpact, setCorrectImpact] = useState<boolean>(
    formMode === FormModeState.Create
      ? createFindingData.impact !== undefined &&
          createFindingData.impact !== null
      : !!updateFindingData &&
          updateFindingData.impact !== undefined &&
          updateFindingData.impact !== null
  );
  const [isCorrectAssets, setCorrectAssets] = useState<boolean>(
    formMode === FormModeState.Create
      ? !!createFindingData.affected_assets &&
          createFindingData.affected_assets.length > 0
      : !!updateFindingData?.affected_assets &&
          updateFindingData?.affected_assets.length > 0
  );
  const [isCorrectOverallRisk, setCorrectOverallRisk] = useState<boolean>(
    formMode === FormModeState.Create
      ? createFindingData.overall_risk !== undefined &&
          createFindingData.overall_risk !== null &&
          createFindingData.overall_risk > -1
      : !!updateFindingData &&
          updateFindingData.overall_risk !== undefined &&
          updateFindingData.overall_risk !== null &&
          updateFindingData.overall_risk > -1
  );
  const [isCorrectExploitability, setCorrectExploitability] = useState<boolean>(
    formMode === FormModeState.Create
      ? createFindingData.exploitability !== undefined &&
          createFindingData.exploitability !== null
      : !!updateFindingData &&
          updateFindingData.exploitability !== undefined &&
          updateFindingData.exploitability !== null
  );
  const [isCorrectCvssScore, setCorrectCvssScore] = useState<boolean>(
    formMode === FormModeState.Create
      ? createFindingData.cvss_score !== undefined &&
          createFindingData.cvss_score !== null &&
          createFindingData.cvss_score >= 0 &&
          createFindingData.cvss_score <= 10
      : !!updateFindingData &&
          updateFindingData.cvss_score !== undefined &&
          updateFindingData.cvss_score !== null &&
          updateFindingData.cvss_score >= 0 &&
          updateFindingData.cvss_score <= 10
  );

  const validateForm = () => {
    return (
      isCorrectTitle &&
      isCorrectCustomer &&
      isCorrectProject &&
      isCorrectAssignee &&
      isCorrectImpact &&
      isCorrectAssets &&
      isCorrectOverallRisk &&
      isCorrectExploitability &&
      !isSizeLimitExceeded
    );
  };

  const createFindingSubmission = () => {
    createFinding({
      ...createFindingData,
      onSuccessCallback(data) {
        const findingId = data.id;

        uploadFindingTextAndImages(
          findingId,
          createFindingData.description_wasp || "",
          createFindingData.attack_details_wasp || "",
          createFindingData.mitigation_wasp || "",
          createFindingData.description || "",
          createFindingData.attack_details || "",
          createFindingData.mitigation || "",
          createFindingData.description_html || "",
          createFindingData.attack_details_html || "",
          createFindingData.mitigation_html || "",
          createImage,
          updateFinding,
          setSubmissionState
        );

        if (findingAttachments.length > 0)
          createAttachments({
            findingId: findingId,
            attachments: findingAttachments,
            onSuccessCallback(attachments) {
              addToast({
                message: `Created attachments to new finding: ${attachments}`,
                type: "success",
              });
            },
            onErrorCallback(error) {
              addToast({
                message: `Failed to upload attachments. Error:${error} `,
                type: "error",
              });
            },
          });
      },
      onErrorCallback() {
        setSubmissionState(FormSubmissionState.FailedSubmission);
      },
    });
  };

  const onSubmit = () => {
    setSubmissionState(FormSubmissionState.Loading);
    if (!validateForm()) {
      setSubmissionState(FormSubmissionState.FailedValidation);
      return;
    }
    if (!createFindingData.is_pending) {
      setSubmissionState(FormSubmissionState.AreYouSure);
    } else {
      createFindingSubmission();
    }
  };

  const clearForm = () => {
    // Delete all form inputs
    setCreateFinding(
      me?.customer.is_multi_tenant
        ? getEmptyCustomerInsertedFinding(me)
        : emptyFinding
    );
    clearBasicInfoRTE.current = true;
    clearAttackDetailsRTE.current = true;
    clearMitigationRTE.current = true;

    // Reset form validation states
    setCorrectTitle(false);
    setCorrectCustomer(
      me?.customer.is_multi_tenant
        ? true
        : formMode === FormModeState.Create
          ? createFindingData.customer === 0
          : updateFindingData?.customer === 0
    );
    setCorrectProject(false);
    setCorrectAssignee(
      me?.customer.is_multi_tenant
        ? true
        : formMode === FormModeState.Create
          ? !!createFindingData.op_jira_assignee
          : !!updateFindingData?.op_jira_assignee
    );
    setCorrectImpact(false);
    setCorrectAssets(false);
    setCorrectOverallRisk(false);
    setCorrectExploitability(false);
    setCorrectCvssScore(true);
  };

  if (!isSuperuser && !me?.customer.is_multi_tenant)
    return (
      <HeaderMain>
        Access Denied: You do not have the necessary permissions to view this
        page.
      </HeaderMain>
    );
  if (
    formMode === FormModeState.Update &&
    (isFetchingFinding || updateFindingData === undefined)
  )
    return (
      <Flex column h100 w100 justify="center" align="center">
        <Loading />
      </Flex>
    );
  return (
    <>
      <Flex column gap="16px">
        <div ref={refTopOfPage}></div>
        {submissionState === FormSubmissionState.Loading && (
          <LoadingLine width="100%" height="5px" />
        )}
        <Flex justify="between">
          <HeaderMain>
            {!!updateFindingId ? "Update Finding" : "Create Finding"}
          </HeaderMain>
          <Flex gap="8px">
            {formMode === FormModeState.Create && (
              <>
                <TextButton
                  label={"Clear Form"}
                  size="large"
                  onClick={clearForm}
                />
                <MainButton
                  label={"Submit"}
                  size="large"
                  iconName="wasp"
                  onClick={() => {
                    setIsSubmitClicked(true);
                    onSubmit();
                  }}
                />
              </>
            )}
            {formMode === FormModeState.Update && (
              <Link
                to={`/finding-details/${updateFindingId}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <MainButton
                  iconName="findings"
                  label="Finding Page"
                  size="medium"
                  onClick={() => {
                    refetch();
                  }}
                />
              </Link>
            )}
          </Flex>
        </Flex>
        <Flex column gap="8px">
          {isSizeLimitExceeded && (
            <AlertBanner
              message={
                <BodyRegular>
                  We've reached our storage limit! Consider deleting some images
                  to create space. Thanks for your cooperation!
                </BodyRegular>
              }
              customBackground="error"
              customIcon="warning"
            />
          )}
          <Flex
            justify="around"
            align="center"
            padding="8px"
            style={{
              backgroundColor: theme.blue50,
              borderRadius: "8px",
              boxShadow: "0px 8px 40px rgba(123, 123, 123, 0.1)",
              alignSelf: "stretch",
              border: "1px solid " + theme.primary + "",
              marginBottom: "8px",
            }}
          >
            {(me?.customer.is_multi_tenant === false || me?.is_superuser) && (
              <Flex>
                <Switch
                  checked={
                    formMode === FormModeState.Update
                      ? !editableUpdateFindingData?.is_pending
                      : !createFindingData.is_pending
                  }
                  onChange={(checked) => {
                    if (formMode === FormModeState.Create) {
                      setCreateFinding((prev) => ({
                        ...prev,
                        is_pending: !checked,
                      }));
                    }
                    if (formMode === FormModeState.Update) {
                      updateInPlace({ is_pending: !checked });
                    }
                  }}
                />
                <Flex gap="8px" align="center" padding="0 30px">
                  <HeaderMain>Show in WASP</HeaderMain>
                </Flex>
              </Flex>
            )}
            <Flex>
              <Switch
                checked={
                  formMode === FormModeState.Update
                    ? !!editableUpdateFindingData?.is_false_positive
                    : !!createFindingData.is_false_positive
                }
                onChange={(checked) => {
                  if (formMode === FormModeState.Create) {
                    setCreateFinding((prev) => ({
                      ...prev,
                      is_false_positive: checked,
                    }));
                  }
                  if (formMode === FormModeState.Update) {
                    updateInPlace({ is_false_positive: checked });
                  }
                }}
              />
              <Flex gap="8px" align="center" padding="0 30px">
                <HeaderMain>False Positive</HeaderMain>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <FindingAttachments
          findingAttachmentsToCreate={findingAttachments}
          setAttachmentsToCreate={setAttachments}
          currentAttachmentsNames={currentAttachmentsNames}
          findingId={updateFindingId ? parseInt(updateFindingId) : undefined}
          formMode={formMode}
        />
        <Flex gap="16px" w100>
          <BasicInformation
            formMode={formMode}
            loadingStates={loadingStates}
            setLoadingStates={setLoadingStates}
            createFindingData={createFindingData}
            setCreateFinding={setCreateFinding}
            updateFindingData={updateFindingData}
            setUpdateFinding={setUpdateFinding}
            editableUpdateFindingData={editableUpdateFindingData}
            setEditableUpdateFinding={setEditableUpdateFinding}
            clearTrigger={clearBasicInfoRTE}
            valueTrigger={initialValueTriggerBasicInfoRTE}
            isCorrectTitle={isCorrectTitle}
            setCorrectTitle={setCorrectTitle}
            isSubmitClicked={isSubmitClicked}
          />
          <ProjectDetails
            formMode={formMode}
            loadingStates={loadingStates}
            setLoadingStates={setLoadingStates}
            createFindingData={createFindingData}
            setCreateFinding={setCreateFinding}
            updateFindingData={updateFindingData}
            setUpdateFinding={setUpdateFinding}
            editableUpdateFindingData={editableUpdateFindingData}
            setEditableUpdateFinding={setEditableUpdateFinding}
            isCorrectCustomer={isCorrectCustomer}
            isCorrectProject={isCorrectProject}
            isCorrectAssignee={isCorrectAssignee}
            customers={customers}
            projects={projects}
            setCorrectAssignee={setCorrectAssignee}
            setCorrectCustomer={setCorrectCustomer}
            setCorrectProject={setCorrectProject}
            isSubmitClicked={isSubmitClicked}
          />
        </Flex>
        <AttackDetails
          formMode={formMode}
          loadingStates={loadingStates}
          setLoadingStates={setLoadingStates}
          createFindingData={createFindingData}
          setCreateFinding={setCreateFinding}
          updateFindingData={updateFindingData}
          setUpdateFinding={setUpdateFinding}
          editableUpdateFindingData={editableUpdateFindingData}
          setEditableUpdateFinding={setEditableUpdateFinding}
          clearTrigger={clearAttackDetailsRTE}
          valueTrigger={initialValueTriggerAttackDetailsRTE}
          isCorrectAssets={isCorrectAssets}
          isCorrectImpact={isCorrectImpact}
          customers={customers}
          setCorrectAssets={setCorrectAssets}
          setCorrectImpact={setCorrectImpact}
          isSubmitClicked={isSubmitClicked}
        />
        <RiskAssessment
          formMode={formMode}
          loadingStates={loadingStates}
          setLoadingStates={setLoadingStates}
          createFindingData={createFindingData}
          setCreateFinding={setCreateFinding}
          updateFindingData={updateFindingData}
          setUpdateFinding={setUpdateFinding}
          editableUpdateFindingData={editableUpdateFindingData}
          setEditableUpdateFinding={setEditableUpdateFinding}
          isCorrectCvssScore={isCorrectCvssScore}
          isCorrectExploitability={isCorrectExploitability}
          isCorrectOverallRisk={isCorrectOverallRisk}
          setCorrectCvssScore={setCorrectCvssScore}
          setCorrectExploitability={setCorrectExploitability}
          setCorrectOverallRisk={setCorrectOverallRisk}
          isSubmitClicked={isSubmitClicked}
        />
        <MitigationAssessment
          formMode={formMode}
          loadingStates={loadingStates}
          setLoadingStates={setLoadingStates}
          createFindingData={createFindingData}
          setCreateFinding={setCreateFinding}
          updateFindingData={updateFindingData}
          setUpdateFinding={setUpdateFinding}
          editableUpdateFindingData={editableUpdateFindingData}
          setEditableUpdateFinding={setEditableUpdateFinding}
          clearTrigger={clearMitigationRTE}
          valueTrigger={initialValueTriggerMitigationRTE}
        />
      </Flex>
      {submissionState === FormSubmissionState.FailedValidation && (
        <WarningModal
          header={`Oops!`}
          body={
            "Please ensure that all required fields are correctly filled out before submitting. Please try again."
          }
          approveButtonLabel="OK"
          onApprove={() => {
            setSubmissionState(FormSubmissionState.Initial);
          }}
          onClose={() => {
            setSubmissionState(FormSubmissionState.Initial);
          }}
        />
      )}
      {submissionState === FormSubmissionState.FailedSubmission && (
        <WarningModal
          header={`Something went wrong...`}
          body={
            "There was an issue submitting your form. Please reach out to the WASP team for assistance."
          }
          approveButtonLabel="OK"
          onApprove={() => {
            setSubmissionState(FormSubmissionState.Initial);
          }}
          onClose={() => {
            setSubmissionState(FormSubmissionState.Initial);
          }}
        />
      )}
      {submissionState === FormSubmissionState.AreYouSure && (
        <ConfirmModal
          isLoading={false}
          isSuccess={false}
          successMessage={"New finding created!"}
          title={"Are you show you want to show this finding?"}
          onClose={async () => {
            setSubmissionState(FormSubmissionState.Initial);
          }}
          onConfirm={() => {
            createFindingSubmission();
          }}
        />
      )}
      {submissionState === FormSubmissionState.Successful && (
        <ConfirmModal
          isLoading={false}
          isSuccess={true}
          successMessage={"New finding created!"}
          title={""}
          onClose={async () => {
            deleteCreateFinding();
            window.location.reload();
          }}
          onConfirm={() => {}}
        />
      )}
    </>
  );
};
