import { SimpleCheckbox } from "../../../components/elements/checkbox/SimpleCheckbox";
import { Column, Table } from "../../../components/elements/table/Table";
import {
  NotificationChannel,
  NotificationSettings,
} from "../../../types/Notifications";
import { Box } from "../../../components/elements/box/Box";
import { useApiMe } from "../../../hooks/queries/meContext";
import { Flex } from "../../../components/layouts/flex/Flex";
import { BodyRegular } from "../../../components/elements/typography/Typography";
import { Switch } from "../../../components/elements/switch/Switch";

type Props = {
  formValues: any;
  updateSettings: ({
    channel,
    values,
  }: {
    channel: NotificationChannel;
    values: NotificationSettings;
  }) => void;
};

export const NotificationsSettingsTable = (props: Props) => {
  const { formValues, updateSettings } = props;
  const { data: me } = useApiMe();

  const handleChange = (
    value: boolean | number,
    field: string,
    channel: NotificationChannel
  ) => {
    const values = { ...formValues[channel], [field]: value };
    updateSettings({ channel, values });
  };

  let columns: Column[] = [
    {
      title: "",
      key: "title",
      minWidth: "100px",
    },
    {
      title: "",
      titleComponent: () => (
        <Flex gap="12px" align="center">
          <BodyRegular>In App</BodyRegular>
          <Switch
            checked={!formValues.inApp.is_disabled}
            onChange={(state) => handleChange(!state, "is_disabled", "inApp")}
          />
        </Flex>
      ),
      key: "inApp",
      maxWidth: "360px",
      cell: (row) =>
        row.inApp !== null && (
          <SimpleCheckbox
            isDisabled={formValues.inApp.is_disabled}
            isChecked={row.inApp}
            onChange={(state) => handleChange(!state, row.alertType, "inApp")}
          />
        ),
    },
    {
      title: "",
      titleComponent: () => (
        <Flex gap="12px" align="center">
          <BodyRegular>Email</BodyRegular>
          <Switch
            checked={!formValues.email.is_disabled}
            onChange={(state) => handleChange(!state, "is_disabled", "email")}
          />
        </Flex>
      ),
      key: "email",
      maxWidth: "360px",
      cell: (row) =>
        row.email !== null && (
          <SimpleCheckbox
            isDisabled={formValues.email.is_disabled}
            isChecked={row.email}
            onChange={(state) => handleChange(!state, row.alertType, "email")}
          />
        ),
    },
  ];

  if (me?.can_manage_customer) {
    columns.push({
      title: "",
      titleComponent: () => (
        <Flex gap="12px" align="center">
          <BodyRegular>Slack</BodyRegular>
          <Switch
            checked={!formValues.slack.is_disabled}
            onChange={(state) => handleChange(!state, "is_disabled", "slack")}
          />
        </Flex>
      ),
      key: "slack",
      maxWidth: "360px",
      cell: (row) =>
        row.slack !== null && (
          <SimpleCheckbox
            isDisabled={formValues.slack.is_disabled}
            isChecked={row.slack}
            onChange={(state) => handleChange(!state, row.alertType, "slack")}
          />
        ),
    });
  }

  const rows: any[] = [
    {
      title: "New threat intelligence feed item has been added",
      alertType: "on_new_intelligence_feed_item",
      inApp: null,
      email: formValues.email.on_new_intelligence_feed_item,
      slack: formValues.slack.on_new_intelligence_feed_item,
    },
    {
      title: "New asset has been found",
      alertType: "on_new_asset",
      inApp: formValues.inApp.on_new_asset,
      email: formValues.email.on_new_asset,
      slack: formValues.slack.on_new_asset,
    },
    {
      title: "Comment has been added",
      alertType: "on_new_comment",
      inApp: formValues.inApp.on_new_comment,
      email: formValues.email.on_new_comment,
      slack: formValues.slack.on_new_comment,
    },
    {
      title: "You are mentioned in a comment",
      alertType: "on_comment_mention",
      inApp: formValues.inApp.on_comment_mention,
      email: formValues.email.on_comment_mention,
      slack: formValues.slack.on_comment_mention,
    },
    {
      title: "Finding status has been changed",
      alertType: "on_status_change",
      inApp: formValues.inApp.on_status_change,
      email: formValues.email.on_status_change,
      slack: formValues.slack.on_status_change,
    },
    {
      title: "Finding has been Assigned to You",
      alertType: "on_user_assign",
      inApp: formValues.inApp.on_user_assign,
      email: null,
      slack: null,
    },
    {
      title: "Finding has been Updated",
      alertType: "on_finding_updated",
      inApp: formValues.inApp.on_finding_updated,
      email: null,
      slack: null,
    },
    {
      title: "Finding Breached SLA",
      alertType: "on_breached_sla",
      inApp: formValues.inApp.on_breached_sla,
      email: null,
      slack: null,
    },
  ];

  return (
    <Box>
      <Table columns={columns} rows={rows} />
    </Box>
  );
};
