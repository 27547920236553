import { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Box } from "../../../components/elements/box/Box";
import { Ellipse } from "../../../components/elements/ellipse/Ellipse";
import { Icon } from "../../../components/elements/icon/Icon";
import { SkeletonLoading } from "../../../components/elements/loading/SkeletonLoading";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import {
  BodyRegular,
  HeaderSubBold,
  KPISmall,
} from "../../../components/elements/typography/Typography";
import { useApiAssetsRiskDistribution } from "../../../hooks/queries/assetsContext";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import { SCREEN_LAPTOP_WIDTH } from "../../../shared/consts";
import { getRiskColor } from "../../../shared/findingsHelper";
import { Filter } from "../../../types/AssetsView";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Link, useSearchParams } from "react-router-dom";
import { toBase64AssetsView } from "../../../shared/helper";
import { Mixpanel } from "../../../shared/mixpanel";

type Props = {
  filters: Filter[];
};

type RiskLevelCountProps = {};
const RiskLevelCountStyle = styled.div<RiskLevelCountProps>`
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  transition: 0.2s;
  cursor: pointer;
  background-color: ${(props) => props.theme.bg1};
  &:hover {
    background-color: ${(props) => props.theme.blue100};
  }
`;

export const RiskyAssets = (props: Props) => {
  const { filters } = props;
  const theme = useContext(ThemeContext);
  const [, setUseSearchParams] = useSearchParams();
  const { data: riskDistribution, isFetching } =
    useApiAssetsRiskDistribution(filters);
  const screenWidth = useScreenWidth();
  const isLaptop = screenWidth < SCREEN_LAPTOP_WIDTH;

  const data = [
    {
      risk_level: 4,
      name: "Critical", //"risk_score__gt=29",
      value: (riskDistribution?.f || 0) + (riskDistribution?.e || 0),
      filters: [
        {
          column: "risk_score",
          condition: "gt",
          value: "29",
          next_condition: "and",
          order: filters.length,
        },
      ],
    },
    {
      risk_level: 3,
      name: "High risk", // "risk_score__gt=9&risk_score__lt=30",
      value: (riskDistribution?.d || 0) + (riskDistribution?.c || 0),
      filters: [
        {
          column: "risk_score",
          condition: "gt",
          value: "9",
          next_condition: "and",
          order: filters.length,
        },
        {
          column: "risk_score",
          condition: "lt",
          value: "30",
          next_condition: "and",
          order: filters.length + 1,
        },
      ],
    },

    {
      risk_level: 1,
      name: "Low risk", //"risk_score__gt=0&risk_score__lt=9",
      value: (riskDistribution?.b || 0) + (riskDistribution?.a || 0),
      filters: [
        {
          column: "risk_score",
          condition: "gt",
          value: "0",
          next_condition: "and",
          order: filters.length,
        },
        {
          column: "risk_score",
          condition: "lt",
          value: "9",
          next_condition: "and",
          order: filters.length + 1,
        },
      ],
    },
    {
      risk_level: 0,
      name: "No risk", // "risk_score=0",
      value: riskDistribution?.no_risk || 0,
      filters: [
        {
          column: "risk_score",
          condition: "is",
          value: "0",
          next_condition: "and",
          order: filters.length,
        },
      ],
    },
  ];

  const AssetRiskLevelCount = (props: {
    title: string;
    risk_level: number;
    amount: number;
    onClick: () => void;
  }) => (
    <RiskLevelCountStyle
      onClick={props.onClick}
      className={`${isLaptop ? "text-center gap-4" : "gap-8"}`}
    >
      <Link to="" style={{ textDecoration: "none" }}>
        <div
          className={`d-flex  align-items-center w-100 ${
            isLaptop
              ? "flex-column justify-content-around"
              : "flex-row justify-content-between"
          }`}
        >
          <KPISmall style={{ color: theme.textSecondary }}>
            {props.amount}
          </KPISmall>
          {isLaptop ? (
            <SeparatorHorizontal
              style={{
                backgroundColor: theme[getRiskColor(props.risk_level)],
                height: 4,
              }}
            />
          ) : (
            <Ellipse size={8} color={theme[getRiskColor(props.risk_level)]} />
          )}
        </div>

        <BodyRegular
          className="text-truncate"
          style={{
            color: theme.textSecondary,
            fontSize: isLaptop ? 12 : 14,
          }}
        >
          {props.title}
        </BodyRegular>
      </Link>
    </RiskLevelCountStyle>
  );

  return (
    <Box
      className="d-flex flex-column justify-content-between w-100  h-100"
      style={{ padding: 16 }}
    >
      <Flex align="center" gap="8px">
        <Icon name="activeIssues" size={32} color={theme.primary} />
        <HeaderSubBold>Risky Assets</HeaderSubBold>
      </Flex>
      <Flex align="center" justify="between" gap={isLaptop ? "6px" : "16px"}>
        {data.map((item, index) =>
          isFetching ? (
            <SkeletonLoading key={`skl-${index}`} height="100px" width="100%" />
          ) : (
            <AssetRiskLevelCount
              key={`risk-rank-${index}`}
              risk_level={item.risk_level}
              title={item.name}
              amount={item.value}
              onClick={() => {
                Mixpanel.track("Risky Asset click", { Selected: item.name });
                setUseSearchParams((prev) => ({
                  ...prev,
                  view: toBase64AssetsView({
                    name: "",
                    filters: [
                      ...filters.filter((f) => f.column !== "risk_score"),
                      ...item.filters,
                    ],
                  }),
                }));
              }}
            ></AssetRiskLevelCount>
          )
        )}
      </Flex>
    </Box>
  );
};
