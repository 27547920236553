import { Dropdown, Option } from "../../elements/dropdowns/Dropdown";
import { SingleValue, components } from "react-select";
import { SEVERITIES } from "../../../shared/consts";
import RiskLegendItem from "../../elements/legend/RiskLegendItem";

export const SeverityDropdown = ({
  value,
  onChange,
  dataTestId,
  width,
  disabled,
}: {
  onChange: (option: SingleValue<Option>) => void;
  value: SingleValue<Option>;
  dataTestId?: string;
  width?: string;
  disabled?: boolean;
}) => {
  const { Option } = components;

  const SeverityOption = (props: any) => (
    <Option {...props}>
      <RiskLegendItem label={props.data.label} />
    </Option>
  );

  const SeverityValue = (props: any) => {
    const severity = props.selectProps?.value.label;
    return (
      <components.SingleValue {...props}>
        <div
          data-testid={`${dataTestId}-${severity}`}
          className="d-flex align-items-center dropdown-container w-100"
        >
          <RiskLegendItem label={severity} size={14} />
        </div>
      </components.SingleValue>
    );
  };

  return (
    <div style={{ minWidth: "120px" }}>
      <Dropdown
        size="medium"
        variant="border"
        onChange={onChange}
        value={value}
        width={width}
        options={Object.keys(SEVERITIES).map((key) => ({
          value: key,
          label: SEVERITIES[key],
        }))}
        customComponents={{
          Option: SeverityOption,
          SingleValue: SeverityValue,
        }}
        isMenuPositionFixed
        disabled={disabled}
      />
    </div>
  );
};
