import { Flex } from "../../../components/layouts/flex/Flex";
import { LabelRegular } from "../../../components/elements/typography/Typography";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import { FormError } from "../../../components/elements/FormsElements/FormError";
import { Dropdown } from "../../../components/elements/dropdowns/Dropdown";
import { useContext, useState } from "react";
import {
  AccountFormErrors,
  AccountFormValues,
} from "../../settings/users/AddEditUserForm";
import { ThemeContext } from "styled-components";
import { EMAIL_REGEX, ROLE_OPTIONS } from "../../../shared/consts";
import {
  AccountParams,
  useApiCreateAccount,
  useApiDeleteAccount,
} from "../../../hooks/queries/accountsContext";
import useToastContext from "../../../hooks/toastHook";
import {
  OptionalField,
  RequiredField,
} from "../../../components/elements/requiredField/RequiredField";
import { Account } from "../../../types/Account";
import { IconButton } from "../../../components/elements/button/icon/IconButton";

const emptyAccount = {
  phone: "",
  email: "",
  products_allowed: [],
  projects_allowed: [],
  role: "",
};

type Props = {
  account?: Account | undefined;
};

export const UserForm = (props: Props) => {
  const { account } = props;
  const addToast = useToastContext();
  const theme = useContext(ThemeContext);

  const initFormValues = account
    ? {
        phone: account.phone,
        email: account.email,
        products_allowed: account.products_allowed,
        projects_allowed: account.projects_allowed,
        role: account.role,
      }
    : emptyAccount;

  const { mutate: createAccount, isLoading: isCreating } =
    useApiCreateAccount();
  const { mutate: deleteAccount } = useApiDeleteAccount();

  const [formErrors, setFormErrors] = useState<AccountFormErrors>({});
  const [formValues, setFormValues] =
    useState<AccountFormValues>(initFormValues);

  const apiCall = () => {
    let data: AccountParams = { ...formValues };
    const callbacks = {
      onSuccessCallback: () => {
        addToast({
          message: "User has been successfully created",
          type: "success",
        });
      },
      onErrorCallback: (error: any) => {
        addToast({ message: `Error: ${error}`, type: "error" });
      },
    };
    createAccount({ ...data, ...callbacks });
  };

  const validate = (values: AccountFormValues) => {
    const errors: AccountFormErrors = {};
    if (!values.email) errors.email = "Email is required!";
    else if (!EMAIL_REGEX.test(values.email))
      errors.email = "Please enter a valid email!";

    if (formValues.phone)
      if (formValues.phone.substring(0, 1) !== "+")
        errors.phone =
          "Phone number must start with + and international dial code";
    if (/[a-zA-Z]/.test(`${formValues.phone}`))
      errors.phone = "Phone number cannot contain letters";
    if (!values.role) errors.role = "Role is required!";
    else if (values.role.length > 30)
      errors.role = "Role name is too long, 30 characters max.";
    return errors;
  };

  const handleSubmit = () => {
    const errors = validate(formValues);
    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return;
    }
    apiCall();
  };

  return (
    <Flex
      gap="24px"
      data-testid="form-body"
      w100
      align="center"
      justify="center"
    >
      <Flex data-testid="email-field" column style={{ height: "70px" }}>
        <Flex align="center" gap="8px">
          <LabelRegular>Email Address</LabelRegular>
          <RequiredField />
        </Flex>
        <InputText
          dataTestId="email-input"
          isError={!!formErrors.email}
          color={theme.black800}
          onChange={(e) => {
            setFormErrors({ ...formErrors, email: "" });
            setFormValues({
              ...formValues,
              email: `${e.target.value}`.toLowerCase(),
            });
          }}
          value={formValues.email}
          autoFocus={true}
          width={"250px"}
          disabled={!!account}
          placeholder="user@yourdomain.com"
        />
        <FormError errorMessage={formErrors.email} />
      </Flex>

      <Flex data-testid="phone-field" column style={{ height: "70px" }}>
        <Flex align="center" justify="between">
          <LabelRegular>Phone Number</LabelRegular>
          <OptionalField />
        </Flex>
        <InputText
          dataTestId="phone-input"
          isError={!!formErrors.phone}
          color={theme.black800}
          onChange={(e) => {
            setFormErrors({ ...formErrors, phone: "" });
            setFormValues({ ...formValues, phone: e.target.value });
          }}
          value={formValues.phone || ""}
          width={"250px"}
          placeholder={!!account ? "" : "+972581231234"}
          disabled={!!account}
        />
        <FormError errorMessage={formErrors.phone} />
      </Flex>

      <Flex data-testid="email-field" column style={{ height: "70px" }}>
        <Flex align="center" gap="8px">
          <LabelRegular>Role</LabelRegular>
          <RequiredField />
        </Flex>
        <Dropdown
          dataTestId="role-dropdown"
          creatable
          variant="border"
          isError={!!formErrors.role}
          onChange={(e) => {
            setFormErrors({ ...formErrors, role: "" });
            setFormValues({
              ...formValues,
              role: `${e?.value}`.replace("&amp;", "&"),
            });
          }}
          value={
            formValues.role
              ? {
                  value: formValues.role.replace("&amp;", "&"),
                  label: formValues.role.replace("&amp;", "&"),
                }
              : { value: "", label: "Select a Role" }
          }
          options={ROLE_OPTIONS}
          width="250px"
          disabled={!!account}
        />
        <FormError errorMessage={formErrors.role} />
      </Flex>

      <Flex style={{ height: "35px" }} column justify="start">
        {!!account ? (
          <IconButton
            label="Delete User"
            dataTestId="remove-user-btn"
            iconName="remove"
            color={theme.redPrimary}
            onClick={() => deleteAccount(account)}
          />
        ) : (
          <IconButton
            label="Add user"
            dataTestId="add-user-btn"
            iconName="add"
            onClick={handleSubmit}
            inProgress={isCreating}
          />
        )}
      </Flex>
    </Flex>
  );
};
