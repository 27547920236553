import React from "react";
import { Assignee } from "../../../../components/composed/assignee/Assignee";
import { Finding } from "../../../../types/Finding";
import { Section } from "../../../../components/elements/section/Section";

type Props = {
  finding: Finding | undefined | null;
  noSeparator?: boolean;
  width?: string;
};

export const IssuedByItem = (props: Props) => {
  const { finding, noSeparator = false, width } = props;
  return (
    <Section title="Issued By" noSeparator={noSeparator} width={width}>
      <Assignee
        name={finding?.op_jira_assignee?.display_name}
        imageSrc={finding?.op_jira_assignee?.avatar_url}
      />
    </Section>
  );
};
