import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ThemeContext } from "styled-components";
import { IFindingsLegend } from "../FindingsLegend";
import { FunnelDataLayered, FunnelGraph } from "@trutoo/funnel-graph";
import "./findingsFunnel.css";
import "@trutoo/funnel-graph/dist/index.min.css";
import FindingFunnelLabels from "./FindingsFunnelLabels";
import FindingFunnelCounters from "./FindingsFunnelCounters";
import { SingleValue } from "react-select";
import { Option } from "../../../../components/elements/dropdowns/Dropdown";
import { useApiFindingsFunnelStatistics } from "../../../../hooks/queries/findingContext";
import { Filters } from "../../Findings";
import { FindingsFunnelStatistics } from "../../../../types/Finding";
import { SkeletonLoading } from "../../../../components/elements/loading/SkeletonLoading";
import { Flex } from "../../../../components/layouts/flex/Flex";

function sumNestedArrays(arr: number[][]): number {
  return arr.flat().reduce((acc, curr) => acc + curr, 0);
}

function FindingsFunnel({
  selectedProduct,
  legend,
  timeframe,
}: {
  selectedProduct: number | "all";
  legend: IFindingsLegend;
  timeframe: SingleValue<Option>;
}) {
  const theme = useContext(ThemeContext);

  let filters: Filters = { timeframe: timeframe?.value, stats_type: "funnel" };
  if (selectedProduct !== "all") filters.products = selectedProduct;
  const { data: stats, isLoading } = useApiFindingsFunnelStatistics(filters);

  const [isDrawn, setIsDrawn] = useState<boolean>(false);

  // mutates the stats data coming from the db
  // to a list of list of values by severity
  // index 0 in the list is critical (severity 4) to keep the reds on the top
  const values: number[][] = useMemo(() => {
    if (!stats || Object.keys(stats).length === 0) return [];
    return Object.keys(stats).map((statKey) => {
      const {
        4: critical,
        3: high,
        2: medium,
        1: low,
        0: info,
      } = stats[statKey as keyof FindingsFunnelStatistics];
      // if severity is disabled on legend return 0
      return [
        (legend.critical && critical) || 0,
        (legend.high && high) || 0,
        (legend.medium && medium) || 0,
        (legend.low && low) || 0,
        (legend.info && info) || 0,
      ];
    });
  }, [stats, legend]);

  const data: FunnelDataLayered | null = useMemo(() => {
    if (!values || sumNestedArrays(values) === 0) return null;
    return {
      labels: ["All", "Valid", "Open", "Breached SLA"],
      subLabels: ["Critical", "High", "Medium", "Low", "info"],
      colors: [
        // if severity is disabled on legend or no values make it white
        !!values[0]?.length ? [theme.redPrimary, theme.bg2] : "transparent",
        !!values[1]?.length ? [theme.orangePrimary, theme.bg2] : "transparent",
        !!values[2]?.length ? [theme.yellowPrimary, theme.bg2] : "transparent",
        !!values[3]?.length
          ? [theme.lightBluePrimary, theme.bg2]
          : "transparent",
        !!values[4]?.length ? [theme.gray600, theme.bg2] : "transparent",
      ],
      values,
    };
  }, [theme, values]);

  const graph = useMemo(() => {
    if (!data) return null;
    return new FunnelGraph({
      container: `.funnal-container`,
      gradientDirection: "vertical",
      data: data,
      displayPercent: true,
      direction: "horizontal",
      subLabelValue: "raw",
      height: 260,
    });
  }, [data]);

  const drawGraph = useCallback(() => {
    if (!data || !Object.keys(data).length) return;
    graph?.draw();

    // SET GRAPH OPACITY AND BORDERS
    let svg = graph?.getSVG();
    let paths = svg?.getElementsByTagName("path");
    Array.from({ length: 4 }, (_, i) => i).forEach((num) => {
      paths?.item(num)?.setAttribute("fill-opacity", "0.2");
      paths?.item(num)?.setAttribute("stroke-width", "2");
    });

    // SET POPUP BACKGROUND COLOR
    let popups = document.querySelectorAll(".fg-label__segments");
    const rgb = theme.name === "dark" ? "29, 29, 29" : "255, 255, 255";
    let popupStyle = `
      background: linear-gradient(280deg,rgba(${rgb},0), rgba(${rgb}, 1) 60%);
      position: absolute;
      top: 90px !important;
      padding: 16px !important;
      height: 240px;
      z-index: 30 !important;
    `;
    popups.forEach((popup) => {
      popup?.setAttribute("style", popupStyle);
    });

    // SET POPUP TEXT COLOR
    let segmentsItems = document.querySelectorAll(".fg-label__segment-item");
    segmentsItems.forEach((item) => {
      item?.setAttribute("style", `color: ${theme.textBody} !important`);
    });

    setIsDrawn(true);
  }, [data, graph, theme]);

  useEffect(() => {
    drawGraph();
    window.addEventListener("resize", drawGraph, false);
    return window.removeEventListener("resize", drawGraph, false);
  }, [drawGraph]);

  const totalFindings =
    stats?.all && !!Object.values(stats.all).length
      ? Object.values(stats.all)?.reduce((a, b) => a + b)
      : 0;

  return (
    <>
      {data ? (
        <div style={{ opacity: isDrawn ? 1 : 0, transition: "0.8s" }}>
          <div style={{ position: "relative", marginBottom: "24px" }}>
            <div
              id="funnel-container"
              key="funnal-ctrl"
              className="funnal-container  d-flex"
              style={{ height: "232px", transition: "0.4s" }}
            />

            <FindingFunnelCounters graph={graph} values={values} />
          </div>
        </div>
      ) : (
        <div style={{ transition: "0.8s", height: "232px" }}>
          {isLoading && (
            <Flex column gap="8px" style={{ marginTop: "8px" }}>
              <SkeletonLoading height="48px" width="100%" />
              <SkeletonLoading height="48px" width="100%" />
              <SkeletonLoading height="48px" width="100%" />
              <SkeletonLoading height="48px" width="100%" />
            </Flex>
          )}
        </div>
      )}
      <FindingFunnelLabels
        values={values}
        totalFindings={totalFindings}
        timeframeDays={parseInt(`${timeframe?.value}`)}
        selectedProduct={selectedProduct}
      />
    </>
  );
}

export default FindingsFunnel;
