import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Flex } from "../../layouts/flex/Flex";
import { LabelRegular } from "../typography/Typography";

function RiskLegendItem({
  label,
  size,
  isDisabled,
  handleLegendClick,
}: {
  label: string;
  size?: number;
  isDisabled?: boolean | undefined;
  handleLegendClick?: Function;
}) {
  const theme = useContext(ThemeContext);
  let riskColors: { [index: string]: string } = {
    info: theme.gray600,
    low: theme.lightBluePrimary,
    medium: theme.yellowPrimary,
    high: theme.orangePrimary,
    critical: theme.redPrimary,
    total: theme.primary,
  };
  const dotStyle = {
    backgroundColor: riskColors[label],
    border: `3px solid ${riskColors[label?.toLowerCase()]}`,
    borderRadius: "50%",
    width: "8px",
    height: "8px",
  };

  return (
    <Flex
      gap="8px"
      align="center"
      data-testid={label}
      style={{
        fontSize: size || "12px",
        textDecoration: isDisabled ? "line-through" : "none",
        cursor: handleLegendClick ? "pointer" : "default",
        textTransform: "capitalize",
        overflowY: "clip",
      }}
      onClick={() => handleLegendClick && handleLegendClick(label)}
    >
      <div style={dotStyle}></div>
      <LabelRegular style={{ fontSize: size }} color={theme.textSecondary}>
        {label}
      </LabelRegular>
    </Flex>
  );
}

export default RiskLegendItem;
