import { Dispatch, SetStateAction, useContext } from "react";
import { Asset } from "../../../types/Asset";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Icon } from "../../../components/elements/icon/Icon";
import {
  LabelRegular,
  LabelRegularHover,
} from "../../../components/elements/typography/Typography";
import { ThemeContext } from "styled-components";
import { Loading } from "../../../components/elements/loading/Loading";

type Props = {
  asset: Asset | undefined;
  setShowVerifyAssetModal?: Dispatch<SetStateAction<number>>;
  onOpenModal?: () => void;
  isLoading?: boolean;
  variant?: "table" | undefined;
};

export const AssetVerificationIndicator = (props: Props) => {
  const { asset, setShowVerifyAssetModal, isLoading, onOpenModal, variant } =
    props;
  const theme = useContext(ThemeContext);

  return isLoading ? (
    <Loading />
  ) : asset?.is_verified && variant !== "table" ? (
    <Flex align="center" gap="8px">
      <Icon name="check" color={theme.greenPrimary} size={24} />
      <LabelRegular>Verified</LabelRegular>
    </Flex>
  ) : (
    <Flex
      align="center"
      onClick={() => {
        setShowVerifyAssetModal && setShowVerifyAssetModal(asset?.id || 0);
        onOpenModal && onOpenModal();
      }}
      style={{ cursor: "pointer" }}
    >
      <Icon name="activeIssues" color={theme.redPrimary} size={24} />
      {variant !== "table" ? (
        !!setShowVerifyAssetModal ? (
          <LabelRegularHover>Not Verified</LabelRegularHover>
        ) : (
          <LabelRegular>Not Verified</LabelRegular>
        )
      ) : null}
    </Flex>
  );
};
