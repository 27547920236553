import { SetStateAction, useContext } from "react";
import { ThemeContext } from "styled-components";
import { Badge } from "../../../../components/elements/badge/Badge";
import { PrimaryBadge } from "../../../../components/elements/badge/PrimaryBadge";
import { LabelRegular } from "../../../../components/elements/typography/Typography";
import { useApiAssetsGetById } from "../../../../hooks/queries/assetsContext";
import { Finding } from "../../../../types/Finding";
import { Link } from "react-router-dom";

type Props = {
  finding: Finding | undefined | null;
  setShowAffectedAssetsPane?: React.Dispatch<SetStateAction<boolean>>;
};

export const AffectedAssetsList = (props: Props) => {
  const { finding, setShowAffectedAssetsPane } = props;
  const theme = useContext(ThemeContext);
  const { data: assets } = useApiAssetsGetById(finding?.affected_assets || []);

  return (
    <div className="d-flex flex-column gap-8" data-testid="affected-assets">
      <LabelRegular>Affected Assets</LabelRegular>
      <div className="d-flex flex-wrap gap-8">
        {assets?.slice(0, 10).map((affectedAsset, index) => (
          <Link
            to={`/assets?assetId=${affectedAsset.id}`}
            style={{ textDecoration: "none", color: "inherit" }}
            key={`affectedAsset-${index}`}
          >
            <PrimaryBadge content={affectedAsset.name} maxWidth="75vw" />
          </Link>
        ))}
        {finding?.affected_assets_displayed.length &&
        finding?.affected_assets_displayed.length > 10 ? (
          <Badge
            onClick={() =>
              setShowAffectedAssetsPane && setShowAffectedAssetsPane(true)
            }
            style={{
              cursor: "pointer",
              border: `1px solid ${theme.black500}`,
              color: theme.primary,
            }}
          >
            +{finding?.affected_assets_displayed.length - 10}
          </Badge>
        ) : null}
      </div>
    </div>
  );
};
