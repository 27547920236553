import React, { Dispatch, SetStateAction, useContext } from "react";
import { TagsLine } from "../../../../components/composed/tagsLine/TagsLine";
import { FormError } from "../../../../components/elements/FormsElements/FormError";
import { Box } from "../../../../components/elements/box/Box";
import { Dropdown } from "../../../../components/elements/dropdowns/Dropdown";
import { Icon } from "../../../../components/elements/icon/Icon";
import {
  RequiredField,
  OptionalField,
} from "../../../../components/elements/requiredField/RequiredField";
import {
  HeaderSecondary,
  LabelRegular,
} from "../../../../components/elements/typography/Typography";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { StatusMapping } from "../../../../shared/consts";
import {
  objectsToOptions,
  getOptionFromObjects,
  getOptionFromKeyValuePairs,
  FormModeState,
} from "../../../../shared/formUtils";
import {
  AdminFindingEdit,
  Finding,
  FindingLoadingMap,
  FindingStatus,
  JiraUser,
} from "../../../../types/Finding";
import { SuperUsersDropdown } from "../../../projects/projectPane/SuperUsersDropdown";
import { ThemeContext } from "styled-components";
import { Customer } from "../../../../types/Customer";
import { Project } from "../../../../types/Project";
import { OpUser } from "../../../../hooks/queries/usersContext";
import { Option } from "../../../../components/elements/dropdowns/Dropdown";
import { useParams } from "react-router";
import { useUpdateFindingInPlace } from "../../../../shared/formUtils";
import { useApiMe } from "../../../../hooks/queries/meContext";

type Props = {
  formMode: FormModeState;
  loadingStates: FindingLoadingMap;
  setLoadingStates: Dispatch<SetStateAction<FindingLoadingMap>>;
  createFindingData: AdminFindingEdit;
  setCreateFinding: Dispatch<SetStateAction<AdminFindingEdit>>;
  updateFindingData?: Finding;
  setUpdateFinding: Dispatch<SetStateAction<Finding | undefined>>;
  editableUpdateFindingData: AdminFindingEdit | null;
  setEditableUpdateFinding: Dispatch<SetStateAction<AdminFindingEdit | null>>;
  isCorrectCustomer: boolean;
  isCorrectProject: boolean;
  isCorrectAssignee: boolean;
  customers: Customer[] | undefined;
  projects: Project[] | undefined;
  setCorrectCustomer: Dispatch<SetStateAction<boolean>>;
  setCorrectProject: Dispatch<SetStateAction<boolean>>;
  setCorrectAssignee: Dispatch<SetStateAction<boolean>>;
  isSubmitClicked: boolean;
};

export const ProjectDetails = (props: Props) => {
  const {
    formMode,
    loadingStates,
    setLoadingStates,
    createFindingData,
    setCreateFinding: setCreateFindingData,
    updateFindingData,
    setUpdateFinding,
    editableUpdateFindingData,
    setEditableUpdateFinding,
    isCorrectCustomer,
    isCorrectProject,
    isCorrectAssignee,
    customers,
    projects,
    setCorrectCustomer,
    setCorrectProject,
    setCorrectAssignee,
    isSubmitClicked,
  } = props;
  const theme = useContext(ThemeContext);
  const { data: me } = useApiMe();
  const { id: updateFindingId } = useParams();
  const [updateInPlace, isLoading] = useUpdateFindingInPlace(
    parseInt(updateFindingId ? updateFindingId : "0"),
    setUpdateFinding,
    updateFindingData,
    setLoadingStates
  );

  const onAssigneeChangedHandler = (opUser: OpUser) => {
    var jiraUser: JiraUser = {
      jira_id: "",
      display_name: opUser.first_name + " " + opUser.last_name,
      email: opUser.email,
      avatar_url: opUser.avatar_url,
    };
    if (formMode === FormModeState.Update) {
      setEditableUpdateFinding((prev) => ({
        ...prev,
        op_jira_assignee: jiraUser,
      }));
      updateInPlace({ op_jira_assignee: jiraUser });
      setLoadingStates((prev) => ({ ...prev, op_jira_assignee: true }));
    }

    if (formMode === FormModeState.Create) {
      setCreateFindingData((prev) => ({
        ...prev,
        op_jira_assignee: jiraUser,
      }));
    }

    setCorrectAssignee(true);
  };

  const onChangeProjectValue = (): Option | null => {
    var currentProjectIdSelected =
      formMode === FormModeState.Update
        ? editableUpdateFindingData?.project
        : createFindingData.project;
    var currentCustomer =
      formMode === FormModeState.Update
        ? editableUpdateFindingData?.customer
        : createFindingData.customer;

    var currentProjectOptions = projects
      ? objectsToOptions(
          projects?.filter((p) => p.customer === currentCustomer) || []
        )
      : [];

    // Checking to avoid edge case where selected project is not owned by selected customer
    for (const currentProj of currentProjectOptions) {
      if (Number(currentProj.value) === Number(currentProjectIdSelected)) {
        return currentProj;
      }
    }

    return { label: "Select an option", value: 0 };
  };

  return (
    <Box style={{ width: "50%" }}>
      <Flex column gap="32px">
        <Flex gap="8px">
          <Icon name="projects" size={30} color={theme.primary} />{" "}
          <HeaderSecondary>Project Details</HeaderSecondary>
        </Flex>
        <Flex column gap="8px">
          <Flex justify="between">
            <LabelRegular>Customer</LabelRegular>
            <RequiredField />
          </Flex>
          <Dropdown
            searchable
            placeholder="Select customer"
            options={customers ? objectsToOptions(customers) : []}
            disabled={
              formMode === FormModeState.Update ||
              (me?.customer.is_multi_tenant &&
                !me.is_superuser &&
                formMode === FormModeState.Create)
            }
            value={
              formMode === FormModeState.Update
                ? editableUpdateFindingData?.customer && customers
                  ? getOptionFromObjects(
                      customers,
                      editableUpdateFindingData?.customer
                    )
                  : null
                : createFindingData.customer && customers
                ? getOptionFromObjects(customers, createFindingData.customer)
                : null
            }
            onChange={(opt) => {
              if (opt?.value) {
                if (formMode === FormModeState.Update)
                  setEditableUpdateFinding((prev) => ({
                    ...prev,
                    customer: Number(opt.value),
                  }));
                if (formMode === FormModeState.Create)
                  setCreateFindingData((prev) => ({
                    ...prev,
                    customer: Number(opt.value),
                  }));
                if (Number(opt.value) === 0) {
                  setCorrectCustomer(false);
                } else {
                  setCorrectCustomer(true);
                }
              }
            }}
            variant="border"
            size="medium"
          />
          <FormError
            errorMessage={
              !isCorrectCustomer && isSubmitClicked
                ? "Please fill in the Customer field"
                : ""
            }
          />
        </Flex>
        <Flex column gap="8px">
          <Flex justify="between">
            <Flex>
              <LabelRegular>Project</LabelRegular>
              <RequiredField />
            </Flex>
            {isLoading && loadingStates.project && (
              <Icon name="spinner" size={20} />
            )}
          </Flex>
          <Dropdown
            searchable
            placeholder="Select project"
            disabled={
              formMode === FormModeState.Update
                ? !editableUpdateFindingData?.customer
                : !createFindingData.customer
            }
            options={
              projects && formMode === FormModeState.Update
                ? objectsToOptions(
                    projects?.filter(
                      (p) => p.customer === editableUpdateFindingData?.customer
                    ) || []
                  )
                : projects && formMode === FormModeState.Create
                ? objectsToOptions(
                    projects?.filter(
                      (p) => p.customer === createFindingData?.customer
                    ) || []
                  )
                : []
            }
            value={onChangeProjectValue()}
            variant="border"
            size="medium"
            onChange={(opt) => {
              if (opt?.value) {
                if (formMode === FormModeState.Update) {
                  setEditableUpdateFinding((prev) => ({
                    ...prev,
                    project: Number(opt.value),
                  }));
                  updateInPlace({ project: Number(opt.value) });
                  setLoadingStates((prev) => ({ ...prev, project: true }));
                }
                if (formMode === FormModeState.Create)
                  setCreateFindingData((prev) => ({
                    ...prev,
                    project: Number(opt.value),
                  }));
                if (Number(opt.value) === 0) {
                  setCorrectProject(false);
                } else {
                  setCorrectProject(true);
                }
              }
            }}
          />
          <FormError
            errorMessage={
              !isCorrectProject && isSubmitClicked
                ? "Please fill in the Project field"
                : ""
            }
          />
        </Flex>
        <Flex column gap="8px">
          <Flex justify="between">
            <Flex>
              <LabelRegular>Status</LabelRegular>
              <RequiredField />
            </Flex>
            {isLoading && loadingStates.status && (
              <Icon name="spinner" size={20} />
            )}
          </Flex>
          <Dropdown
            placeholder="Select status"
            searchable
            value={
              formMode === FormModeState.Update
                ? editableUpdateFindingData?.status
                  ? getOptionFromKeyValuePairs(
                      StatusMapping,
                      editableUpdateFindingData?.status
                    )
                  : null
                : createFindingData?.status
                ? getOptionFromKeyValuePairs(
                    StatusMapping,
                    createFindingData?.status
                  )
                : null
            }
            options={Object.keys(StatusMapping).map((key) => ({
              value: key,
              label: StatusMapping[key as keyof typeof StatusMapping],
            }))}
            variant="border"
            size="medium"
            onChange={(opt) => {
              if (opt?.value) {
                if (formMode === FormModeState.Update) {
                  setEditableUpdateFinding((prev) => ({
                    ...prev,
                    status: opt.value as FindingStatus,
                  }));
                  updateInPlace({ status: opt.value as FindingStatus });
                  setLoadingStates((prev) => ({ ...prev, status: true }));
                }
                if (formMode === FormModeState.Create)
                  setCreateFindingData((prev) => ({
                    ...prev,
                    status: opt.value as FindingStatus,
                  }));
              }
            }}
          />
        </Flex>
        {(me?.customer.is_multi_tenant === false || me?.is_superuser) && (
          <Flex column gap="8px">
            <Flex justify="between">
              <Flex>
                <LabelRegular>OP User Assignee</LabelRegular>
                <RequiredField />
              </Flex>
              {isLoading && loadingStates.op_jira_assignee && (
                <Icon name="spinner" size={20} />
              )}
            </Flex>
            <SuperUsersDropdown
              placeholder="Select an option"
              valueByEmail={
                formMode === FormModeState.Update
                  ? editableUpdateFindingData?.op_jira_assignee?.email
                  : createFindingData.op_jira_assignee?.email
              }
              size="medium"
              onSelect={(opUser) => opUser && onAssigneeChangedHandler(opUser)}
            />
            <FormError
              errorMessage={
                !isCorrectAssignee && isSubmitClicked
                  ? "Please fill in the Assignee field"
                  : ""
              }
            />
          </Flex>
        )}
        <Flex column gap="8px">
          <Flex justify="between">
            <Flex>
              <LabelRegular>Labels</LabelRegular>
              <OptionalField />
            </Flex>
            {isLoading && loadingStates.labels && (
              <Icon name="spinner" size={20} />
            )}
          </Flex>
          <TagsLine
            isEditable={true}
            selectedTags={
              formMode === FormModeState.Update
                ? editableUpdateFindingData?.labels
                  ? editableUpdateFindingData?.labels
                  : []
                : createFindingData?.labels
                ? createFindingData?.labels
                : []
            }
            onTagAdd={(tagLabel: string) => {
              if (formMode === FormModeState.Update) {
                setEditableUpdateFinding((prev) => ({
                  ...prev,
                  labels: editableUpdateFindingData?.labels
                    ? [...editableUpdateFindingData?.labels, tagLabel]
                    : [tagLabel],
                }));
                updateInPlace({
                  labels: editableUpdateFindingData?.labels
                    ? [...editableUpdateFindingData?.labels, tagLabel]
                    : [tagLabel],
                });
                setLoadingStates((prev) => ({ ...prev, labels: true }));
              }
              if (formMode === FormModeState.Create)
                setCreateFindingData((prev) => ({
                  ...prev,
                  labels: createFindingData?.labels
                    ? [...createFindingData?.labels, tagLabel]
                    : [tagLabel],
                }));
            }}
            onTagRemove={(tagLabel: string) => {
              if (formMode === FormModeState.Update) {
                setEditableUpdateFinding((prev) => ({
                  ...prev,
                  labels: editableUpdateFindingData?.labels
                    ? editableUpdateFindingData?.labels.filter(
                        (label) => label !== tagLabel
                      )
                    : [],
                }));
                updateInPlace({
                  labels: editableUpdateFindingData?.labels
                    ? editableUpdateFindingData?.labels.filter(
                        (label) => label !== tagLabel
                      )
                    : [],
                });
                setLoadingStates((prev) => ({ ...prev, labels: true }));
              }
              if (formMode === FormModeState.Create) {
                setCreateFindingData((prev) => ({
                  ...prev,
                  labels: createFindingData?.labels
                    ? createFindingData?.labels.filter(
                        (label) => label !== tagLabel
                      )
                    : [],
                }));
              }
            }}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
