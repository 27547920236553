import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import {
  HeaderSecondary,
  HeaderSubBold,
} from "../../../../components/elements/typography/Typography";
import { SEVERITIES } from "../../../../shared/consts";
import { Finding } from "../../../../types/Finding";
import { Section } from "../../../../components/elements/section/Section";
import { getRiskColor } from "../../../../shared/findingsHelper";

type Props = {
  finding: Finding | undefined | null;
  noSeparator?: boolean;
  headerStyle: "secondary" | "sub";
  width?: string;
};

export const SeverityItem = (props: Props) => {
  const { finding, noSeparator = false, headerStyle, width } = props;
  const theme = useContext(ThemeContext);
  const severity_string = SEVERITIES[finding?.overall_risk || 0];

  return (
    <Section
      title="Severity"
      noSeparator={noSeparator}
      headerStyle={headerStyle === "secondary" ? "body" : "label"}
      width={width || "100%"}
    >
      <div
        style={{
          width: "8px",
          height: "8px",
          borderRadius: "50%",
          backgroundColor:
            theme[getRiskColor(finding?.overall_risk || 0) || "black600"],
        }}
      />
      {headerStyle === "secondary" ? (
        <HeaderSecondary style={{ color: theme.black700 }}>
          {severity_string}
        </HeaderSecondary>
      ) : (
        <></>
      )}
      {headerStyle === "sub" ? (
        <HeaderSubBold style={{ color: theme.black700 }}>
          {severity_string}
        </HeaderSubBold>
      ) : (
        <></>
      )}
    </Section>
  );
};
