import { CSSProperties, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Icon } from "../../icon/Icon";
import { TypographyLabelMedium } from "../../typography/Typography";
import { Tooltip } from "../../tooltip/Tooltip";

type ButtonProps = {
  iconName: string;
  size?: "very-small" | "small" | "medium";
  onClick?: () => void;
  inProgress?: boolean;
  disabled?: boolean;
  dataTestId?: string;
  color?: string;
  id?: string;
  label?: string;
  style?: CSSProperties;
};

const IconButtonStyle = styled.button<ButtonProps>`
  ${TypographyLabelMedium}
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) =>
    props.size === "medium" ? 40 : props.size === "small" ? 32 : 24}px;
  width: ${(props) =>
    props.size === "medium" ? 40 : props.size === "small" ? 32 : 24}px;
  padding: 4px;
  background-color: transparent;
  color: ${(props) => props.color || props.theme.primary};
  border: 0px;
  border-radius: 50%;
  &:hover:enabled {
    background-color: ${(props) => props.theme.blue50};
  }
  &:active:enabled {
    color: ${(props) => props.theme.secondary};
  }
  &:disabled {
    color: ${(props) => props.theme.black600};
  }
  &:disabled {
    color: ${(props) => props.theme.black600};
  }
  &:focus {
    outline: none;
    border: 1px solid;
    border-color: ${(props) => props.theme.primary};
    background-color: ${(props) => props.theme.blue50};
  }
`;

export const IconButton = (props: ButtonProps) => {
  const {
    size = "medium",
    onClick,
    inProgress = false,
    disabled = false,
    color,
    iconName,
    label,
    style,
  } = props;
  const theme = useContext(ThemeContext);
  return (
    <Tooltip content={label || ""}>
      <IconButtonStyle
        onClick={onClick}
        disabled={disabled || inProgress}
        size={size}
        data-testid={props.dataTestId}
        iconName={iconName}
        color={color}
        id={props.id}
        style={style}
      >
        {inProgress ? (
          <Icon color={color || theme.black600} name="spinner" />
        ) : (
          <Icon
            color={disabled ? theme.black600 : color || theme.primary}
            name={iconName}
            size={size === "medium" ? 32 : 24}
          />
        )}
      </IconButtonStyle>
    </Tooltip>
  );
};
