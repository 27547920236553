import { useContext } from "react";
import { CodeBox } from "../../../components/elements/box/Box";
import { Asset } from "../../../types/Asset";
import { ThemeContext } from "styled-components";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { Icon } from "../../../components/elements/icon/Icon";
import { Flex } from "../../../components/layouts/flex/Flex";

type Props = {
  asset: Asset | undefined;
};

export const AssetWhois = (props: Props) => {
  const { asset } = props;
  const theme = useContext(ThemeContext);

  const whois = asset?.properties?.whois
    ?.replace('b"', "")
    .slice(0, -1)
    .split(/\\n/);
  return (
    <>
      <Flex align="center">
        <Icon name="data" size={32} color={theme.primary} />
        <HeaderSecondary>Raw Whois Data</HeaderSecondary>
      </Flex>
      <CodeBox>
        <div style={{ height: "200px", overflowX: "auto" }}>
          {whois?.map((line) => (
            <div>{line}</div>
          ))}
        </div>
      </CodeBox>
    </>
  );
};
