import { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { LinkButton } from "../../../components/elements/button/link/LinkButton";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { Switch } from "../../../components/elements/switch/Switch";
import {
  LabelRegular,
  HeaderSecondary,
  HeaderSubBold,
  BodyRegular,
  BodyBold,
} from "../../../components/elements/typography/Typography";
import { RightPane } from "../../../components/elements/rightPane/RightPane";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import {
  AccountParams,
  useApiCreateAccount,
  useApiUpdateAccount,
} from "../../../hooks/queries/accountsContext";
import { useApiMe } from "../../../hooks/queries/meContext";
import { Dropdown } from "../../../components/elements/dropdowns/Dropdown";
import { EMAIL_REGEX, ROLE_OPTIONS } from "../../../shared/consts";
import { Product } from "../../../types/Product";
import { FormError } from "../../../components/elements/FormsElements/FormError";
import { Account } from "../../../types/Account";
import useToastContext from "../../../hooks/toastHook";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Mixpanel } from "../../../shared/mixpanel";
import { useApiProjects } from "../../../hooks/queries/projectsContext";
import { Project } from "../../../types/Project";
import { InfoTooltip } from "../../../components/composed/infoTooltip/InfoTooltip";

export interface AccountFormValues extends AccountParams {
  // from User
  name?: string;
  // not implemented
  view_only?: boolean;
  can_manage_auto_scans?: boolean;
}

export interface AccountFormErrors {
  email?: string;
  phone?: string;
  role?: string;
  products_allowed?: string;
  projects_allowed?: string;
}

const AddEditUserForm = ({
  editedAccount,
  setDeletedAccount,
  onClose,
}: {
  editedAccount?: Account;
  setDeletedAccount: React.Dispatch<React.SetStateAction<Account | undefined>>;
  onClose: () => void;
}) => {
  const theme = useContext(ThemeContext);
  const addToast = useToastContext();
  const { data: products } = useApiProducts();
  const { data: projects } = useApiProjects();
  const { data: me } = useApiMe();
  const { mutate: updateAccount } = useApiUpdateAccount();
  const { mutate: createAccount } = useApiCreateAccount();

  const [formErrors, setFormErrors] = useState<AccountFormErrors>({});
  const [formValues, setFormValues] = useState<AccountFormValues>({
    phone: editedAccount?.phone || "",
    view_only: false,
    can_manage_auto_scans: false,
    can_manage_customer: editedAccount?.can_manage_customer,
    customer: me?.customer.id,
    email: editedAccount?.email,
    products_allowed: !!editedAccount?.products_allowed?.length
      ? editedAccount?.products_allowed
      : products?.map((p) => p.id),
    projects_allowed: !!editedAccount?.projects_allowed?.length
      ? editedAccount?.projects_allowed
      : projects?.map((p) => p.id),
    role: editedAccount?.role,
  });

  const [productsSearchWord, setProductsSearchWord] = useState<string>("");
  const [projectsSearchWord, setProjectsSearchWord] = useState<string>("");

  const addProductAccess = (prodId: number): void => {
    if (formValues.products_allowed?.includes(prodId)) return;
    setFormValues({
      ...formValues,
      products_allowed: !!formValues.products_allowed?.length
        ? [...formValues.products_allowed, prodId]
        : [prodId],
    });
  };

  const addProjectAccess = (projectId: number): void => {
    if (formValues.projects_allowed?.includes(projectId)) return;
    setFormValues({
      ...formValues,
      projects_allowed: !!formValues.projects_allowed?.length
        ? [...formValues.projects_allowed, projectId]
        : [projectId],
    });
  };

  const removeProductAccess = (prodId: number): void => {
    setFormValues({
      ...formValues,
      products_allowed: formValues?.products_allowed?.filter(
        (p) => p !== prodId
      ),
    });
  };

  const removeProjectAccess = (project: number): void => {
    setFormValues({
      ...formValues,
      projects_allowed: formValues?.projects_allowed?.filter(
        (p) => p !== project
      ),
    });
  };

  const handleProductsAccessChange = (prodId: number) => {
    setFormErrors({ ...formErrors, products_allowed: "" });
    formValues.products_allowed?.includes(prodId)
      ? formValues.products_allowed?.length === 1
        ? setFormErrors({
            ...formErrors,
            products_allowed: "User must have at least one product allowed",
          })
        : removeProductAccess(prodId)
      : addProductAccess(prodId);
  };

  const handleProjectsAccessChange = (projectId: number) => {
    setFormErrors({ ...formErrors, projects_allowed: "" });
    formValues.projects_allowed?.includes(projectId)
      ? formValues.projects_allowed?.length === 1
        ? setFormErrors({
            ...formErrors,
            projects_allowed: "User must have at least one project allowed",
          })
        : removeProjectAccess(projectId)
      : addProjectAccess(projectId);
  };

  const searchProducts = (prods: Product[]): Product[] =>
    prods.filter(
      (p) =>
        p.name &&
        p.name.toLowerCase().indexOf(productsSearchWord.toLowerCase()) > -1
    );

  const searchProjects = (projects: Project[]): Project[] =>
    projects.filter(
      (p) =>
        p.name &&
        p.name.toLowerCase().indexOf(projectsSearchWord.toLowerCase()) > -1
    );

  const filteredProducts =
    products && (productsSearchWord ? searchProducts(products) : products);

  const filteredProjects =
    projects && (projectsSearchWord ? searchProjects(projects) : projects);

  const validate = (values: AccountFormValues) => {
    const errors: AccountFormErrors = {};

    if (!values.email) errors.email = "Email is required!";
    else if (!EMAIL_REGEX.test(values.email))
      errors.email = "Please enter a valid email!";

    if (formValues.phone)
      if (formValues.phone.substring(0, 1) !== "+")
        errors.phone =
          "Phone number must start with + and international dial code";
    if (/[a-zA-Z]/.test(`${formValues.phone}`))
      errors.phone = "Phone number cannot contain letters";
    if (!values.role) errors.role = "Role is required!";
    else if (values.role.length > 30)
      errors.role = "Role name is too long, 30 characters max.";

    if (formValues.products_allowed && formValues.products_allowed.length < 1)
      errors.products_allowed = "User must have at least one product allowed";

    return errors;
  };

  const handleSubmit = () => {
    const errors = validate(formValues);
    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return;
    }
    apiCall();
  };

  const apiCall = async () => {
    let data: AccountParams = {
      email: formValues.email,
      phone: formValues.phone,
      customer: me?.customer?.id,
      role: formValues.role,
      can_manage_customer: formValues.can_manage_customer,
      products_allowed:
        /// this requires the total products count
        formValues.products_allowed?.length === products?.length ||
        formValues.can_manage_customer
          ? []
          : formValues.products_allowed,
      projects_allowed:
        /// this requires the total products count
        formValues.projects_allowed?.length === projects?.length ||
        formValues.can_manage_customer
          ? []
          : formValues.projects_allowed,
    };
    const callbacks = {
      onSuccessCallback: () => {
        addToast({
          message: `${editedAccount?.email || "User"} has been successfully ${
            !!editedAccount ? "updated" : "created"
          }`,
          type: "success",
        });
        Mixpanel.track(`User ${!!editedAccount ? "updated" : "created"}`);
        onClose();
      },
      onErrorCallback: (error: any) => {
        addToast({
          message: `Error: ${error}`,
          type: "error",
        });
      },
    };
    if (!!editedAccount)
      updateAccount({
        accountId: editedAccount.id,
        accountData: data,
        ...callbacks,
      });
    else createAccount({ ...data, ...callbacks });
  };

  const FormHeader = () => (
    <div
      style={{
        position: "absolute",
        top: "0",
        width: "100%",
        padding: "24px",
        zIndex: "160",
        background: "inherit",
      }}
    >
      <Flex w100 align="center">
        {!!editedAccount ? (
          <div data-testid="edit-user-form-header">
            <LabelRegular>Edit User</LabelRegular>
            <HeaderSecondary>{editedAccount?.user.name}</HeaderSecondary>
          </div>
        ) : (
          <div data-testid="add-user-form-header">
            <LabelRegular>Add User</LabelRegular>
            <HeaderSecondary>{formValues?.name || "New User"}</HeaderSecondary>
          </div>
        )}
        <div className="ms-auto">
          <MainButton label="Save" onClick={handleSubmit} size="medium" />
        </div>
      </Flex>
      <SeparatorHorizontal style={{ marginTop: "24px" }} />
    </div>
  );

  const FormFooter = () =>
    !!editedAccount ? (
      <div
        style={{
          position: "absolute",
          bottom: "0",
          right: "0",
          width: "100%",
          padding: "24px",
          paddingTop: "0",
          zIndex: "160",
          background: "inherit",
        }}
      >
        <SeparatorHorizontal />
        <Flex w100 align="center">
          <div className="ms-auto" style={{ marginTop: "24px" }}>
            <LinkButton
              dataTestId="delete-user-btn"
              label="Delete User"
              iconColor={theme.redPrimary}
              iconName="remove"
              onClick={() => setDeletedAccount(editedAccount)}
            />
          </div>
        </Flex>
      </div>
    ) : null;

  return (
    <RightPane onClose={onClose}>
      <FormHeader />
      <div
        style={{
          marginTop: "110px",
          padding: "24px",
          paddingBottom: "210px",
          width: "100%",
          overflowY: "auto",
          height: "100%",
          position: "relative",
        }}
      >
        <Flex column gap="24px" data-testid="form-body">
          <Flex data-testid="email-field" column>
            <LabelRegular>Email Address</LabelRegular>
            <InputText
              dataTestId="email-input"
              isError={!!formErrors.email}
              color={theme.black800}
              onChange={(e) => {
                setFormErrors({ ...formErrors, email: "" });
                setFormValues({
                  ...formValues,
                  email: `${e.target.value}`.toLowerCase(),
                });
              }}
              value={formValues.email}
              autoFocus={true}
              width={"100%"}
            />
            <FormError errorMessage={formErrors.email} />
          </Flex>

          <Flex data-testid="phone-field" column>
            <LabelRegular>Phone Number</LabelRegular>
            <InputText
              dataTestId="phone-input"
              isError={!!formErrors.phone}
              color={theme.black800}
              onChange={(e) => {
                setFormErrors({ ...formErrors, phone: "" });
                setFormValues({ ...formValues, phone: e.target.value });
              }}
              value={formValues.phone || ""}
              width={"100%"}
            />
            <FormError errorMessage={formErrors.phone} />
          </Flex>

          <div data-testid="role-field" className="d-flex flex-column">
            <LabelRegular>Role</LabelRegular>
            <Dropdown
              dataTestId="role-dropdown"
              creatable
              variant="border"
              isError={!!formErrors.role}
              onChange={(e) => {
                setFormErrors({ ...formErrors, role: "" });
                setFormValues({
                  ...formValues,
                  role: `${e?.value}`.replace("&amp;", "&"),
                });
              }}
              value={
                formValues.role
                  ? {
                      value: formValues.role.replace("&amp;", "&"),
                      label: formValues.role.replace("&amp;", "&"),
                    }
                  : { value: "", label: "Select a Role" }
              }
              options={ROLE_OPTIONS}
            />
            <FormError errorMessage={formErrors.role} />
          </div>

          <SeparatorHorizontal
            style={{ marginTop: "24px", marginBottom: "24px" }}
          />

          <div data-testid="permissions-field">
            <Flex column gap="24px">
              <HeaderSubBold>General Permission</HeaderSubBold>
              <Flex column gap="12px">
                <Flex align="center" w100>
                  <Flex align="center" w100>
                    <Flex gap="8px">
                      <BodyRegular>Organizational Admin</BodyRegular>
                      <InfoTooltip
                        content={
                          <Flex column gap="8px">
                            <BodyBold>Organizational admin can:</BodyBold>
                            <BodyRegular>
                              Add users and set permissions
                            </BodyRegular>
                            <BodyRegular>Add products</BodyRegular>
                            <BodyRegular>Manage scans</BodyRegular>
                            <BodyRegular>
                              Manage integrations and automation
                            </BodyRegular>
                            <BodyRegular>Manage SLA policy</BodyRegular>
                            {me?.customer.is_multi_tenant && (
                              <>
                                <BodyRegular>Add security findings</BodyRegular>
                                <BodyRegular>Add projects</BodyRegular>
                              </>
                            )}
                          </Flex>
                        }
                      />
                    </Flex>
                    <Switch
                      className="ms-auto"
                      checked={!!formValues.can_manage_customer}
                      onChange={() => {
                        setFormValues({
                          ...formValues,
                          can_manage_customer: !formValues.can_manage_customer,
                          // products_allowed: products?.map((prod) => prod.id),
                        });
                      }}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </div>

          <SeparatorHorizontal
            style={{ marginTop: "24px", marginBottom: "24px" }}
          />
          {formValues.can_manage_customer ? (
            <Flex justify="center">
              <BodyRegular>
                All projects and products are allowed for organizational admin
              </BodyRegular>
            </Flex>
          ) : (
            <>
              <div
                data-testid="products-permissions"
                className="d-flex flex-column gap-24"
              >
                <HeaderSubBold>Products Permissions</HeaderSubBold>
                <FormError errorMessage={formErrors.products_allowed} />
                <InputText
                  data-testid="product-search-bar"
                  onChange={(e) => setProductsSearchWord(e.target.value)}
                  placeholder="Search"
                  iconName="search"
                  width={"100%"}
                />
                <div
                  className="d-flex flex-column gap-12"
                  style={{ padding: "16px" }}
                >
                  {filteredProducts?.map((p) => (
                    <div key={p.id} className="d-flex align-items-center w-100">
                      <BodyRegular>{p.name}</BodyRegular>
                      <Switch
                        dataTestId={`${p.name}-switch`}
                        className="ms-auto"
                        checked={
                          !formValues.products_allowed?.length ||
                          !!formValues.products_allowed?.includes(p.id)
                        }
                        onChange={() => handleProductsAccessChange(p.id)}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <SeparatorHorizontal
                style={{ marginTop: "24px", marginBottom: "24px" }}
              />

              <div
                data-testid="projects-permissions"
                className="d-flex flex-column gap-24"
              >
                <HeaderSubBold>Projects Permissions</HeaderSubBold>
                <FormError errorMessage={formErrors.projects_allowed} />
                <InputText
                  data-testid="projects-search-bar"
                  onChange={(e) => setProjectsSearchWord(e.target.value)}
                  placeholder="Search"
                  iconName="search"
                  width={"100%"}
                />
                <div
                  className="d-flex flex-column gap-12"
                  style={{ padding: "16px" }}
                >
                  {filteredProjects?.map((p) => (
                    <div key={p.id} className="d-flex align-items-center w-100">
                      <BodyRegular>{p.name}</BodyRegular>
                      <Switch
                        dataTestId={`${p.name}-switch`}
                        className="ms-auto"
                        checked={
                          !formValues.projects_allowed?.length ||
                          !!formValues.projects_allowed?.includes(p.id)
                        }
                        onChange={() => handleProjectsAccessChange(p.id)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </Flex>
      </div>
      <FormFooter />
    </RightPane>
  );
};

export default AddEditUserForm;
