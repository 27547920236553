import { useContext } from "react";
import { CSSObject, ThemeContext } from "styled-components";
import { HeaderMain } from "../../components/elements/typography/Typography";
import { Box } from "../../components/elements/box/Box";
import { AssetsBarChart } from "./assetsBarChart/AssetsBarChart";
import { ProductsExposure } from "./productsExposure/ProductsExposure";
import { CwePieChart } from "./cwePieChart/CwePieChart";
import { Flex } from "../../components/layouts/flex/Flex";
import { useScreenWidth } from "../../hooks/utilsHooks";
import { SCREEN_LAPTOP_WIDTH } from "../../shared/consts";
import { useTrackPage } from "../../hooks/trackingHooks";
import { Tours } from "../../tours/Tours";
import { insightsSteps } from "../../tours/InsightsTourSteps";
import { AlertsBanners } from "../alertsBanners/AlertsBanners";
import { useApiMe } from "../../hooks/queries/meContext";
import { MttrChart } from "./MttrChart";
import { AverageFindingAgeChart } from "./AverageFindingAgeChart";
import { FixedFindingsOvertimeChart } from "./FixedFindingsOvertimeChart";
import { useIsSuperuser } from "../../hooks/useIsSuperuser";
import { SecurityBenchmark } from "./SecurityBenchmark";
import { FindingsTrends } from "./findingsTrendsGraph/FindingsTrends";
// import { ProductsBarChart } from "./productsBarChart.tsx/productsBarChart";

export const legendContainerStyle: CSSObject = {
  height: "303px",
  overflowY: "auto",
  margin: "24px",
};

export const chartsFont = {
  family: "poppins",
  size: 12,
  lineHeight: "21px",
  weight: "700",
};

const Insights = () => {
  const theme = useContext(ThemeContext);
  const screenWidth = useScreenWidth();
  const isLaptop = screenWidth < SCREEN_LAPTOP_WIDTH;
  const { data: me } = useApiMe();
  const isSuperUser = useIsSuperuser();
  useTrackPage("Insights", undefined, me);

  return (
    <Flex
      column
      w100
      gap="24px"
      data-testid="insights-page"
      style={{ paddingBottom: "8px" }}
    >
      <AlertsBanners />
      <Tours steps={insightsSteps(theme)} />
      <HeaderMain style={{ color: theme.black900 }}>Insights</HeaderMain>
      <Flex column={isLaptop} gap="24px" w100>
        <Box
          style={{ height: "420px", width: "100%" }}
          data-tut="most-frequent"
        >
          <CwePieChart />
        </Box>
        <Box
          style={{ height: "420px", width: "100%" }}
          data-tut="prod-exposure"
        >
          <ProductsExposure />
        </Box>
      </Flex>
      <Box data-tut="assets-dist">
        <AssetsBarChart />
      </Box>

      {isSuperUser && (
        <>
          <Box>
            <MttrChart />
          </Box>
          <Box>
            <AverageFindingAgeChart />
          </Box>
          <Box>
            <FixedFindingsOvertimeChart />
          </Box>
          <Box>
            <SecurityBenchmark />
          </Box>
          <Box>
            <FindingsTrends />
          </Box>
        </>
      )}
    </Flex>
  );
};

export default Insights;
