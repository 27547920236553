import React, { useContext } from "react";
import { Box } from "../../../components/elements/box/Box";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  LabelBold,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { ThemeContext } from "styled-components";
import { CustomerLog } from "../../../types/CustomerLog";
import { CardHeader } from "../../findings/findingHistoryAndComments/CardHeader";
import { useApiScanners } from "../../../hooks/queries/scannersContext";
import { useApiMe } from "../../../hooks/queries/meContext";

type Props = {
  customerLog: CustomerLog;
};

export function CustomerLogCard({ customerLog }: Props) {
  const theme = useContext(ThemeContext);
  const { data: me } = useApiMe();
  const { data: scanners } = useApiScanners(me?.customer?.id);

  const getScannerDisplayName = (
    scannersNames: string[]
  ): string[] | undefined => {
    return scanners
      ?.filter((scanner) => scannersNames.includes(scanner.name))
      .map((scanner) => scanner.display_name);
  };

  const getValueString = (value: string): string => {
    if (customerLog.field === "asm_config") {
      try {
        let correctedString = value.replace(/'/g, '"');
        let asmConfigJson = JSON.parse(correctedString);
        return (asmConfigJson.custom_user_agent as string) || "Disabled";
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
    if (customerLog.field === "disabled_scanners") {
      try {
        let correctedString = value.replace(/'/g, '"');
        let disabledScannersList = JSON.parse(correctedString);
        const scannersDisplayNames =
          getScannerDisplayName(disabledScannersList);

        return scannersDisplayNames?.join(", ") || (value as string);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
    return value as string;
  };

  const getFieldString = (): string => {
    if (customerLog.field === "asm_config") {
      return "Custom User Agent";
    } else {
      // remove the underscores and capitalize the first letter of each word
      return customerLog.field
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());
    }
  };

  return (
    <Box>
      <Flex column gap="16px">
        <CardHeader
          userName={customerLog.actor.name}
          avatarUrl={customerLog.actor.avatar_url}
          createdAt={customerLog.created_at}
        />
        {customerLog.action === "CUSTOMER_UPDATED" ? (
          <Flex align="center" gap="8px" flexWrap>
            <LabelRegular color={theme.black600}>The field</LabelRegular>
            <LabelBold className="text-capitalize">
              {getFieldString()}
            </LabelBold>
            <LabelRegular
              color={theme.black600}
            >{`has been changed to`}</LabelRegular>
            <LabelBold className="text-capitalize">
              {getValueString(customerLog.new_value)}
            </LabelBold>
          </Flex>
        ) : customerLog.action === "ALL_SCANS_STOPPED" ? (
          <Flex align="center" gap="8px" flexWrap>
            <LabelBold>All scanners have been stopped</LabelBold>
          </Flex>
        ) : (
          <Flex align="center" gap="8px" flexWrap>
            <LabelRegular color={theme.black600}>Scanner</LabelRegular>
            <LabelBold>
              {getScannerDisplayName([customerLog.new_value]) ||
                customerLog.new_value}
            </LabelBold>
            <LabelRegular color={theme.black600}>has been</LabelRegular>
            <LabelBold>
              {customerLog.action === "SCAN_STARTED" ? "started" : "stopped"}
            </LabelBold>
          </Flex>
        )}
      </Flex>
    </Box>
  );
}
