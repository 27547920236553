import { SingleValue } from "react-select";
import {
  Dropdown,
  Option,
} from "../../../components/elements/dropdowns/Dropdown";
import { useState } from "react";
import { Account } from "../../../types/Account";
import { useApiAccountsPaging } from "../../../hooks/queries/accountsContext";

type Props = {
  onSelect: (user: Account | undefined) => void;
  placeholder?: string;
  valueByEmail?: string;
  size?: "medium" | "large" | "small";
  isClearable?: boolean;
};

export const AccountsDropdown = (props: Props) => {
  const {
    onSelect,
    placeholder = "",
    valueByEmail,
    size,
    isClearable = false,
  } = props;

  const [searchWord, setSearchWord] = useState("");

  const { data: accountsPages } = useApiAccountsPaging({ search: searchWord });

  const getAccounts = (): Account[] => {
    return accountsPages?.pages.map((page) => page.results || []).flat() || [];
  };

  const options = getAccounts()?.map((a) => ({
    label: `${a.user.first_name} ${a.user.last_name}`,
    value: `${a.email}`,
  }));

  const onChange = (opt: SingleValue<Option>) => {
    setSelectedAccountOption(opt);
    const account = getAccounts()?.find((u) => u.email === opt?.value);
    onSelect(account);
  };

  const getOptionByEmail = (email: string) =>
    options?.filter((opt) => opt.value === email)[0];

  const [selectedAccountOption, setSelectedAccountOption] = useState<
    SingleValue<Option>
  >(valueByEmail ? getOptionByEmail(valueByEmail) : null);

  return (
    <Dropdown
      size={size}
      variant="border"
      placeholder={placeholder}
      value={selectedAccountOption}
      options={options || []}
      onChange={onChange}
      onInputChange={(newValue) => setSearchWord(newValue)}
      searchable
      clearable={isClearable}
    />
  );
};
