import { useEffect, useState } from "react";
import { Asset } from "../../../types/Asset";
import { useApiVerifyAsset } from "../../../hooks/queries/assetsContext";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyMediumHover,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { AssetVerificationIndicator } from "./AssetVerificationIndicator";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";
import useToastContext from "../../../hooks/toastHook";
import { Link } from "react-router-dom";
import { useApiMe } from "../../../hooks/queries/meContext";

type Props = {
  asset: Asset | undefined;
  onVerification?: (asset: Asset) => void;
  disabled?: boolean;
};

export const VerifyAssetForm = (props: Props) => {
  const { asset, onVerification, disabled } = props;
  const addToast = useToastContext();
  const { data: me } = useApiMe();
  const [currentAsset, setCurrentAsset] = useState<Asset | undefined>(asset);

  const { mutate: verify, isLoading } = useApiVerifyAsset();

  const verifyAsset = () =>
    asset &&
    verify({
      assetId: asset.id,
      assetData: asset,
      onSuccessCallback: (assetData) => {
        setCurrentAsset(assetData);
        onVerification && onVerification(assetData);
        if (!assetData.is_verified)
          addToast({
            type: "error",
            message: "Token was not found on your DNS records",
          });
      },
      onErrorCallback: (err) =>
        addToast({
          type: "success",
          message: `Failed to verify asset ownership | ${err}`,
        }),
    });

  useEffect(() => {
    asset && setCurrentAsset(asset);
  }, [asset]);

  return (
    <Flex column gap="24px" align="center" justify="center" w100>
      <Flex justify="center" w100 style={{ height: "40px" }}>
        {!disabled && (
          <AssetVerificationIndicator
            isLoading={isLoading}
            asset={currentAsset}
          />
        )}
      </Flex>
      <Flex align="center" gap="8px" flexWrap>
        <LabelRegular>Verification Token</LabelRegular>
        <InputText
          disabled
          copyToClipboard
          value={me?.customer?.assets_verification_token}
          width={"330px"}
        />
        <Flex align="center" justify="end" gap="12px">
          <SecondaryButton
            size="small"
            label="Verify Domain"
            onClick={verifyAsset}
            disabled={isLoading || disabled}
          />
        </Flex>
      </Flex>
      <Flex justify="center" w100>
        <Link
          style={{ textDecoration: "none" }}
          target="_blank"
          to="https://opinnovate.notion.site/WASP-Domains-verification-a1b9bf8afee6491e80df63367871a375"
        >
          <BodyMediumHover>
            Click here to learn how to add tokens to your DNS records
          </BodyMediumHover>
        </Link>
      </Flex>
    </Flex>
  );
};
