import React, { Dispatch, SetStateAction, useContext } from "react";
import { SeverityDropdown } from "../../../../components/composed/severityDropdown/SeverityDropdown";
import { TagsLine } from "../../../../components/composed/tagsLine/TagsLine";
import { FormError } from "../../../../components/elements/FormsElements/FormError";
import { Box } from "../../../../components/elements/box/Box";
import { Dropdown } from "../../../../components/elements/dropdowns/Dropdown";
import { Icon } from "../../../../components/elements/icon/Icon";
import { InputText } from "../../../../components/elements/input/textInput/InputText";
import {
  RequiredField,
  OptionalField,
} from "../../../../components/elements/requiredField/RequiredField";
import {
  HeaderSecondary,
  LabelRegular,
} from "../../../../components/elements/typography/Typography";
import { Flex } from "../../../../components/layouts/flex/Flex";
import {
  FormModeState,
  getOptionFromKeyValuePairs,
  mapToOptions,
  useUpdateFindingInPlace,
} from "../../../../shared/formUtils";
import { cweMap } from "../../../insights/cwePieChart/cweMap";
import { ThemeContext } from "styled-components";
import { SEVERITIES } from "../../../../shared/consts";
import {
  AdminFindingEdit,
  Finding,
  FindingLoadingMap,
} from "../../../../types/Finding";
import { useParams } from "react-router";

type Props = {
  formMode: FormModeState;
  loadingStates: FindingLoadingMap;
  setLoadingStates: Dispatch<SetStateAction<FindingLoadingMap>>;
  createFindingData: AdminFindingEdit;
  setCreateFinding: Dispatch<SetStateAction<AdminFindingEdit>>;
  updateFindingData?: Finding;
  setUpdateFinding: Dispatch<SetStateAction<Finding | undefined>>;
  editableUpdateFindingData: AdminFindingEdit | null;
  setEditableUpdateFinding: Dispatch<SetStateAction<AdminFindingEdit | null>>;
  isCorrectCvssScore: boolean;
  isCorrectExploitability: boolean;
  isCorrectOverallRisk: boolean;
  setCorrectOverallRisk: Dispatch<SetStateAction<boolean>>;
  setCorrectExploitability: Dispatch<SetStateAction<boolean>>;
  setCorrectCvssScore: Dispatch<SetStateAction<boolean>>;
  isSubmitClicked: boolean;
};

export const RiskAssessment = (props: Props) => {
  const {
    formMode,
    loadingStates,
    setLoadingStates,
    createFindingData,
    setCreateFinding,
    updateFindingData,
    setUpdateFinding,
    editableUpdateFindingData,
    setEditableUpdateFinding,
    isCorrectCvssScore,
    isCorrectExploitability,
    isCorrectOverallRisk,
    setCorrectOverallRisk,
    setCorrectExploitability,
    setCorrectCvssScore,
    isSubmitClicked,
  } = props;
  const theme = useContext(ThemeContext);
  const { id: updateFindingId } = useParams();
  const [updateInPlace, isLoading] = useUpdateFindingInPlace(
    parseInt(updateFindingId ? updateFindingId : "0"),
    setUpdateFinding,
    updateFindingData,
    setLoadingStates
  );

  const validateScore = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) > 10 || Number(e.target.value) < 0) {
      setCorrectCvssScore(false);
    } else {
      setCorrectCvssScore(true);
    }
  };

  return (
    <Box style={{ width: "100%" }}>
      <Flex column gap="32px">
        <Flex gap="8px">
          <Icon name="data" size={30} color={theme.primary} />{" "}
          <HeaderSecondary>Risk Assessment</HeaderSecondary>
        </Flex>
        <Flex gap="32px" w100>
          <Flex column style={{ width: "50%" }} gap="16px">
            <Flex column gap="8px">
              <Flex justify="between">
                <Flex>
                  <LabelRegular>Overall Risk</LabelRegular>
                  <RequiredField />
                </Flex>
                {isLoading && loadingStates.overall_risk && (
                  <Icon name="spinner" size={20} />
                )}
              </Flex>
              <SeverityDropdown
                dataTestId="overall-risk"
                onChange={(opt) => {
                  if (opt?.value) {
                    if (formMode === FormModeState.Update) {
                      setEditableUpdateFinding((prev) => ({
                        ...prev,
                        overall_risk: Number(opt.value),
                      }));
                      updateInPlace({ overall_risk: Number(opt.value) });
                      setLoadingStates((prev) => ({
                        ...prev,
                        overall_risk: true,
                      }));
                    }
                    if (formMode === FormModeState.Create) {
                      setCreateFinding((prev) => ({
                        ...prev,
                        overall_risk: Number(opt.value),
                      }));
                    }
                  }
                  setCorrectOverallRisk(true);
                }}
                value={
                  formMode === FormModeState.Update
                    ? editableUpdateFindingData?.overall_risk !== undefined &&
                      editableUpdateFindingData.overall_risk > -1
                      ? getOptionFromKeyValuePairs(
                          SEVERITIES,
                          editableUpdateFindingData.overall_risk
                        )
                      : null
                    : createFindingData?.overall_risk !== undefined &&
                      createFindingData.overall_risk > -1
                    ? getOptionFromKeyValuePairs(
                        SEVERITIES,
                        createFindingData.overall_risk
                      )
                    : null
                }
              />
              <FormError
                errorMessage={
                  !isCorrectOverallRisk && isSubmitClicked
                    ? "Please fill in the Overall Risk field"
                    : ""
                }
              />
            </Flex>
            <Flex column gap="8px">
              <Flex justify="between">
                <Flex>
                  <LabelRegular>Exploitability</LabelRegular>
                  <RequiredField />
                </Flex>
                {isLoading && loadingStates.exploitability && (
                  <Icon name="spinner" size={20} />
                )}
              </Flex>
              <SeverityDropdown
                dataTestId="exploitability"
                onChange={(opt) => {
                  if (opt?.value) {
                    if (formMode === FormModeState.Update) {
                      setEditableUpdateFinding((prev) => ({
                        ...prev,
                        exploitability: Number(opt.value),
                      }));
                      updateInPlace({ exploitability: Number(opt.value) });
                      setLoadingStates((prev) => ({
                        ...prev,
                        exploitability: true,
                      }));
                    }
                    if (formMode === FormModeState.Create) {
                      setCreateFinding((prev) => ({
                        ...prev,
                        exploitability: Number(opt.value),
                      }));
                    }
                  }
                  setCorrectExploitability(true);
                }}
                value={
                  formMode === FormModeState.Update
                    ? editableUpdateFindingData?.exploitability !== undefined &&
                      editableUpdateFindingData.exploitability !== null &&
                      editableUpdateFindingData.exploitability > -1
                      ? getOptionFromKeyValuePairs(
                          SEVERITIES,
                          editableUpdateFindingData.exploitability
                        )
                      : null
                    : createFindingData?.exploitability !== undefined &&
                      createFindingData.exploitability !== null &&
                      createFindingData.exploitability > -1
                    ? getOptionFromKeyValuePairs(
                        SEVERITIES,
                        createFindingData.exploitability
                      )
                    : null
                }
              />
              <FormError
                errorMessage={
                  !isCorrectExploitability && isSubmitClicked
                    ? "Please fill in the Exploitability field"
                    : ""
                }
              />
              <Flex></Flex> {/* Added for alignment of rows and columns */}
              <Flex></Flex> {/* Added for alignment of rows and columns */}
            </Flex>
            <Flex column gap="8px">
              <Flex justify="between">
                <Flex>
                  <LabelRegular>CWE ID</LabelRegular>
                  <OptionalField />
                </Flex>
                {isLoading && loadingStates.cwe_id && (
                  <Icon name="spinner" size={20} />
                )}
              </Flex>
              <Dropdown
                searchable
                options={mapToOptions(cweMap)}
                variant="border"
                size="medium"
                value={
                  formMode === FormModeState.Update
                    ? editableUpdateFindingData?.cwe_id !== null &&
                      editableUpdateFindingData?.cwe_id !== undefined
                      ? getOptionFromKeyValuePairs(
                          cweMap,
                          editableUpdateFindingData.cwe_id
                        )
                      : null
                    : createFindingData?.cwe_id !== null &&
                      createFindingData?.cwe_id !== undefined
                    ? getOptionFromKeyValuePairs(
                        cweMap,
                        createFindingData.cwe_id
                      )
                    : null
                }
                onChange={(opt) => {
                  if (opt?.value) {
                    if (formMode === FormModeState.Update) {
                      setEditableUpdateFinding((prev) => ({
                        ...prev,
                        cwe_id: Number(opt.value),
                      }));
                      updateInPlace({ cwe_id: Number(opt.value) });
                      setLoadingStates((prev) => ({ ...prev, cwe_id: true }));
                    }
                    if (formMode === FormModeState.Create) {
                      setCreateFinding((prev) => ({
                        ...prev,
                        cwe_id: Number(opt.value),
                      }));
                    }
                  }
                }}
              />
            </Flex>
          </Flex>
          <Flex column style={{ width: "50%" }} gap="32px">
            <Flex column gap="8px">
              <Flex justify="between">
                <Flex>
                  <LabelRegular>CVEs</LabelRegular>
                  <OptionalField />
                </Flex>
                {isLoading && loadingStates.cve_ids && (
                  <Icon name="spinner" size={20} />
                )}
              </Flex>
              <TagsLine
                isEditable={true}
                selectedTags={
                  formMode === FormModeState.Update
                    ? editableUpdateFindingData?.cve_ids
                      ? editableUpdateFindingData.cve_ids
                      : []
                    : createFindingData?.cve_ids
                    ? createFindingData.cve_ids
                    : []
                }
                onTagAdd={(tagCve: string) => {
                  if (formMode === FormModeState.Update) {
                    setEditableUpdateFinding((prev) => ({
                      ...prev,
                      cve_ids: prev?.cve_ids
                        ? [...prev.cve_ids, tagCve]
                        : [tagCve],
                    }));
                    updateInPlace({
                      cve_ids: editableUpdateFindingData?.cve_ids
                        ? [...editableUpdateFindingData.cve_ids, tagCve]
                        : [tagCve],
                    });
                    setLoadingStates((prev) => ({ ...prev, cve_ids: true }));
                  }
                  if (formMode === FormModeState.Create) {
                    setCreateFinding((prev) => ({
                      ...prev,
                      cve_ids: prev?.cve_ids
                        ? [...prev.cve_ids, tagCve]
                        : [tagCve],
                    }));
                  }
                }}
                onTagRemove={(tagCve: string) => {
                  if (formMode === FormModeState.Update) {
                    setEditableUpdateFinding((prev) => ({
                      ...prev,
                      cve_ids: prev?.cve_ids
                        ? prev.cve_ids.filter((cve) => cve !== tagCve)
                        : [],
                    }));
                    updateInPlace({
                      cve_ids: editableUpdateFindingData?.cve_ids
                        ? editableUpdateFindingData.cve_ids.filter(
                            (cve) => cve !== tagCve
                          )
                        : [],
                    });
                    setLoadingStates((prev) => ({ ...prev, cve_ids: true }));
                  }
                  if (formMode === FormModeState.Create) {
                    setCreateFinding((prev) => ({
                      ...prev,
                      cve_ids: prev?.cve_ids
                        ? prev.cve_ids.filter((cve) => cve !== tagCve)
                        : [],
                    }));
                  }
                }}
              />
            </Flex>
            <Flex column gap="8px">
              <Flex justify="between">
                <Flex>
                  <LabelRegular>CVSS Score</LabelRegular>
                  <OptionalField />
                </Flex>
                {isLoading && loadingStates.cvss_score && (
                  <Icon name="spinner" size={20} />
                )}
              </Flex>
              <InputText
                saveChangesMode={formMode === FormModeState.Update}
                onSave={() => {
                  updateInPlace({
                    cvss_score: Number(editableUpdateFindingData?.cvss_score),
                  });
                  setLoadingStates((prev) => ({ ...prev, cvss_score: true }));
                }}
                onCancel={() => {
                  setEditableUpdateFinding((prev) => ({
                    ...prev,
                    cvss_score: updateFindingData?.cvss_score,
                  }));
                }}
                width="100%"
                placeholder="Insert Score..."
                value={
                  formMode === FormModeState.Update
                    ? editableUpdateFindingData?.cvss_score
                      ? String(editableUpdateFindingData.cvss_score)
                      : "0"
                    : createFindingData?.cvss_score
                    ? String(createFindingData.cvss_score)
                    : "0"
                }
                onChange={(e) => {
                  if (formMode === FormModeState.Update) {
                    setEditableUpdateFinding((prev) => ({
                      ...prev,
                      cvss_score: Number(e.target.value),
                    }));
                  }
                  if (formMode === FormModeState.Create) {
                    setCreateFinding((prev) => ({
                      ...prev,
                      cvss_score: Number(e.target.value),
                    }));
                  }
                  validateScore(e);
                }}
              />
              <FormError
                errorMessage={
                  !isCorrectCvssScore && isSubmitClicked
                    ? "Please fill in a number (0-10) in the CVSS Score field"
                    : ""
                }
              />
            </Flex>
            <Flex column gap="8px">
              <Flex justify="between">
                <Flex>
                  <LabelRegular>CVSS Vector</LabelRegular>
                  <OptionalField />
                </Flex>
                {isLoading && loadingStates.cvss_vector && (
                  <Icon name="spinner" size={20} />
                )}
              </Flex>
              <InputText
                saveChangesMode={formMode === FormModeState.Update}
                onSave={() => {
                  updateInPlace({
                    cvss_vector: editableUpdateFindingData?.cvss_vector,
                  });
                  setLoadingStates((prev) => ({ ...prev, cvss_vector: true }));
                }}
                onCancel={() => {
                  setEditableUpdateFinding((prev) => ({
                    ...prev,
                    cvss_vector: updateFindingData?.cvss_vector,
                  }));
                }}
                width="100%"
                placeholder="Insert Vector..."
                value={
                  formMode === FormModeState.Update
                    ? editableUpdateFindingData?.cvss_vector
                      ? editableUpdateFindingData.cvss_vector
                      : ""
                    : createFindingData?.cvss_vector
                    ? createFindingData.cvss_vector
                    : ""
                }
                onChange={(e) => {
                  if (formMode === FormModeState.Update) {
                    setEditableUpdateFinding((prev) => ({
                      ...prev,
                      cvss_vector: e.target.value,
                    }));
                  }
                  if (formMode === FormModeState.Create) {
                    setCreateFinding((prev) => ({
                      ...prev,
                      cvss_vector: e.target.value,
                    }));
                  }
                }}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
