import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Icon } from "./Icon";

export const TrendUpGreenIcon = () => {
  const theme = useContext(ThemeContext);
  return (
    <span
      style={{
        borderRadius: "50%",
        background: theme.green50,
        width: "32px",
        height: "32px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Icon name="trendUp" size={24} color={theme.greenPrimary} />
    </span>
  );
};
