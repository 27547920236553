import React, { useContext } from "react";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  KPILarge,
  LabelMini,
} from "../../../components/elements/typography/Typography";
import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import { pieChartSkeletonData } from "../../../shared/consts";
import { ThemeContext } from "styled-components";
import { getTooltip } from "./externalTooltip";

export type DoughnutChartDataSet = {
  data: number[];
  backgroundColor: string[];
};

type Props = {
  labelNumber: number;
  labelText: string;
  isLoadingData?: boolean;
  labels: string[];
  datasets: DoughnutChartDataSet[];
  size?: number;
  labelNumberSize?: number;
  labelTextSize?: number;
  top?: string;
  labelNumberLineHeight?: string;
};

export const DoughnutChart = ({
  labelNumber,
  labelText,
  isLoadingData,
  labels,
  datasets,
  size,
  labelNumberSize,
  labelTextSize,
  top,
  labelNumberLineHeight = "48px",
}: Props) => {
  const theme = useContext(ThemeContext);
  return (
    <div
      style={{
        position: "relative",
        width: `${size || 100}px`,
        height: `${size || 100}px`,
      }}
    >
      <Flex
        column
        w100
        className="text-center"
        style={{
          position: "absolute",
          top: top || "20%",
          left: "0",
        }}
      >
        <KPILarge
          style={{
            lineHeight: labelNumberLineHeight,
            fontSize: labelNumberSize || "",
          }}
        >
          {labelNumber}
        </KPILarge>
        <LabelMini
          className="text-truncate w-100"
          title={labelText}
          style={{
            fontSize: labelTextSize || "",
          }}
        >
          {labelText}
        </LabelMini>
      </Flex>

      <Doughnut
        options={{
          cutout: "75%",
          elements: { arc: { borderColor: theme.bg2 } },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
              mode: "point",
              external: (context: any) => getTooltip(context, theme, "bar"),
              usePointStyle: true,
            },
          },
        }}
        data={
          isLoadingData
            ? pieChartSkeletonData
            : { labels: labels, datasets: datasets }
        }
      />
    </div>
  );
};
