import { useContext, useRef, useState } from "react";
import { ThemeContext } from "styled-components";
import { useApiAssetsBreakdown } from "../../../hooks/queries/assetsContext";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ScaleChartOptions,
  DatasetChartOptions,
  PluginChartOptions,
  ElementChartOptions,
  CoreChartOptions,
  BarControllerChartOptions,
  ChartEvent,
} from "chart.js";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { Icon } from "../../../components/elements/icon/Icon";
import {
  Dropdown,
  Option,
} from "../../../components/elements/dropdowns/Dropdown";
import {
  assetsDistributionOptions,
  defaultAssetDistribution,
} from "../../../shared/consts";
import { Flex } from "../../../components/layouts/flex/Flex";
import { SingleValue } from "react-select";
import { NoDataAvailable } from "../NoDataAvailable";
import { _DeepPartialObject } from "chart.js/dist/types/utils";
import { getTooltip } from "../../../components/composed/charts/externalTooltip";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { Mixpanel } from "../../../shared/mixpanel";
import { toBase64AssetsView } from "../../../shared/helper";
import { useNavigate } from "react-router";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.font.family = "poppins";
ChartJS.defaults.font.size = 11;
ChartJS.defaults.font.lineHeight = "21px";
// ChartJS.defaults.font.weight = "700";

const sortOptions = [
  { value: "desc", label: "High to Low" },
  { value: "asc", label: "Low to High" },
];

type BarChartOptions = _DeepPartialObject<
  CoreChartOptions<"bar"> &
    ElementChartOptions<"bar"> &
    PluginChartOptions<"bar"> &
    DatasetChartOptions<"bar"> &
    ScaleChartOptions<"bar"> &
    BarControllerChartOptions
>;

export const AssetsBarChart = () => {
  const theme = useContext(ThemeContext);
  const chartRef = useRef<ChartJS<"bar">>(null);
  const navigate = useNavigate();

  const [distribution, setDistribution] = useState<SingleValue<Option>>(
    defaultAssetDistribution
  );
  const [selectedSorting, setSelectedSorting] = useState<SingleValue<Option>>(
    sortOptions[0]
  );

  const { data: assetsBreakdown, isFetching } = useApiAssetsBreakdown(
    distribution?.value.toString() || defaultAssetDistribution.value,
    `${selectedSorting?.value}`
  );

  const dataSet =
    assetsBreakdown &&
    Object.keys(assetsBreakdown).map((key, i) => ({
      label: key,
      value: Object.values(assetsBreakdown)[i],
    }));

  const barChartOptions: BarChartOptions = {
    responsive: true,
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
        mode: "point",
        external: (context: any) =>
          getTooltip(context, theme, "bar", 0, "Click to view on assets page"),
        usePointStyle: true,
      },
    },
    indexAxis: "x",
    elements: {
      bar: {
        // borderColor: "transparent",
        borderRadius: 25,
        borderSkipped: false,
        borderWidth: 1,
        inflateAmount: 0,
      },
    },
    scales: {
      x: {
        ticks: {
          color: theme.textSecondary,
        },
        grid: { color: theme.separation },
      },
      y: {
        grid: { color: theme.separation },
        ticks: { stepSize: 1 },
      },
    },
    onClick: (e: ChartEvent) => {
      const canvas = e.native?.target as HTMLCanvasElement;
      const chart = ChartJS.getChart(canvas); // Get the chart instance
      if (!chart) return;
      const elements = chart.getElementsAtEventForMode(
        e as unknown as Event, // Casting to Event for compatibility
        "nearest",
        { intersect: true },
        false
      );
      if (elements.length > 0) {
        const firstElement = elements[0];
        const labelIndex = firstElement.index;
        const label = chart.data.labels![labelIndex];

        const view = toBase64AssetsView({
          name: "",
          filters: [
            {
              column: `${distribution?.value}`,
              value: `${label}`,
              condition:
                distribution?.value === "status_code" ? "is" : "contains",
              order: 0,
              next_condition: "and",
            },
          ],
        });

        navigate(`/assets?view=${view}`);
      }
    },
  };

  const chartData = {
    labels: dataSet?.map((d) => d.label),
    datasets: [
      {
        label: "",
        data: dataSet?.map(() => 0),
        backgroundColor: "transparent",
      },
      {
        label: "Assets",
        data: dataSet?.map((d) => d.value),
        backgroundColor: theme.blue500,
      },
      {
        label: "",
        data: dataSet?.map(() => 0),
        backgroundColor: "transparent",
      },
    ],
  };

  return (
    <Flex column gap="12px">
      <Flex align="center" gap="8px">
        <Icon name="insightsOutline" size={24} color={theme.primary} />
        <HeaderSecondary>Distribution of Assets by</HeaderSecondary>
        <SeparatorVertical height="16px" />

        <div>
          <Dropdown
            onChange={(option) => {
              Mixpanel.track("Distribution of Assets selected", {
                selected: option?.label,
              });
              setDistribution(option);
            }}
            value={distribution}
            options={assetsDistributionOptions}
            isBold
          />
        </div>

        <Flex align="center" className="ms-auto">
          <Icon name="sort" color={theme.primary} />
          <Dropdown
            onChange={(option) => {
              Mixpanel.track("Sorting changed", { selected: option?.label });
              setSelectedSorting(option);
            }}
            value={selectedSorting}
            options={sortOptions}
          />
        </Flex>
      </Flex>

      <div style={{ position: "relative" }}>
        <Bar
          height={50}
          options={barChartOptions}
          data={chartData}
          ref={chartRef}
        />
        {!isFetching && !Object.keys(assetsBreakdown || {}).length && (
          <NoDataAvailable />
        )}
      </div>
    </Flex>
  );
};
