import React, { useContext, useEffect, useState } from "react";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { Icon } from "../../../../components/elements/icon/Icon";
import { ThemeContext } from "styled-components";
import { NodeProps } from "@xyflow/react";
import { LabelMini } from "../../../../components/elements/typography/Typography";
import {
  KPISmall,
  LabelMedium,
} from "../../../../components/elements/typography/Typography";
import { IconButton } from "../../../../components/elements/button/icon/IconButton";

export const NodeDomain = ({ data }: NodeProps) => {
  const theme = useContext(ThemeContext);

  const [isHidden, setIsHidden] = useState(!data.isExpand);

  // this useEffect help us make the nodes appear with a delay
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsHidden(!data.isExpand);
    }, 100);

    return () => clearTimeout(timer);
  }, [data.isExpand]);

  return (
    <Flex
      column
      align="center"
      justify="center"
      className={isHidden ? "node-hidden" : "node-expand"}
      style={{
        display: isHidden ? "none" : "flex",
        position: "relative",
      }}
    >
      {!!data.showLabel && (
        <LabelMini
          className="text-truncate text-center"
          style={{
            position: "absolute",
            maxWidth: "100px",
            top: "50%",
            right: "6px" /* Position at the right edge */,
            transform: "translate(100%, -50%)",
            // Shahar said to remove the background from the labels 🤷‍♂️
            // backgroundColor: theme.black400,
            padding: "0 4px",
            borderRadius: "4px",
          }}
          title={`${data.label}`}
        >{`${data.label}`}</LabelMini>
      )}
      <Flex
        justify="center"
        align="center"
        style={{
          backgroundColor: theme.black400,
          borderRadius: "50%",
          padding: "4px",
          height: "28px",
          width: "28px",
        }}
      >
        <Icon name="globe" size={16} color={data.color || theme.primary} />
      </Flex>
    </Flex>
  );
};

type GroupLabelProps = {
  label: string;
  onClick?: (id: string) => void;
  id: string;
  withCancelButton?: boolean;
};

const GroupLabel = ({
  label,
  onClick,
  id,
  withCancelButton,
}: GroupLabelProps) => {
  const theme = useContext(ThemeContext);
  return (
    <Flex
      align="center"
      style={{
        position: "absolute",
        top: "50%",
        right: "-4px" /* Position at the right edge */,
        transform: "translate(100%, -50%)",
      }}
    >
      <Flex
        align="center"
        gap="4px"
        style={{
          backgroundColor: theme.bg3,
          borderStyle: "solid",
          borderColor: theme.black500,
          borderWidth: "1px 1px 1px 0px",
          borderRadius: "0 16px 16px 0",
          padding: "4px",
          height: "28px",
        }}
      >
        <LabelMedium
          className="text-truncate"
          style={{
            maxWidth: "200px",
          }}
          title={label}
        >
          {label}
        </LabelMedium>
        {withCancelButton && (
          <IconButton
            iconName="cancel"
            size={"very-small"}
            color={theme.redPrimary}
            onClick={!!onClick ? () => onClick(id) : () => {}}
          />
        )}
      </Flex>
    </Flex>
  );
};

type GroupBorderProps = {
  size: string;
  backgroundColor: string;
  children: React.ReactNode;
};

const GroupBorderedContainer = ({
  size,
  children,
  backgroundColor,
}: GroupBorderProps) => {
  const theme = useContext(ThemeContext);
  return (
    <Flex
      column
      padding="4px"
      style={{
        borderRadius: "50%",
        backgroundColor: theme.bg1,
        border: `1px solid ${theme.gray500}`,
      }}
    >
      <Flex
        align="center"
        justify="center"
        style={{
          position: "relative",
          border: `1px solid ${theme.black500}`,
          borderRadius: "50%",
          backgroundColor: backgroundColor,
          width: size, // Set group size
          height: size,
          transition: "all 0.3s ease-in-out",
        }}
      >
        {children}
      </Flex>
    </Flex>
  );
};

export const NodeGroup = ({ data, id }: NodeProps) => {
  const theme = useContext(ThemeContext);
  const isExpand = data.isExpand as boolean;
  const nodesCount = data.nodesCount as number;
  return (
    <GroupBorderedContainer
      size={
        data.radius ? `${isExpand ? Number(data.radius) * 2 : 48}px` : "300px"
      }
      backgroundColor={isExpand ? theme.bg3 : data.color || theme.primary}
    >
      {isExpand ? (
        <GroupLabel
          id={id}
          label={data.label as string}
          onClick={data.onClick as any}
          withCancelButton={isExpand}
        />
      ) : (
        <Flex
          align="center"
          justify="center"
          className="pointer"
          h100
          w100
          onClick={!!data.onClick ? () => (data.onClick as any)(id) : () => {}}
        >
          <GroupLabel
            id={id}
            label={data.label as string}
            onClick={data.onClick as any}
            withCancelButton={isExpand}
          />
          <KPISmall
            color={theme.bg1}
            style={{
              fontSize:
                nodesCount > 9999 ? "14px" : nodesCount > 999 ? "16px" : "",
            }}
          >
            {nodesCount}
          </KPISmall>
        </Flex>
      )}
    </GroupBorderedContainer>
  );
};
