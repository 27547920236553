import React from "react";
import {
  useApiDownloadSingleFindingAttachment,
  useApiFindingAttachments,
} from "../../../hooks/queries/findingContext";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  LabelMini,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { AttachmentTag } from "../../../components/composed/findingAttachments/AttachmentTag";
import useToastContext from "../../../hooks/toastHook";
import { blobToFileAndDownload } from "../../admin/research/AttachmentUtils";
import { Loading } from "../../../components/elements/loading/Loading";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import { SCREEN_MOBILE_WIDTH } from "../../../shared/consts";

type Props = {
  findingId?: number;
};

export const FindingAttachmentsDetails = (props: Props) => {
  const { findingId } = props;
  const addToast = useToastContext();
  const { data: attachments, isLoading } = useApiFindingAttachments(findingId);
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth < SCREEN_MOBILE_WIDTH;
  const { mutate: downloadAttachment } =
    useApiDownloadSingleFindingAttachment();
  return (
    <Flex column gap="8px">
      <LabelRegular>Attachments</LabelRegular>
      <Flex gap="8px" column={isMobile}>
        {Array.isArray(attachments) && findingId ? (
          attachments.map((a) => (
            <AttachmentTag
              label={a}
              onClickDownload={() => {
                downloadAttachment({
                  findingId: findingId,
                  attachment_name: a,
                  onSuccessCallback(data) {
                    blobToFileAndDownload(data as Blob, a);
                    console.log("Download successful");
                  },
                  onErrorCallback(error) {
                    addToast({
                      message: `Failed to download attachment - ${error}`,
                      type: "error",
                    });
                  },
                });
              }}
            />
          ))
        ) : isLoading ? (
          <Loading noTitle />
        ) : (
          <LabelMini>None.</LabelMini>
        )}
      </Flex>
    </Flex>
  );
};
