import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Box } from "../../../components/elements/box/Box";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { Icon } from "../../../components/elements/icon/Icon";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import {
  HeaderSubBold,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { getDateTime } from "../../../shared/helper";
import { Finding } from "../../../types/Finding";
import {
  getFindingInsight,
  getStatusName,
  getRiskColor,
} from "../../../shared/findingsHelper";
import { Link } from "react-router-dom";

type Props = {
  finding: Finding;
  hasUnreadComments: boolean;
};

export const FindingShortCard = (props: Props) => {
  const { finding, hasUnreadComments } = props;
  const theme = useContext(ThemeContext);

  return (
    <Box
      className="d-flex w-100"
      style={{
        gap: "12px",
        padding: "0px",
        borderRadius: "8px",
        height: "82px",
        overflowX: "clip",
      }}
    >
      <div // Vertical Status Color Line
        style={{
          width: "4px",
          height: "inherit",
          background: theme[getRiskColor(finding.overall_risk)],
        }}
      ></div>
      <div
        className="d-flex justify-content-between align-items-center w-100"
        style={{ height: "inherit", overflowX: "hidden" }}
      >
        <div className="d-flex flex-column w-50">
          <Tooltip content={finding.title} isTextTruncate>
            <HeaderSubBold className="text-truncate w-100">
              {finding.title}
            </HeaderSubBold>
          </Tooltip>
          <div
            className="d-flex  "
            style={{
              color: theme.black600,
            }}
          >
            <LabelRegular
              style={{
                paddingRight: "8px",
                borderRight: `1px solid ${theme.black600}`,
              }}
            >
              {getDateTime(finding.date_found || finding.created_at)}
            </LabelRegular>
            <LabelRegular
              style={{
                padding: "0 8px",
                borderRight: finding.is_automated
                  ? `1px solid ${theme.black600}`
                  : "",
              }}
            >
              {getFindingInsight(finding)}
            </LabelRegular>
            {finding.is_automated && (
              <LabelRegular
                style={{
                  padding: "0 8px",
                }}
              >
                <Icon name={"wasp"} color={theme.yellowPrimary} size={16} />
                <span
                  style={{
                    padding: "0 8px",
                  }}
                >
                  Automated
                </span>
              </LabelRegular>
            )}
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-end  gap-8 w-50">
          <LabelRegular className="d-flex align-items-center">
            <Icon name={finding.status} color={theme.primary} size={18} />
            <span className="ms-2">{getStatusName(finding.status)}</span>
          </LabelRegular>
          <SeparatorVertical style={{ height: "22px" }} />
          <LabelRegular className="d-flex align-items-center">
            <Icon name={"comment"} color={theme.primary} size={16} />
            <span className="ms-2 me-1">{finding.comments_count || 0}</span>
            {hasUnreadComments && (
              <span
                data-testid="unread-comments"
                style={{
                  background: theme.redPrimary,
                  width: "4px",
                  height: "4px",
                  borderRadius: "50%",
                }}
              />
            )}
          </LabelRegular>
          <Link to={`/finding-details/${finding.id}?`}>
            <IconButton iconName="chevronRight" />
          </Link>
        </div>
      </div>
    </Box>
  );
};
