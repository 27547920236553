import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useContext,
} from "react";
import { FormError } from "../../../../components/elements/FormsElements/FormError";
import { Box } from "../../../../components/elements/box/Box";
import { Icon } from "../../../../components/elements/icon/Icon";
import { InputText } from "../../../../components/elements/input/textInput/InputText";
import {
  RequiredField,
  OptionalField,
} from "../../../../components/elements/requiredField/RequiredField";
import {
  HeaderSecondary,
  LabelRegular,
} from "../../../../components/elements/typography/Typography";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { ThemeContext } from "styled-components";
import {
  FormModeState,
  onRichTextChangedHandler,
  useUpdateFindingInPlace,
} from "../../../../shared/formUtils";
import {
  AdminFindingEdit,
  Finding,
  FindingLoadingMap,
} from "../../../../types/Finding";
import { useParams } from "react-router";
import {
  useApiCreateFindingImages,
  useApiUpdateFinding,
} from "../../../../hooks/queries/findingContext";
import { RichTextEditorWASP } from "../../../../components/elements/richTextEditor/RichTextEditor";
import { uploadFindingTextAndImages } from "../ImageUtils";
import useToastContext from "../../../../hooks/toastHook";

type Props = {
  formMode: FormModeState;
  loadingStates: FindingLoadingMap;
  setLoadingStates: Dispatch<SetStateAction<FindingLoadingMap>>;
  createFindingData: AdminFindingEdit;
  setCreateFinding: Dispatch<SetStateAction<AdminFindingEdit>>;
  updateFindingData?: Finding;
  setUpdateFinding: Dispatch<SetStateAction<Finding | undefined>>;
  editableUpdateFindingData: AdminFindingEdit | null;
  setEditableUpdateFinding: Dispatch<SetStateAction<AdminFindingEdit | null>>;
  setCorrectTitle: Dispatch<SetStateAction<boolean>>;
  clearTrigger: MutableRefObject<boolean>;
  valueTrigger: MutableRefObject<boolean>;
  isCorrectTitle: boolean;
  isSubmitClicked: boolean;
};

export const BasicInformation = (props: Props) => {
  const {
    formMode,
    loadingStates,
    setLoadingStates,
    createFindingData,
    setCreateFinding,
    updateFindingData,
    setUpdateFinding,
    editableUpdateFindingData,
    setEditableUpdateFinding,
    clearTrigger,
    valueTrigger,
    isCorrectTitle,
    setCorrectTitle,
    isSubmitClicked,
  } = props;
  const theme = useContext(ThemeContext);
  const addToast = useToastContext();
  const { id: updateFindingId } = useParams();
  const { mutate: createImage } = useApiCreateFindingImages();
  const { mutate: updateFinding } = useApiUpdateFinding({
    "admin-mode": true,
  });
  const [updateInPlace, isLoading] = useUpdateFindingInPlace(
    parseInt(updateFindingId ? updateFindingId : "0"),
    setUpdateFinding,
    updateFindingData,
    setLoadingStates
  );

  return (
    <Box style={{ width: "50%" }}>
      <Flex column gap="32px">
        <Flex gap="8px">
          <Icon name="info" size={30} color={theme.primary} />{" "}
          <HeaderSecondary>Basic Information</HeaderSecondary>
        </Flex>
        <Flex column gap="8px" data-testid="title-field">
          <Flex justify="between">
            <Flex>
              <LabelRegular>Title</LabelRegular>
              <RequiredField />
            </Flex>
            {isLoading && loadingStates.title && (
              <Icon name="spinner" size={20} />
            )}
          </Flex>
          <InputText
            dataTestId="title-input"
            saveChangesMode={formMode === FormModeState.Update}
            onEnterPressed={() => {
              updateInPlace({ title: editableUpdateFindingData?.title });
              setLoadingStates((prev) => ({ ...prev, title: true }));
            }}
            onSave={() => {
              updateInPlace({ title: editableUpdateFindingData?.title });
              setLoadingStates((prev) => ({ ...prev, title: true }));
            }}
            onCancel={() => {
              setEditableUpdateFinding((prev) => ({
                ...prev,
                title: updateFindingData?.title,
              }));
            }}
            width="100%"
            placeholder="XSS vulnerability..."
            value={
              formMode === FormModeState.Update
                ? editableUpdateFindingData?.title
                  ? editableUpdateFindingData.title
                  : ""
                : createFindingData.title
                  ? createFindingData.title
                  : ""
            }
            onChange={(e) => {
              if (formMode === FormModeState.Update) {
                setEditableUpdateFinding((prev) => ({
                  ...prev,
                  title: e.target.value,
                }));
              }
              if (formMode === FormModeState.Create) {
                setCreateFinding((prev) => ({
                  ...prev,
                  title: e.target.value,
                }));
              }
              if (e.target.value === "") {
                setCorrectTitle(false);
              } else {
                setCorrectTitle(true);
              }
            }}
          />
          <FormError
            errorMessage={
              !isCorrectTitle && isSubmitClicked
                ? "Please fill in the Title field"
                : ""
            }
          />
        </Flex>
        <Flex column gap="8px">
          <Flex justify="between">
            <Flex>
              <LabelRegular>One-liner summary</LabelRegular>
              <OptionalField />
            </Flex>
            {isLoading && loadingStates.summary && (
              <Icon name="spinner" size={20} />
            )}
          </Flex>
          <InputText
            saveChangesMode={formMode === FormModeState.Update}
            onEnterPressed={() => {
              updateInPlace({ summary: editableUpdateFindingData?.summary });
              setLoadingStates((prev) => ({ ...prev, summary: true }));
            }}
            onSave={() => {
              updateInPlace({ summary: editableUpdateFindingData?.summary });
              setLoadingStates((prev) => ({ ...prev, summary: true }));
            }}
            onCancel={() => {
              setEditableUpdateFinding((prev) => ({
                ...prev,
                summary: updateFindingData?.summary,
              }));
            }}
            width="100%"
            placeholder="Write short summary..."
            value={
              formMode === FormModeState.Update
                ? editableUpdateFindingData?.summary
                  ? editableUpdateFindingData.summary
                  : ""
                : createFindingData.summary
                  ? createFindingData.summary
                  : ""
            }
            onChange={(e) => {
              if (formMode === FormModeState.Update) {
                setEditableUpdateFinding((prev) => ({
                  ...prev,
                  summary: e.target.value,
                }));
              }
              if (formMode === FormModeState.Create) {
                setCreateFinding((prev) => ({
                  ...prev,
                  summary: e.target.value,
                }));
              }
            }}
          />
        </Flex>
        <Flex column>
          <Flex justify="between">
            <Flex>
              <LabelRegular>Description</LabelRegular>
              <OptionalField />
            </Flex>
            {loadingStates.description && <Icon name="spinner" size={20} />}
          </Flex>
          <RichTextEditorWASP
            saveChangesMode={formMode === FormModeState.Update}
            placeholderText="Provide a detailed description of the vulnerability discovered..."
            value={
              formMode === FormModeState.Update
                ? editableUpdateFindingData?.description_wasp
                  ? editableUpdateFindingData.description_wasp
                  : null
                : createFindingData.description_wasp
                  ? createFindingData.description_wasp
                  : null
            }
            valueTrigger={valueTrigger}
            clearTrigger={clearTrigger}
            onChange={(html: string, markdown: string) => {
              onRichTextChangedHandler(
                html,
                "description_wasp",
                markdown,
                "description",
                formMode === FormModeState.Create
                  ? setCreateFinding
                  : setEditableUpdateFinding
              );
            }}
            theme={theme}
            onSave={async () => {
              setLoadingStates((prev) => ({ ...prev, description: true }));
              uploadFindingTextAndImages(
                parseInt(updateFindingId ? updateFindingId : "0"),
                editableUpdateFindingData?.description_wasp || "",
                editableUpdateFindingData?.attack_details_wasp || "",
                editableUpdateFindingData?.mitigation_wasp || "",
                editableUpdateFindingData?.description || "",
                editableUpdateFindingData?.attack_details || "",
                editableUpdateFindingData?.mitigation || "",
                editableUpdateFindingData?.description_html || "",
                editableUpdateFindingData?.attack_details_html || "",
                editableUpdateFindingData?.mitigation_html || "",
                createImage,
                updateFinding,
                undefined,
                addToast,
                () => {
                  setLoadingStates((prev) => ({ ...prev, description: false }));
                }
              );
            }}
            onCancel={() => {
              setEditableUpdateFinding((prev) => ({
                ...prev,
                description_wasp: updateFindingData?.description_wasp,
                description: updateFindingData?.description,
              }));
            }}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
