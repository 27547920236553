import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  useApiAsset,
  useApiAssetsWithViewPaging,
} from "../../../hooks/queries/assetsContext";
import { AssetsViewProps } from "../../../types/AssetsView";
import { Box } from "../../../components/elements/box/Box";
import {
  HeaderMain,
  LabelBold,
  LabelMini,
} from "../../../components/elements/typography/Typography";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import { ThemeContext } from "styled-components";
import { AssetGradeBadge } from "../assetsTable/AssetGradeBadge";
import { useInfiniteScroll } from "../../../hooks/utilsHooks";
import { Loading } from "../../../components/elements/loading/Loading";
import AssetPaneMobile from "./AssetPaneMobile";
import { Asset } from "../../../types/Asset";
import { useSearchParams } from "react-router-dom";

type Props = {
  assetsView: AssetsViewProps;
};

const AssetsMobile = (props: Props) => {
  const { assetsView } = props;
  const theme = useContext(ThemeContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const assetId = searchParams.get("assetId");
  const { data: singleAsset } = useApiAsset(parseInt(`${assetId}`));

  const [selectedAsset, setSelectedAsset] = useState<Asset | undefined>();

  const { data: products } = useApiProducts();

  useEffect(() => {
    if (singleAsset) setSelectedAsset(singleAsset);
  }, [singleAsset]);

  const {
    data: assets,
    hasNextPage: hasAssetsNextPage,
    fetchNextPage: fetchAssetsNextPage,
    isFetching,
    isFetchingNextPage,
  } = useApiAssetsWithViewPaging(assetsView.filters, "created_at", false);
  const isFetchingAssets = isFetching || isFetchingNextPage;

  const assetsMapped = useMemo(
    () =>
      assets?.pages
        .map((page) => page.results || [])
        .flat()
        .map((asset) => ({
          ...asset,
          isDead:
            asset.type === "domain" && asset.properties?.status_code === 0,
        })) || [],
    [assets]
  );

  const observerElemForFetchPage = useRef(null);

  useInfiniteScroll(
    observerElemForFetchPage,
    !!hasAssetsNextPage,
    fetchAssetsNextPage
  );

  return (
    <>
      <Flex column w100 gap="16px">
        <HeaderMain data-testid="assets-title">Assets</HeaderMain>
        <Flex column gap="12px">
          {assetsMapped?.map((asset) => (
            <Box
              onClick={() => {
                setSelectedAsset(asset);
                searchParams.set("assetId", `${asset.id}`);
                setSearchParams(searchParams);
              }}
            >
              <Flex justify="between">
                <Flex column>
                  <LabelBold>{asset.name}</LabelBold>
                  <Flex gap="8px">
                    <LabelMini>
                      {products?.find((p) => p.id === asset.product)?.name}
                    </LabelMini>
                    <LabelMini color={theme.primary}>
                      {asset.environment}
                    </LabelMini>
                  </Flex>
                  <LabelMini>{asset.type}</LabelMini>
                </Flex>
                <Flex column align="center" gap="4px">
                  <LabelMini style={{ textAlign: "center", width: "50px" }}>
                    Security Grade
                  </LabelMini>
                  <AssetGradeBadge
                    riskScore={asset.risk_score || 0}
                    color={asset.isDead ? theme.black600 : undefined}
                    backgroundColor={asset.isDead ? theme.black400 : undefined}
                  />
                </Flex>
              </Flex>
            </Box>
          ))}

          <div className="d-flex" ref={observerElemForFetchPage} />
          {isFetchingAssets && <Loading />}
        </Flex>
      </Flex>
      {selectedAsset && assetId && (
        <AssetPaneMobile
          asset={selectedAsset}
          onClose={() => {
            setSelectedAsset(undefined);
            searchParams.delete("assetId");
            setSearchParams(searchParams);
          }}
        />
      )}
    </>
  );
};

export default AssetsMobile;
