import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Badge } from "../../../../components/elements/badge/Badge";
import { Icon } from "../../../../components/elements/icon/Icon";
import { FilterContext } from "./FindingsFilterLine";

const FilterBadge = ({
  filterKey,
  label,
  value,
  removeFilter,
}: {
  filterKey: string;
  label?: string;
  value?: string;
  removeFilter?: (filterContext: FilterContext) => void;
}) => {
  const theme = useContext(ThemeContext);

  const filterKeyDisplay = filterKey.replace(/_/g, " ").replace("is ", "");

  return label ? (
    <Badge style={{ alignItems: "center", background: theme.blue100 }}>
      {filterKey !== "is_automated" && (
        <span style={{ textTransform: "capitalize" }}>
          {filterKeyDisplay}
          {": "}
        </span>
      )}

      <span style={{ color: theme.primary, textTransform: "capitalize" }}>
        {`${label}`.replace("_", " ")}
      </span>
      {!!removeFilter && (
        <span
          className="pointer"
          data-testid={`${label}-cancel`}
          onClick={() => removeFilter({ filterKey, value })}
        >
          <Icon name="cancel" color={theme.primary} size={16} />
        </span>
      )}
    </Badge>
  ) : null;
};
export default FilterBadge;
