import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useContext,
  useEffect,
} from "react";
import { TagsLine } from "../../../../components/composed/tagsLine/TagsLine";
import { FormError } from "../../../../components/elements/FormsElements/FormError";
import { Box } from "../../../../components/elements/box/Box";
import { Dropdown } from "../../../../components/elements/dropdowns/Dropdown";
import { Icon } from "../../../../components/elements/icon/Icon";
import {
  OptionalField,
  RequiredField,
} from "../../../../components/elements/requiredField/RequiredField";
import { Switch } from "../../../../components/elements/switch/Switch";
import {
  HeaderSecondary,
  LabelRegular,
} from "../../../../components/elements/typography/Typography";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { IMPACTS } from "../../../../shared/consts";
import {
  onRichTextChangedHandler,
  mapToOptions,
  getOptionFromKeyValuePairs,
  arraysToOptions,
  useUpdateFindingInPlace,
  FormModeState,
} from "../../../../shared/formUtils";
import { ThemeContext } from "styled-components";
import { SingleValue } from "react-select";
import { Option } from "../../../../components/elements/dropdowns/Dropdown";
import { AssetsDropdown } from "../../../projects/projectPane/AssetsDropdown";
import { TagBadge } from "../../../../components/composed/tagsLine/TagBadge";
import { useApiAssetsGetById } from "../../../../hooks/queries/assetsContext";
import { useApiProducts } from "../../../../hooks/queries/productsContext";
import { Customer } from "../../../../types/Customer";
import {
  AdminFindingEdit,
  Finding,
  FindingLoadingMap,
} from "../../../../types/Finding";
import {
  useApiCreateFindingImages,
  useApiUpdateFinding,
} from "../../../../hooks/queries/findingContext";
import { useParams } from "react-router";
import { RichTextEditorWASP } from "../../../../components/elements/richTextEditor/RichTextEditor";
import { uploadFindingTextAndImages } from "../ImageUtils";
import useToastContext from "../../../../hooks/toastHook";
import { useApiMe } from "../../../../hooks/queries/meContext";
import { useIsSuperuser } from "../../../../hooks/useIsSuperuser";

type Props = {
  formMode: FormModeState;
  loadingStates: FindingLoadingMap;
  setLoadingStates: Dispatch<SetStateAction<FindingLoadingMap>>;
  createFindingData: AdminFindingEdit;
  setCreateFinding: Dispatch<SetStateAction<AdminFindingEdit>>;
  updateFindingData?: Finding;
  setUpdateFinding: Dispatch<SetStateAction<Finding | undefined>>;
  editableUpdateFindingData: AdminFindingEdit | null;
  setEditableUpdateFinding: Dispatch<SetStateAction<AdminFindingEdit | null>>;
  clearTrigger: MutableRefObject<boolean>;
  valueTrigger: MutableRefObject<boolean>;
  isCorrectAssets: boolean;
  isCorrectImpact: boolean;
  customers: Customer[] | undefined;
  setCorrectImpact: Dispatch<SetStateAction<boolean>>;
  setCorrectAssets: Dispatch<SetStateAction<boolean>>;
  isSubmitClicked: boolean;
};

export const AttackDetails = (props: Props) => {
  const {
    formMode,
    loadingStates,
    setLoadingStates,
    createFindingData,
    setCreateFinding,
    updateFindingData,
    setUpdateFinding,
    editableUpdateFindingData,
    setEditableUpdateFinding,
    clearTrigger,
    valueTrigger,
    isCorrectAssets,
    isCorrectImpact,
    customers,
    setCorrectImpact,
    setCorrectAssets,
    isSubmitClicked,
  } = props;

  const isSuperuser = useIsSuperuser();
  const theme = useContext(ThemeContext);
  const { data: me } = useApiMe();
  const { id: updateFindingId } = useParams();
  const addToast = useToastContext();
  const { mutate: updateFinding } = useApiUpdateFinding({ "admin-mode": true });
  const { mutate: createImage } = useApiCreateFindingImages();
  const [updateInPlace, isLoading] = useUpdateFindingInPlace(
    parseInt(updateFindingId ? updateFindingId : "0"),
    setUpdateFinding,
    updateFindingData,
    setLoadingStates
  );

  // HANDLE ASSETS
  const { data: assets, refetch: refetchAssets } = useApiAssetsGetById(
    formMode === FormModeState.Update
      ? editableUpdateFindingData?.affected_assets || []
      : createFindingData.affected_assets || [],
    { "admin-mode": true }
  );

  const { data: products } = useApiProducts({
    id:
      formMode === FormModeState.Update
        ? editableUpdateFindingData?.products
        : createFindingData.products,
    "admin-mode": true,
  });

  // If user selected assets, refetch assets query for products search
  useEffect(() => {
    refetchAssets(); // eslint-disable-next-line
  }, [
    editableUpdateFindingData?.affected_assets,
    createFindingData.affected_assets,
  ]);

  useEffect(() => {
    // If this is not the initial render
    if (
      (formMode === FormModeState.Update &&
        editableUpdateFindingData?.affected_assets !== undefined) ||
      (formMode === FormModeState.Create &&
        createFindingData.affected_assets !== undefined)
    ) {
      onProductsChangedHandler(); // eslint-disable-next-line
    }
    // eslint-disable-next-line
  }, [assets]);

  const onProductsChangedHandler = () => {
    var products: number[] = [];
    if (
      formMode === FormModeState.Update &&
      editableUpdateFindingData?.affected_assets !==
        updateFindingData?.affected_assets &&
      editableUpdateFindingData?.affected_assets !== undefined &&
      assets
    ) {
      products = [...new Set(assets.map((asset) => asset.product))]; // products without duplicates
      if (editableUpdateFindingData.affected_assets.length === 0) {
        setEditableUpdateFinding((prev) => ({ ...prev, products: [] }));
        updateInPlace({ products: [] });
      } else {
        setEditableUpdateFinding((prev) => ({ ...prev, products: products }));
        updateInPlace({ products: products });
      }
      setLoadingStates((prev) => ({ ...prev, products: true }));
    }

    if (
      formMode === FormModeState.Create &&
      createFindingData?.affected_assets !== undefined &&
      assets
    ) {
      products = [...new Set(assets.map((asset) => asset.product))]; // products without duplicates
      if (createFindingData.affected_assets.length === 0)
        setCreateFinding((prev) => ({ ...prev, products: [] }));
      else setCreateFinding((prev) => ({ ...prev, products: products }));
    }
  };

  const onAssetsAddedHandler = (assetOption: SingleValue<Option>) => {
    if (!!assetOption) {
      if (formMode === FormModeState.Update) {
        setEditableUpdateFinding((prev) => ({
          ...prev,
          affected_assets: prev?.affected_assets
            ? [...prev.affected_assets, Number(assetOption.value)]
            : [Number(assetOption.value)],
          affected_assets_displayed: prev?.affected_assets_displayed
            ? [...prev?.affected_assets_displayed, assetOption.label]
            : [assetOption.label],
        }));

        updateInPlace({
          affected_assets: editableUpdateFindingData?.affected_assets
            ? [
                ...editableUpdateFindingData?.affected_assets,
                Number(assetOption.value),
              ]
            : [Number(assetOption.value)],
          affected_assets_displayed:
            editableUpdateFindingData?.affected_assets_displayed
              ? [
                  ...editableUpdateFindingData?.affected_assets_displayed,
                  assetOption.label,
                ]
              : [assetOption.label],
        });
        setLoadingStates((prev) => ({ ...prev, affected_assets: true }));
      }
      if (formMode === FormModeState.Create) {
        setCreateFinding((prev) => ({
          ...prev,
          affected_assets: prev?.affected_assets
            ? [...prev.affected_assets, Number(assetOption.value)]
            : [Number(assetOption.value)],
          affected_assets_displayed: prev?.affected_assets_displayed
            ? [...prev?.affected_assets_displayed, assetOption.label]
            : [assetOption.label],
        }));
      }
      setCorrectAssets(true);
    }
  };

  const onAssetsDeletedHandler = (assetOption: SingleValue<Option>) => {
    if (!!assetOption) {
      if (
        formMode === FormModeState.Update &&
        editableUpdateFindingData?.affected_assets !== undefined &&
        editableUpdateFindingData?.affected_assets.length === 1
      ) {
        setCorrectAssets(false);
      }

      if (
        formMode === FormModeState.Create &&
        createFindingData?.affected_assets !== undefined &&
        createFindingData?.affected_assets.length === 1
      ) {
        setCorrectAssets(false);
      }

      if (formMode === FormModeState.Update) {
        setEditableUpdateFinding((prev) => ({
          ...prev,
          affected_assets: prev?.affected_assets
            ? prev.affected_assets.filter(
                (asset) => asset !== Number(assetOption.value)
              )
            : [],
          affected_assets_displayed: prev?.affected_assets_displayed
            ? prev.affected_assets_displayed.filter(
                (label) => label !== assetOption.label
              )
            : [],
        }));

        updateInPlace({
          affected_assets: editableUpdateFindingData?.affected_assets
            ? editableUpdateFindingData.affected_assets.filter(
                (asset) => asset !== Number(assetOption.value)
              )
            : [],
          affected_assets_displayed:
            editableUpdateFindingData?.affected_assets_displayed
              ? editableUpdateFindingData.affected_assets_displayed.filter(
                  (label) => label !== assetOption.label
                )
              : [],
        });
        setLoadingStates((prev) => ({ ...prev, affected_assets: true }));
      }
      if (formMode === FormModeState.Create) {
        setCreateFinding((prev) => ({
          ...prev,
          affected_assets: prev.affected_assets
            ? prev.affected_assets.filter(
                (asset) => asset !== Number(assetOption.value)
              )
            : [],
          affected_assets_displayed: prev.affected_assets_displayed
            ? prev.affected_assets_displayed.filter(
                (label) => label !== assetOption.label
              )
            : [],
        }));
      }
    }
  };

  return (
    <Box style={{ width: "100%" }}>
      <Flex column gap="32px">
        <Flex gap="8px">
          <Icon name="securityStatus" size={30} color={theme.primary} />{" "}
          <HeaderSecondary>Attack Details</HeaderSecondary>
        </Flex>
        <Flex w100 gap="32px">
          <Flex column style={{ width: "50%" }} gap="16px">
            <Flex gap="16px">
              {(!me?.customer.is_multi_tenant || isSuperuser) && (
                <Flex justify="between">
                  <Flex>
                    <LabelRegular>Found by ASM?</LabelRegular>
                    <RequiredField />
                  </Flex>
                  <Switch
                    checked={
                      formMode === FormModeState.Update
                        ? editableUpdateFindingData?.is_automated
                          ? editableUpdateFindingData.is_automated
                          : false
                        : createFindingData?.is_automated
                          ? createFindingData.is_automated
                          : false
                    }
                    onChange={(checked) => {
                      if (formMode === FormModeState.Update) {
                        setEditableUpdateFinding((prev) => ({
                          ...prev,
                          is_automated: checked,
                          scanner_name: "wasp",
                        }));
                        updateInPlace({ is_automated: checked });
                        setLoadingStates((prev) => ({
                          ...prev,
                          is_automated: true,
                        }));
                      }
                      if (formMode === FormModeState.Create) {
                        setCreateFinding((prev) => ({
                          ...prev,
                          is_automated: checked,
                          scanner_name: "wasp",
                        }));
                      }
                    }}
                  />
                </Flex>
              )}
              {isLoading && loadingStates.is_automated && (
                <Icon name="spinner" size={20} />
              )}
            </Flex>
            <Flex column>
              <Flex justify="between">
                <Flex>
                  <LabelRegular>Attack Details</LabelRegular>
                  <OptionalField />
                </Flex>
                {loadingStates.attack_details && (
                  <Icon name="spinner" size={20} />
                )}
              </Flex>
              <RichTextEditorWASP
                saveChangesMode={formMode === FormModeState.Update}
                onSave={async () => {
                  setLoadingStates((prev) => ({
                    ...prev,
                    attack_details: true,
                  }));
                  uploadFindingTextAndImages(
                    parseInt(updateFindingId ? updateFindingId : "0"),
                    editableUpdateFindingData?.description_wasp || "",
                    editableUpdateFindingData?.attack_details_wasp || "",
                    editableUpdateFindingData?.mitigation_wasp || "",
                    editableUpdateFindingData?.description || "",
                    editableUpdateFindingData?.attack_details || "",
                    editableUpdateFindingData?.mitigation || "",
                    editableUpdateFindingData?.description_html || "",
                    editableUpdateFindingData?.attack_details_html || "",
                    editableUpdateFindingData?.mitigation_html || "",
                    createImage,
                    updateFinding,
                    undefined,
                    addToast,
                    () => {
                      setLoadingStates((prev) => ({
                        ...prev,
                        attack_details: false,
                      }));
                    }
                  );
                }}
                onCancel={() => {
                  setEditableUpdateFinding((prev) => ({
                    ...prev,
                    attack_details: updateFindingData?.attack_details,
                    attack_details_wasp: updateFindingData?.attack_details_wasp,
                  }));
                }}
                placeholderText="Outline the attack details..."
                value={
                  formMode === FormModeState.Update
                    ? editableUpdateFindingData?.attack_details_wasp
                      ? editableUpdateFindingData.attack_details_wasp
                      : null
                    : createFindingData?.attack_details_wasp
                      ? createFindingData.attack_details_wasp
                      : null
                }
                valueTrigger={valueTrigger}
                clearTrigger={clearTrigger}
                onChange={(html: string, markdown: string) => {
                  onRichTextChangedHandler(
                    html,
                    "attack_details_wasp",
                    markdown,
                    "attack_details",
                    formMode === FormModeState.Update
                      ? setEditableUpdateFinding
                      : setCreateFinding
                  );
                }}
                theme={theme}
              />
            </Flex>
          </Flex>
          <Flex column style={{ width: "50%" }} gap="32px">
            <Flex column gap="8px" w100>
              <Flex justify="between">
                <Flex>
                  <LabelRegular>Impact</LabelRegular>
                  <RequiredField />
                </Flex>
                {isLoading && loadingStates.impact && (
                  <Icon name="spinner" size={20} />
                )}
              </Flex>
              <Dropdown
                searchable
                variant="border"
                size="medium"
                value={
                  formMode === FormModeState.Update
                    ? editableUpdateFindingData?.impact !== null &&
                      editableUpdateFindingData?.impact !== undefined
                      ? getOptionFromKeyValuePairs(
                          IMPACTS,
                          editableUpdateFindingData.impact
                        )
                      : null
                    : createFindingData?.impact !== null &&
                        createFindingData?.impact !== undefined
                      ? getOptionFromKeyValuePairs(
                          IMPACTS,
                          createFindingData.impact
                        )
                      : null
                }
                options={mapToOptions(IMPACTS)}
                onChange={(selectedOption: any) => {
                  if (formMode === FormModeState.Update) {
                    setEditableUpdateFinding((prev) => ({
                      ...prev,
                      impact: Number(selectedOption.value),
                    }));
                    updateInPlace({
                      impact: Number(selectedOption.value),
                    });
                    setLoadingStates((prev) => ({ ...prev, impact: true }));
                  }
                  if (formMode === FormModeState.Create) {
                    setCreateFinding((prev) => ({
                      ...prev,
                      impact: Number(selectedOption.value),
                    }));
                  }
                  setCorrectImpact(true);
                }}
              />
              <FormError
                errorMessage={
                  !isCorrectImpact && isSubmitClicked
                    ? "Please fill in the Impact field"
                    : ""
                }
              />
            </Flex>
            <Flex column gap="8px">
              <Flex justify="between">
                <Flex>
                  <LabelRegular>Affected Assets</LabelRegular>
                  <RequiredField />
                </Flex>
                {isLoading && loadingStates.affected_assets && (
                  <Icon name="spinner" size={20} />
                )}
              </Flex>
              <AssetsDropdown
                customer={
                  formMode === FormModeState.Update
                    ? customers?.filter(
                        (c) => c.id === editableUpdateFindingData?.customer
                      )[0]
                    : customers?.filter(
                        (c) => c.id === createFindingData?.customer
                      )[0]
                }
                disabled={
                  formMode === FormModeState.Update
                    ? !editableUpdateFindingData?.customer
                    : !createFindingData.customer
                }
                size="medium"
                selected={
                  formMode === FormModeState.Update
                    ? editableUpdateFindingData?.affected_assets
                      ? editableUpdateFindingData.affected_assets
                      : []
                    : createFindingData?.affected_assets
                      ? createFindingData.affected_assets
                      : []
                }
                onChange={(op) => {
                  onAssetsAddedHandler(op);
                }}
              />
              <FormError
                errorMessage={
                  !isCorrectAssets && isSubmitClicked
                    ? "Please fill in the Assets field"
                    : ""
                }
              />

              {formMode === FormModeState.Update
                ? editableUpdateFindingData?.affected_assets &&
                  editableUpdateFindingData.affected_assets_displayed &&
                  arraysToOptions(
                    editableUpdateFindingData.affected_assets_displayed,
                    editableUpdateFindingData.affected_assets
                  )?.map((asset) => (
                    <TagBadge
                      key={asset.value}
                      option={asset}
                      onDeleteOption={onAssetsDeletedHandler}
                      isEditable={true}
                    />
                  ))
                : createFindingData?.affected_assets &&
                  createFindingData.affected_assets_displayed &&
                  arraysToOptions(
                    createFindingData.affected_assets_displayed,
                    createFindingData.affected_assets
                  )?.map((asset) => (
                    <TagBadge
                      key={asset.value}
                      option={asset}
                      onDeleteOption={onAssetsDeletedHandler}
                      isEditable={true}
                    />
                  ))}
            </Flex>
            <Flex column gap="8px">
              <Flex justify="between">
                <LabelRegular>Affected Products</LabelRegular>
                {isLoading && loadingStates.products && (
                  <Icon name="spinner" size={20} />
                )}
              </Flex>
              <TagsLine
                isEditable={false}
                selectedTags={
                  formMode === FormModeState.Update
                    ? products
                      ? products
                          .filter((product) =>
                            editableUpdateFindingData?.products
                              ? editableUpdateFindingData.products.includes(
                                  product.id
                                )
                              : false
                          )
                          .map((product) => product.name)
                      : []
                    : products
                      ? products
                          .filter((product) =>
                            createFindingData?.products
                              ? createFindingData.products.includes(product.id)
                              : false
                          )
                          .map((product) => product.name)
                      : []
                }
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
