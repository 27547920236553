import { useContext, useEffect, useRef, useState } from "react";
import { Box } from "../../../components/elements/box/Box";
import { FindingComment } from "../../../types/FindingComment";
import {
  BodyRegular,
  LabelMini,
} from "../../../components/elements/typography/Typography";
import { CardHeader } from "./CardHeader";
import { JiraHtmlImageFixer } from "../../../shared/parser";
import { ThemeContext } from "styled-components";
import { AssetComment } from "../../../types/AssetComment";
import { useApiMe } from "../../../hooks/queries/meContext";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import { Flex } from "../../../components/layouts/flex/Flex";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { Finding } from "../../../types/Finding";
import { ShortUser } from "../../../types/Me";
import { RichTextEditorWASP } from "../../../components/elements/richTextEditor/RichTextEditor";
import { LinkButton } from "../../../components/elements/button/link/LinkButton";
import {
  useApiDeleteFindingComment,
  useApiUpdateFindingComment,
} from "../../../hooks/queries/findingsCommentsContext";
import useToastContext from "../../../hooks/toastHook";
import {
  useApiDeleteAssetComment,
  useApiUpdateAssetComment,
} from "../../../hooks/queries/assetsCommentsContext";
import { DeleteModal } from "../../../components/composed/deleteModal/DeleteModal";
import moment from "moment";

type Props = {
  comment: FindingComment | AssetComment;
  dataTestId?: string;
  finding?: Finding;
};

export function CommentCard({ comment, dataTestId, finding }: Props) {
  const theme = useContext(ThemeContext);
  const initialValueTriggerRTE = useRef(true);
  const addToast = useToastContext();

  const { data: me } = useApiMe();

  const { mutate: updateFindingComment, isLoading: isUpdatingFindingComment } =
    useApiUpdateFindingComment();
  const {
    mutate: deleteFindingComment,
    isLoading: isDeletingFindingComment,
    isSuccess: isSuccessDeleteFindingComment,
  } = useApiDeleteFindingComment();

  const { mutate: updateAssetComment, isLoading: isUpdatingAssetComment } =
    useApiUpdateAssetComment();
  const {
    mutate: deleteAssetComment,
    isLoading: isDeletingAssetComment,
    isSuccess: isSuccessDeleteAssetComment,
  } = useApiDeleteAssetComment();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRichText, setShowRichText] = useState(false);
  const [commentMessage, setCommentMessage] = useState(""); // COMMENT HTML
  const [commentMd, setCommentMd] = useState(""); // COMMENT MARKDOWN

  const opAssignee: ShortUser = {
    name: finding?.op_jira_assignee?.display_name || "",
    email: finding?.op_jira_assignee?.email || "",
    avatar_url: finding?.op_jira_assignee?.avatar_url || "",
  };

  useEffect(() => {
    // Set code-blocks theme colors
    if (!comment) return;
    const codeblocks = document.querySelectorAll("pre");
    codeblocks.forEach((pre) => {
      pre.style.filter =
        theme.name === "dark" ? "invert(1) hue-rotate(180deg)" : "none";
    });
  }, [comment, theme]);

  const isEdited = comment.created_at !== comment.updated_at;
  const createdAt =
    "jira_updated" in comment
      ? comment.jira_updated || comment.created_at
      : comment.created_at;

  const updateCommentHtmlStyle = (text: string) =>
    // Set mention style - bold blue
    text.replace(
      `data-lexical-mention="true"`,
      `data-lexical-mention="true" style="color:${theme.primary}; font-weight:600"`
    );

  const onErrorCallback = (err: Error) =>
    addToast({ type: "error", message: err.message });

  const onSuccessCallback = () => setShowRichText(false);

  const updateComment = () => {
    if ("finding" in comment)
      updateFindingComment({
        commentData: {
          findingCommentData: {
            comment_html: commentMessage,
            comment_markdown: commentMd,
          },
          findingCommentId: comment.id,
        },
        onErrorCallback,
        onSuccessCallback,
      });
    else
      updateAssetComment({
        commentData: {
          assetCommentData: {
            comment_html: commentMessage,
            comment_markdown: commentMd,
          },
          assetCommentId: comment.id,
        },
        onErrorCallback,
        onSuccessCallback,
      });
  };

  const deleteComment = () => {
    if ("finding" in comment)
      deleteFindingComment({
        commentId: comment.id,
        onErrorCallback,
        onSuccessCallback: () =>
          addToast({
            type: "success",
            message: "Comment deleted successfully!",
          }),
      });
    else
      deleteAssetComment({
        commentId: comment.id,
        onErrorCallback,
        onSuccessCallback: () =>
          addToast({
            type: "success",
            message: "Comment deleted successfully!",
          }),
      });
  };

  return showRichText ? (
    <Flex column>
      <RichTextEditorWASP
        allowMentions
        optionalMentionUsers={[opAssignee]}
        required={true}
        label=""
        value={comment.html}
        valueTrigger={initialValueTriggerRTE}
        onChange={(html: string, markdown: string) => {
          setCommentMessage(html);
          setCommentMd(markdown);
        }}
        placeholderText={"Enter your comment..."}
        theme={theme}
      />
      <Flex justify="end">
        <LinkButton
          label="Cancel"
          iconName="cancel"
          onClick={() => setShowRichText(false)}
          dataTestId="finding-comment-cancel"
        />
        <LinkButton
          label="Save"
          onClick={updateComment}
          dataTestId="finding-comment-send"
          disabled={!commentMd || commentMd === comment.body}
          inProgress={isUpdatingAssetComment || isUpdatingFindingComment}
          iconName="send"
        />
      </Flex>
    </Flex>
  ) : (
    <>
      <Box
        data-testid={dataTestId || "comment-card"}
        style={{ paddingBottom: 0 }}
      >
        <Flex column w100 gap="16px">
          <Flex column gap="16px" w100>
            <CardHeader
              userName={comment.author.display_name}
              avatarUrl={comment.author.avatar_url}
              createdAt={createdAt}
            />
            <Flex flexWrap style={{ overflowX: "scroll" }}>
              {"finding" in comment ? (
                <BodyRegular
                  dangerouslySetInnerHTML={{
                    __html: updateCommentHtmlStyle(
                      comment.html
                        ? JiraHtmlImageFixer(comment.html, comment.finding || 0)
                        : comment.body
                    ),
                  }}
                />
              ) : (
                <BodyRegular
                  dangerouslySetInnerHTML={{
                    __html: updateCommentHtmlStyle(
                      comment.html || comment.body
                    ),
                  }}
                />
              )}
            </Flex>
          </Flex>
          <Flex w100 align="center">
            {me?.email === comment.author.email && (
              <Flex w100 align="center">
                <TextButton
                  label="Edit"
                  onClick={() => {
                    setCommentMd(comment.body);
                    setCommentMessage(comment.html);
                    setShowRichText(true);
                    initialValueTriggerRTE.current = true;
                  }}
                />
                <SeparatorVertical height="16px" />
                <TextButton
                  label="Delete"
                  onClick={() => setShowDeleteModal(true)}
                />
              </Flex>
            )}
            {isEdited && (
              <Flex w100 align="center" justify="end">
                <LabelMini
                  style={{
                    color: theme.textSub,
                    width: "30%",
                  }}
                  className="text-truncate"
                  title={`Edited ${moment(comment.updated_at).fromNow()}`}
                >
                  Edited {moment(comment.updated_at).fromNow()}
                </LabelMini>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Box>
      {showDeleteModal && (
        <DeleteModal
          itemName="comment"
          itemType="comment"
          onClose={() => setShowDeleteModal(false)}
          onConfirm={deleteComment}
          body=""
          isLoading={isDeletingAssetComment || isDeletingFindingComment}
          isSuccess={
            isSuccessDeleteAssetComment || isSuccessDeleteFindingComment
          }
        />
      )}
    </>
  );
}
