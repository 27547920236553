import { Dispatch, SetStateAction, useContext } from "react";
import { FormError } from "../../../components/elements/FormsElements/FormError";
import { TextArea } from "../../../components/elements/input/textArea/TextArea";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import { LabelRegular } from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { ThemeContext } from "styled-components";
import { ProductFormErrors, ProductFormValues } from "./AddEditProductForm";
import {
  OptionalField,
  RequiredField,
} from "../../../components/elements/requiredField/RequiredField";

type Props = {
  idx?: number;
  isFormDisabled?: boolean;
  formValues: ProductFormValues;
  formErrors: ProductFormErrors;
  setFormValues: Dispatch<SetStateAction<ProductFormValues>>;
  setFormErrors: Dispatch<SetStateAction<ProductFormErrors>>;
};

export const ProductForm = (props: Props) => {
  const {
    isFormDisabled,
    formValues,
    formErrors,
    setFormValues,
    setFormErrors,
    idx,
  } = props;
  const theme = useContext(ThemeContext);

  return (
    <Flex w100 column gap="24px">
      <Flex w100 data-testid="name-field" column>
        <Flex gap="8px" align="center">
          <LabelRegular>Product Name</LabelRegular>
          <RequiredField />
        </Flex>
        <InputText
          dataTestId={idx ? `name-input${idx}` : "name-input"}
          isError={!!formErrors.name}
          color={theme.black800}
          onChange={(e) => {
            setFormErrors({ ...formErrors, name: "" });
            setFormValues({ ...formValues, name: e.target.value });
          }}
          value={formValues.name}
          width="100%"
          disabled={isFormDisabled}
        />
        <FormError errorMessage={formErrors.name} />
      </Flex>
      <Flex column>
        <Flex gap="8px" align="center" justify="between">
          <LabelRegular>Product Description</LabelRegular>
          <OptionalField />
        </Flex>
        <TextArea
          isError={!!formErrors.description}
          color={theme.black800}
          onChange={(e) => {
            setFormErrors({ ...formErrors, description: "" });
            setFormValues({ ...formValues, description: e.target.value });
          }}
          value={formValues.description}
          disabled={isFormDisabled}
        />
        <FormError errorMessage={formErrors.description} />
      </Flex>
    </Flex>
  );
};
