import { Finding } from "../../../../types/Finding";
import { Section } from "../../../../components/elements/section/Section";
import {
  BodyRegular,
  LabelMedium,
} from "../../../../components/elements/typography/Typography";
import { Tooltip } from "../../../../components/elements/tooltip/Tooltip";
import { Icon } from "../../../../components/elements/icon/Icon";
import { cvssTooltip } from "../../../../shared/tooltipTexts";
import { light } from "../../../../shared/theme";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { CopyToClipboard } from "../../../../components/composed/copyToClipboard/CopyToClipboard";
import { Badge } from "../../../../components/elements/badge/Badge";

type Props = {
  finding: Finding | undefined | null;
  noSeparator?: boolean;
  width?: string;
  noTooltip?: boolean;
  withVector?: boolean;
  isReport?: boolean;
};

export const CVSSVectorItem = (props: Props) => {
  const {
    finding,
    noSeparator = false,
    width,
    noTooltip,
    isReport = false,
  } = props;

  const theme = useContext(ThemeContext);

  const CVSSVectorTitle = () => (
    <Flex align="center" gap="8px">
      CVSS Score & Vector
      {!noTooltip && (
        <Flex w100 align="center" justify="between">
          <Tooltip content={cvssTooltip}>
            <Icon
              name="info"
              color={isReport ? light.black700 : theme.black700}
              size={16}
            />
          </Tooltip>
          <Badge backgroundColor={theme.red50}>
            <LabelMedium color={theme.red800}>
              {finding?.cvss_score}
            </LabelMedium>
          </Badge>
        </Flex>
      )}
    </Flex>
  );

  return (
    <Section
      title={<CVSSVectorTitle />}
      noSeparator={noSeparator}
      width={width}
      isReport={isReport}
    >
      <Flex align="center" w100 style={{ overflowX: "hidden" }}>
        <Flex align="center" style={{ maxWidth: "85%", overflowX: "auto" }}>
          <Tooltip
            isTextTruncate
            placement="bottom"
            content={finding?.cvss_vector?.replace("CVSS:3.0/", "") || ""}
          >
            <BodyRegular style={{ color: isReport ? light.black800 : "" }}>
              {finding?.cvss_vector?.replace("CVSS:3.0/", "")}
            </BodyRegular>
          </Tooltip>
        </Flex>
        <Flex align="center" style={{ width: "12%" }}>
          {finding?.cvss_vector && (
            <CopyToClipboard
              value={finding?.cvss_vector?.replace("CVSS:3.0/", "")}
            />
          )}
        </Flex>
      </Flex>
    </Section>
  );
};
