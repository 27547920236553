import { fromInsight, toInsight } from "../../../shared/findingsHelper";
import { getDate } from "../../../shared/helper";

export const filtersWithTextLabels = [
  "products",
  "project",
  "overall_risk",
  "affected_assets",
  "scanner_name",
];

export const listFilters = [
  "products",
  "project",
  "overall_risk",
  "affected_assets",
  "status",
  "insight",
  "scanner_name",
];

export const excludedFilters = [
  "page",
  "ordering",
  "impact",
  "mitigation_complexity",
  "is_open",
];

export const stringsListToArray = (str: string): string[] => {
  if (str?.includes(",")) return str.split(",").map((num) => num);
  if (str?.includes("%2C")) return str.split("%2C").map((num) => num);
  return [str];
};

export const getNumberOfActiveFilters = (filters: {
  [key: string]: any;
}): number => {
  let filtersLength = Object.keys(filters).filter(
    (filterKey) => ![...excludedFilters, ...listFilters].includes(filterKey)
  ).length;

  listFilters.forEach((filterKey) => {
    if (filters[filterKey])
      filtersLength += stringsListToArray(filters[filterKey]).length;
  });

  return filtersLength;
};

export function convertToUiFilters(filters: { [key: string]: any }) {
  var tempFilters = { ...filters };
  delete tempFilters.is_open;
  if (tempFilters?.is_automated || tempFilters?.is_automated === false) {
    tempFilters.is_automated = tempFilters.is_automated
      ? ["automated"]
      : ["manual"];
  }
  if (tempFilters?.breached_sla || tempFilters?.breached_sla === false) {
    tempFilters.breached_sla = tempFilters.breached_sla
      ? ["breached"]
      : ["compliance"];
  }
  if (
    tempFilters?.is_false_positive ||
    tempFilters?.is_false_positive === false
  ) {
    tempFilters.is_false_positive = tempFilters.is_false_positive
      ? ["show"]
      : ["hide"];
  }
  if (
    tempFilters?.impact?.length &&
    tempFilters?.mitigation_complexity?.length
  ) {
    tempFilters.insight = toInsight(
      tempFilters.impact,
      tempFilters.mitigation_complexity
    );
  }
  if (tempFilters?.created_at_after)
    tempFilters.created_at_after = getDate(tempFilters.created_at_after);
  if (tempFilters?.created_at_before)
    tempFilters.created_at_before = getDate(tempFilters.created_at_before);
  return tempFilters;
}

export const convertToServerFilters = (
  filters: { [key: string]: any },
  activeOnly: boolean
) => {
  var tempFilters = { ...filters };
  if (tempFilters?.is_automated) {
    if (
      tempFilters?.is_automated?.length === 0 ||
      tempFilters?.is_automated?.length > 1
    ) {
      delete tempFilters.is_automated;
    } else {
      tempFilters.is_automated =
        tempFilters.is_automated[0] === "manual" ? false : true;
    }
  }
  if (tempFilters?.breached_sla) {
    if (
      tempFilters?.breached_sla?.length === 0 ||
      tempFilters?.breached_sla?.length > 1
    ) {
      delete tempFilters.breached_sla;
    } else {
      tempFilters.breached_sla =
        tempFilters.breached_sla[0] === "compliance" ? false : true;
    }
  }
  if (tempFilters?.is_false_positive) {
    if (
      tempFilters?.is_false_positive?.length === 0 ||
      tempFilters?.is_false_positive?.length > 1
    ) {
      delete tempFilters.is_false_positive;
    } else {
      tempFilters.is_false_positive =
        tempFilters.is_false_positive[0] === "hide" ? false : true;
    }
  }

  if (tempFilters?.insight) {
    const serverInsight = fromInsight(tempFilters.insight);
    if (serverInsight) {
      tempFilters.impact = serverInsight.impact;
      tempFilters.mitigation_complexity = serverInsight.mitigationComplexities;
    }
  }
  if (activeOnly) {
    tempFilters.is_open = true;
  }
  return tempFilters;
};
