import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import { Icon } from "../../../components/elements/icon/Icon";
import {
  HeaderSecondary,
  HeaderSubBold,
  LabelMedium,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import {
  useApiFindings,
  useApiFindingsCounts,
} from "../../../hooks/queries/findingContext";
import { useApiFindingsCommentsDeltaData } from "../../../hooks/queries/findingsCommentsContext";
import { useApiProjects } from "../../../hooks/queries/projectsContext";
import { Finding } from "../../../types/Finding";
import { Project } from "../../../types/Project";
import { MONTH_FROM_NOW, SCREEN_LAPTOP_WIDTH } from "../../../shared/consts";
import { FindingCardSkeleton } from "../findingsCards/FindingCardSkeleton";
import { FindingCard } from "../findingsCards/FindingCard";
import { getProject } from "../../../shared/findingsHelper";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import { Mixpanel } from "../../../shared/mixpanel";
import { useApiFindingLogsLatestFindings } from "../../../hooks/queries/findingsLogsContext";
import { FindingLog } from "../../../types/FindingLog";
import { Switch } from "../../../components/elements/switch/Switch";

type Filters =
  | {
      page_size: number;
      products: number[];
    }
  | {
      page_size: number;
    };

type Props = {
  onSelectedFinding: (finding: Finding) => void;
  onOpenProjectPane: (project: Project) => void;
  selectedProduct?: number | "all";
  show?: boolean;
  setShow?: (show: boolean) => void;
};

export const LastFindings = (props: Props) => {
  const {
    selectedProduct,
    onSelectedFinding,
    onOpenProjectPane,
    show = true,
    setShow,
  } = props;
  const theme = useContext(ThemeContext);
  const screenWidth = useScreenWidth();
  const isBigLaptop = screenWidth < SCREEN_LAPTOP_WIDTH;

  const defaultFilter = {
    page_size: 5,
  };
  const [findingsFilters, setFindingsFilter] = useState<Filters>(defaultFilter);

  useEffect(() => {
    setFindingsFilter(
      !!selectedProduct && selectedProduct !== "all"
        ? {
            ...defaultFilter,
            products: [selectedProduct],
          }
        : defaultFilter
    ); // eslint-disable-next-line
  }, [selectedProduct]);

  const { data: projects } = useApiProjects({
    with_findings_counts: true,
  });

  const { data: findings, isFetching } = useApiFindings(findingsFilters);

  const { data: findingsLogs, isFetching: isFetchingLogs } =
    useApiFindingLogsLatestFindings(
      { findings: findings?.map((f) => f.id) || [] },
      !!findings?.length
    );
  const { data: unreadComments } = useApiFindingsCommentsDeltaData();
  const { data: findingsCounts } = useApiFindingsCounts({
    created_at_after: MONTH_FROM_NOW,
  });

  const getFindingLog = (finding: Finding): FindingLog | undefined => {
    return findingsLogs?.find((fl) => fl.finding === finding.id);
  };

  return (
    <>
      <Flex column gap="8px">
        <Flex>
          <Icon name="notification" size={32} color={theme.primary} />
          <HeaderSecondary className="ms-2">Latest Findings</HeaderSecondary>
        </Flex>
        <Flex gap="4px" align="center">
          <Switch
            checked={show}
            onChange={(checked) => {
              !!setShow && setShow(checked);
              Mixpanel.track("Dashboard right widget choose", {
                widget: checked ? "Latest Findings" : "ASM Updates",
              });
            }}
          />
          <LabelMedium>Show Latest Findings</LabelMedium>
        </Flex>
      </Flex>
      {!isBigLaptop && (
        <Flex gap="24px" className="mt-2 mb-3">
          <Flex gap="8px" align="center" data-testid="findings-count-open">
            <LabelMedium>{findingsCounts?.open}</LabelMedium>
            <LabelRegular color={theme.textSub}>New Tickets</LabelRegular>
          </Flex>
          <Flex gap="8px" align="center">
            <LabelMedium data-testid="findings-count-fixed">
              {findingsCounts?.recently_fixed}
            </LabelMedium>
            <LabelRegular color={theme.textSub}>
              Were Fixed In The Last 30 Days
            </LabelRegular>
          </Flex>
        </Flex>
      )}

      <Flex
        column
        gap="8px"
        w100
        padding="16px 0px"
        style={{
          height: "calc(100% - 120px)",
          overflowY: "auto",
        }}
        align="center"
      >
        {!isFetching && findings?.length === 0 && (
          <HeaderSubBold className="h-100 w-100 d-flex flex-column justify-content-center align-items-center">
            No recent activity
          </HeaderSubBold>
        )}
        {isFetching && !findings?.length ? (
          <FindingCardSkeleton />
        ) : (
          projects &&
          unreadComments &&
          findings?.map((finding) => (
            <FindingCard
              key={`finding-card-${finding.id}`}
              finding={finding}
              findingLog={getFindingLog(finding)}
              isFetchingLogs={isFetchingLogs}
              project={getProject(finding, projects)}
              hasUnreadComments={unreadComments.some(
                (c) => c.finding_id === finding.id
              )}
              onSelectedFinding={(f: Finding) => {
                onSelectedFinding(f);
                Mixpanel.track("LatestFindings - Open finding", {
                  finding_id: finding.id,
                });
              }}
              onOpenProjectPane={(project: Project) => {
                onOpenProjectPane(project);
                Mixpanel.track("LatestFindings - Open project pane", {
                  project_id: project.id,
                  finding_id: finding.id,
                });
              }}
              isSmall={isBigLaptop}
            />
          ))
        )}
      </Flex>
    </>
  );
};
