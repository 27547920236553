import { useEffect, useState } from "react";
import { SwitchStyle, SliderStyle } from "./Switch.style";

type Props = {
  checked: boolean;
  onChange: (checked: boolean) => void;
  className?: string;
  disabled?: boolean;
  dataTestId?: string;
};

export function Switch(props: Props) {
  const {
    checked,
    onChange,
    className = "",
    disabled = false,
    dataTestId,
  } = props;

  const [isOn, setIsOn] = useState(checked);

  useEffect(() => {
    setIsOn(checked);
  }, [checked]);

  return (
    <div className={className + " d-flex align-items-center"}>
      <SwitchStyle>
        <input
          data-testid={dataTestId || ""}
          disabled={disabled}
          type="checkbox"
          role="button"
          aria-expanded="false"
          style={{ display: "none" }}
          checked={isOn}
          onChange={(e) => {
            setIsOn(e.target.checked);
            onChange(e.target.checked);
          }}
        />
        <SliderStyle checked={isOn} disabled={disabled} />
      </SwitchStyle>
    </div>
  );
}
