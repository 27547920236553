import { Dispatch, SetStateAction, useContext } from "react";
import { Box } from "../../../components/elements/box/Box";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import {
  BodyRegular,
  HeaderSecondary,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { RightPane } from "../../../components/elements/rightPane/RightPane";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import { useApiProjects } from "../../../hooks/queries/projectsContext";
import { FindingBadges } from "./FindingBadges";
import { FindingHeaderItems } from "./FindingHeaderItems";
import { AffectedAssetsList } from "./items/AffectedAssetsList";
import { AffectedProductsList } from "./items/AffectedProductsList";
import { Link, useSearchParams } from "react-router-dom";
import { getProject } from "../../../shared/findingsHelper";
import { useTrackPage } from "../../../hooks/trackingHooks";
import { Loading } from "../../../components/elements/loading/Loading";
import { Flex } from "../../../components/layouts/flex/Flex";
import { objectToBase64 } from "../../../shared/helper";
import { findingsDefaultFilters } from "../Findings";
import { FindingHistory } from "../findingHistoryAndComments/FindingHistory";
import { useApiSingleFinding } from "../../../hooks/queries/findingContext";
import { useLicense } from "../../../licensing/LicenseManager";
import { FindingsTrialLimitation } from "../../../components/composed/trialLimitationsMessages/FindingsTrialLimitation";
import { Badge } from "../../../components/elements/badge/Badge";
import { ThemeContext } from "styled-components";
import { useIsSuperuser } from "../../../hooks/useIsSuperuser";
import { FindingAttachmentsDetails } from "../findingDetails/FindingAttachmentsDetails";

type Props = {
  onClose: () => void;
  findingId: number | undefined;
  setShowAffectedAssetsPane?: Dispatch<SetStateAction<boolean>>;
  isLoading?: boolean;
};

export const FindingPane = (props: Props) => {
  const {
    onClose,
    findingId,
    setShowAffectedAssetsPane,
    isLoading = false,
  } = props;
  const theme = useContext(ThemeContext);
  const isSupeuser = useIsSuperuser();
  const { licenseType } = useLicense();
  const { data: projects } = useApiProjects();
  const { data: products } = useApiProducts();
  const { data: finding, isFetching: isFetchingFinding } =
    useApiSingleFinding(findingId);
  const project = getProject(finding, projects);

  const [searchParams] = useSearchParams();
  const filtersBase64 =
    searchParams.get("filters") || objectToBase64(findingsDefaultFilters);
  useTrackPage("Findings - Panel", { finding_id: findingId });

  return (
    <RightPane onClose={onClose} width={"1008px"}>
      {isLoading || isFetchingFinding ? (
        <Flex column h100 w100 justify="center" align="center">
          <Loading />
        </Flex>
      ) : licenseType === "trial" &&
        finding?.overall_risk &&
        finding?.overall_risk >= 3 ? (
        <FindingsTrialLimitation />
      ) : (
        <div
          className="d-flex flex-column gap-32"
          style={{
            padding: "32px",
            height: "100%",
            overflowY: "auto",
            marginRight: "10px",
          }}
        >
          <div className="d-flex flex-column justify-content-center gap-8">
            <div className="d-flex justify-content-between align-items-start gap-16 w-100">
              <Tooltip
                content={finding?.title || ""}
                placement="bottom"
                isTextTruncate
              >
                <HeaderSecondary
                  className="text-truncate"
                  style={{
                    maxWidth: "500px",
                  }}
                >
                  {finding?.title}
                </HeaderSecondary>
              </Tooltip>
              <div className="d-flex align-items-center gap-16">
                <Link
                  to={`/finding-details/${finding?.id}?filters=${filtersBase64}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                  onClick={onClose}
                >
                  <MainButton
                    iconName="findings"
                    label="Finding Page"
                    size="small"
                  />
                </Link>
              </div>
            </div>
            <Flex gap="12px" style={{ marginBlock: "12px" }}>
              <FindingBadges finding={finding} showTicketBadge />
              {isSupeuser && finding?.retest_not_found && (
                <Badge color={theme.redPrimary} backgroundColor={theme.red50}>
                  Retest Not Found
                </Badge>
              )}
            </Flex>
            <SeparatorHorizontal />
          </div>

          <Box className="d-flex flex-column gap-32">
            <FindingHeaderItems finding={finding} project={project} />
            <SeparatorHorizontal />
            <Flex column gap="8px">
              <LabelRegular>Finding Summary</LabelRegular>
              <BodyRegular className="d-flex flex-wrap">
                {finding?.summary}
              </BodyRegular>
            </Flex>
          </Box>
          <Box className="d-flex flex-column gap-24">
            <AffectedProductsList finding={finding} products={products} />
            <AffectedAssetsList
              finding={finding}
              setShowAffectedAssetsPane={setShowAffectedAssetsPane}
            />
            <FindingAttachmentsDetails findingId={finding?.id} />
          </Box>
          <SeparatorHorizontal />
          {!!finding?.id && <FindingHistory finding={finding} />}
        </div>
      )}
    </RightPane>
  );
};
