import { useContext, useEffect, useMemo, useState } from "react";
import { AddAssetForm } from "../../assets/addAssets/AddAssetForm";
import { VerifyAssetForm } from "../../assets/assetVerification/VerifyAssetForm";
import { Asset } from "../../../types/Asset";
import {
  BodyRegular,
  BodySemiBold,
  HeaderMain,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { useApiAssetsPaging } from "../../../hooks/queries/assetsContext";
import { Loading } from "../../../components/elements/loading/Loading";
import { OnboardingFooter } from "./OnboardingFooter";
import { Box } from "../../../components/elements/box/Box";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";
import { Icon } from "../../../components/elements/icon/Icon";
import { ThemeContext } from "styled-components";

type Props = {
  onSuccess: () => void;
  onClose: () => void;
  goToPrevStage: () => void;
};

export const AddAsset = (props: Props) => {
  const { onSuccess, onClose, goToPrevStage } = props;
  const theme = useContext(ThemeContext);

  const { data: assetsPages, isFetching: isFetchingAssets } =
    useApiAssetsPaging({}, true);

  const assets = useMemo(
    () => assetsPages?.pages.map((page) => page.results || []).flat() || [],
    [assetsPages]
  );

  const [assetToVerify, setAssetToVerify] = useState<Asset | null>();
  const [assetToEnumerate, setAssetToEnumerate] = useState<Asset | null>();

  useEffect(() => {
    // Set the inner stage (add asset -> verify -> enumerate)
    if (!assets?.length) return;
    const toVerify = assets?.find((a) => !a.is_verified);
    if (toVerify) setAssetToVerify(toVerify);
    const toEnumerate = assets?.find((a) => a.is_verified);
    if (toEnumerate) {
      setAssetToEnumerate(toEnumerate);
      setAssetToVerify(toEnumerate);
    }
  }, [assets, setAssetToVerify]);

  return (
    <>
      <HeaderMain>Let's add your first root domain</HeaderMain>
      <BodyRegular>
        After ownership verification, you will be able to add your sub domains
      </BodyRegular>
      {isFetchingAssets ? (
        <Loading />
      ) : (
        <Flex justify="evenly" style={{ width: "1352px", alignSelf: "start" }}>
          <Box>
            <Flex column gap="32px" style={{ width: "600px" }}>
              <Flex align="center" gap="8px">
                <BodySemiBold>1. Enter your root domain details</BodySemiBold>
                {!!assetToVerify && (
                  <Icon name="check" color={theme.greenPrimary} size={24} />
                )}
              </Flex>
              <AddAssetForm
                onApply={(asset) => setAssetToVerify(asset)}
                hideAsmButton
                hideParentAsset
                defaultProdEnvironment
                editedAsset={assetToVerify}
                isFormDisabled={!!assetToVerify && assetToVerify.is_verified}
              />
            </Flex>
          </Box>
          <Box style={{ zIndex: 100 }}>
            <Flex column gap="32px" style={{ width: "600px" }}>
              <Flex column gap="4px">
                <BodySemiBold>
                  2. Copy the Unique identifier below and add it to the domain
                  dns records
                </BodySemiBold>
                <BodySemiBold>
                  allowing WASP to verify this domain is controlled by you.
                </BodySemiBold>
              </Flex>
              <VerifyAssetForm
                asset={assetToVerify || undefined}
                onVerification={(asset) =>
                  asset.is_verified &&
                  setTimeout(() => setAssetToEnumerate(asset), 2000)
                }
                disabled={!assetToVerify}
              />
            </Flex>
          </Box>
        </Flex>
      )}
      <OnboardingFooter onClose={onClose}>
        <Flex gap="8px">
          <SecondaryButton label="Back" onClick={goToPrevStage} size="medium" />
          <MainButton
            label="Next"
            onClick={onSuccess}
            disabled={!assetToEnumerate}
            size="medium"
          />
        </Flex>
      </OnboardingFooter>
    </>
  );
};
