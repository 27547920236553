import { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import { Icon } from "../../../components/elements/icon/Icon";
import { RightPane } from "../../../components/elements/rightPane/RightPane";
import { ToggleWithIcon } from "../../../components/elements/toggle/ToggleWithIcon";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { useApiCreateAssetsView } from "../../../hooks/queries/assetsViewsContext";
import useToastContext from "../../../hooks/toastHook";
import { useMutationWithDelay } from "../../../hooks/utilsHooks";
import { AssetsView, AssetsViewProps } from "../../../types/AssetsView";
import { CreateEditFilterPane } from "./CreateEditFilterPane";
import { EditFilterPane } from "./EditFilterPane";
import {
  emptyAssetsView,
  isValidFilters,
  isValidView,
  mutateAssetsViewWrapper,
  setFiltersOrder,
} from "./FiltersUtils";
import { SavedFiltersPane } from "./SavedFiltersPane";
import { Flex } from "../../../components/layouts/flex/Flex";

export const STORAGE_KEY = "assetPaneIsCreateState";

type Props = {
  assetsView: AssetsViewProps;
  setAssetsView: (assetsView: AssetsViewProps) => void;
  onClose: () => void;
};

export const AssetsFiltersPane = (props: Props) => {
  const { onClose, setAssetsView, assetsView } = props;
  // use local store to get toggle state
  const [toggleCreateFilter, setToggleCreateFilter] = useState(
    localStorage.getItem(STORAGE_KEY) === "true"
  );
  const [isEditAssetsView, setIsEditAssetsView] = useState(false);
  const [selectedView, setSelectedView] = useState<AssetsView>(emptyAssetsView);

  const [newAssetsView, setNewAssetsView] =
    useState<AssetsViewProps>(assetsView);

  const { mutate: createAssetsView, isLoading } = useApiCreateAssetsView();

  const { runMutation, isRunning } = useMutationWithDelay(3000);

  const theme = useContext(ThemeContext);
  const addToast = useToastContext();

  const handleSaveFilter = () => {
    const data = {
      ...newAssetsView,
      filters: setFiltersOrder(newAssetsView.filters),
    };
    setAssetsView(data);
    mutateAssetsViewWrapper(
      runMutation,
      onClose,
      addToast,
      createAssetsView,
      { assetsViewData: data },
      `Adding filter "${newAssetsView.name}"`,
      `Filter "${newAssetsView.name}" added successfully `,
      `Failed to add filter "${newAssetsView.name}"`
    );
  };

  const handleApplyFilter = () => {
    setAssetsView({
      ...newAssetsView,
      filters: setFiltersOrder(newAssetsView.filters),
    });
    onClose();
  };

  if (isEditAssetsView) {
    return (
      <EditFilterPane
        assetsView={selectedView}
        onClose={() => setIsEditAssetsView(false)}
      />
    );
  }

  return (
    <RightPane onClose={onClose}>
      <Flex column>
        <Flex column gap="24px" style={{ padding: "24px" }}>
          <Flex align="center" justify="between" gap="16px">
            <Flex align="center" gap="16px">
              <Icon name="filter" color={theme.primary} size={24} />
              <HeaderSecondary>Add Filters</HeaderSecondary>
            </Flex>
            <Flex align="center" gap="16px">
              <TextButton
                label="Save"
                onClick={handleSaveFilter}
                dataTestId="save-filter-button"
                disabled={
                  !toggleCreateFilter ||
                  !isValidView(newAssetsView) ||
                  isRunning ||
                  isLoading
                }
              />
              <MainButton
                label="Apply"
                size="small"
                onClick={handleApplyFilter}
                inProgress={isRunning || isLoading}
                dataTestId="apply-filter-button"
                disabled={!isValidFilters(newAssetsView.filters)}
              />
            </Flex>
          </Flex>
          <Flex column>
            <ToggleWithIcon
              dataTestId="toggle-create-filter"
              onText="Saved Filters"
              offText="Create Filter"
              onIconName="save"
              offIconName="edit"
              width="100%"
              checked={toggleCreateFilter}
              onChange={(checked) => {
                setToggleCreateFilter(checked);
                localStorage.setItem(STORAGE_KEY, checked.toString());
              }}
            />
          </Flex>
        </Flex>
        {toggleCreateFilter ? (
          <CreateEditFilterPane
            defaultAssetsView={newAssetsView}
            setAssetsView={setNewAssetsView}
          />
        ) : (
          <SavedFiltersPane
            defaultAssetsView={newAssetsView}
            setAssetsView={setNewAssetsView}
            onEditAssetsView={(assetsView) => {
              setSelectedView(assetsView);
              setIsEditAssetsView(true);
            }}
          />
        )}
      </Flex>
    </RightPane>
  );
};
