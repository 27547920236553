import { useContext } from "react";
import { NotificationReceiver } from "../../../types/Notifications";
import { ThemeContext } from "styled-components";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  LabelBold,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { FindingField, StatusMapping } from "../../../shared/consts";
import { FindingStatus } from "../../findings/findingStatus/FindingStatus";

type Props = {
  notificationReceiver: NotificationReceiver;
};
export const NotificationItemDetails = (props: Props) => {
  const { notificationReceiver } = props;
  const theme = useContext(ThemeContext);
  const actorName =
    notificationReceiver.notification_event.event_details["actor"]["name"];

  const getDetails = (baseText: string) => (
    <Flex align="center">
      <LabelRegular color={theme.black600}>
        <LabelBold color={theme.blue700}>@{actorName}</LabelBold> {baseText}
      </LabelRegular>
    </Flex>
  );

  const getNewValue = (field: string, newValue: string) => {
    switch (field) {
      case "Status":
        return StatusMapping[newValue as keyof typeof FindingStatus];
      case "Assignee":
        return newValue;
    }
  };

  const eventHandlers = {
    FINDING_CREATED: () => getDetails("added a new finding."),
    FINDING_DELETED: () => {
      const id =
        notificationReceiver.notification_event.event_details["finding"]["id"];
      return getDetails(`deleted the finding. (ID: ${id})`);
    },
    FINDING_UPDATED: () => {
      const fieldKey =
        notificationReceiver.notification_event.event_details["field"];
      const field = FindingField[fieldKey as keyof typeof FindingField];
      const newValue = getNewValue(
        field,
        notificationReceiver.notification_event.event_details["new_value"]
      );
      if (field === undefined || newValue === undefined) {
        return getDetails(`made an update to the finding.`);
      }
      return getDetails(`updated field ${field} to ${newValue}.`);
    },
    COMMENT_CREATED: () => getDetails("added a comment."),
    COMMENT_MENTION: () => getDetails("mentioned you on a comment."),
    SENT_TO_JIRA: () => getDetails("synced finding with Jira."),
    BREACHED_SLA: () => (
      <LabelRegular color={theme.black600}>Finding breached SLA!</LabelRegular>
    ),
  };

  const eventType = notificationReceiver.notification_event.event_type;
  const handler = eventType ? eventHandlers[eventType] : null;

  return handler ? handler() : null;
};
