import { BodyRegular } from "../../../../components/elements/typography/Typography";
import { SEVERITIES } from "../../../../shared/consts";
import { Finding } from "../../../../types/Finding";
import { Section } from "../../../../components/elements/section/Section";
import { light } from "../../../../shared/theme";
import { useContext } from "react";
import { ThemeContext } from "styled-components";

type Props = {
  finding: Finding | undefined | null;
  noSeparator?: boolean;
  width?: string;
  isReport?: boolean;
};

export const ExploitabilityItem = (props: Props) => {
  const { finding, noSeparator = false, width, isReport = false } = props;
  const theme = useContext(ThemeContext);

  return (
    <Section
      title="Exploitability"
      noSeparator={noSeparator}
      width={width}
      isReport={isReport}
    >
      <BodyRegular
        style={{ color: isReport ? light.black800 : theme.black800 }}
      >
        {SEVERITIES[finding?.exploitability || 0]}
      </BodyRegular>
    </Section>
  );
};
