import { Flex } from "../../layouts/flex/Flex";
import { Ellipse } from "../ellipse/Ellipse";
import { BodyRegular, BodyRegularHover } from "../typography/Typography";

type Props = {
  label: string;
  color: string;
  onClick?: (e: any, label: string) => void;
  isOn?: boolean;
  ellipseSize?: number;
  labelSize?: number;
};

export const LegendItem = (props: Props) => {
  const { label, onClick, color, ellipseSize, labelSize, isOn = true } = props;
  return (
    <Flex
      align="center"
      gap="8px"
      className={!!onClick ? "pointer" : ""}
      onClick={(e) => (!!onClick ? onClick(e, label) : {})}
    >
      <Ellipse color={color} size={ellipseSize || 12} />
      {!!onClick ? (
        <BodyRegularHover
          style={{
            textDecoration: isOn ? "" : "line-through",
            fontSize: `${labelSize || 14}px`,
          }}
        >
          {label}
        </BodyRegularHover>
      ) : (
        <BodyRegular
          style={{
            fontSize: `${labelSize || 14}px`,
          }}
        >
          {label}
        </BodyRegular>
      )}
    </Flex>
  );
};
