import { useContext } from "react";
import { Tooltip } from "../../../../components/elements/tooltip/Tooltip";
import { BodyRegular } from "../../../../components/elements/typography/Typography";
import { Project } from "../../../../types/Project";
import { Section } from "../../../../components/elements/section/Section";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { Icon } from "../../../../components/elements/icon/Icon";
import { ThemeContext } from "styled-components";

type Props = {
  isAutomated?: boolean;
  project: Project | undefined | null;
  noSeparator?: boolean;
  width?: string;
};

export const DiscoverOnItem = (props: Props) => {
  const { project, noSeparator = false, isAutomated = false } = props;
  const theme = useContext(ThemeContext);
  return (
    <Section title="Discovered on" noSeparator={noSeparator}>
      <Flex style={{ overflow: "auto" }} w100 className="text-truncate">
        <Tooltip
          isTextTruncate
          placement="bottom"
          content={
            isAutomated || project?.type?.toLowerCase() === "asm"
              ? "ASM"
              : project?.name || "N/A"
          }
        >
          <BodyRegular className="text-truncate w-100">
            {isAutomated || project?.type?.toLowerCase() === "asm" ? (
              <Flex gap="8px">
                <Icon name="wasp" size={24} color={theme.primary} /> Automated
              </Flex>
            ) : (
              project?.name || "N/A"
            )}
          </BodyRegular>
        </Tooltip>
      </Flex>
    </Section>
  );
};
