import { Section } from "../../../../components/elements/section/Section";
import { BodyRegular } from "../../../../components/elements/typography/Typography";
import { useApiMe } from "../../../../hooks/queries/meContext";
import { useApiScanners } from "../../../../hooks/queries/scannersContext";
import { Finding } from "../../../../types/Finding";

type Props = {
  finding: Finding | undefined | null;
  noSeparator?: boolean;
  width?: string;
};

export const ScannerItem = (props: Props) => {
  const { finding, noSeparator = false } = props;

  const { data: me } = useApiMe();

  const { data: scanners } = useApiScanners(me?.customer?.id);

  let scannersDisplayNamesMap =
    scanners?.reduce<Record<string, string>>((acc, current) => {
      acc[current.name] = current.display_name;
      return acc;
    }, {}) || {};

  // Add display name for "wasp" (for automate_findings created by SU)
  scannersDisplayNamesMap.wasp = "WASP";

  return (
    <Section title="Source Scanner" noSeparator={noSeparator}>
      <BodyRegular className="text-truncate w-100">
        {scannersDisplayNamesMap && finding?.scanner_name
          ? scannersDisplayNamesMap[finding.scanner_name]
          : "-"}
      </BodyRegular>
    </Section>
  );
};
