import { useMemo } from "react";
import { Modal } from "../../../components/elements/modal/Modal";
import { BodyRegular } from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  useApiAsset,
  useApiAssetsWithViewPaging,
} from "../../../hooks/queries/assetsContext";
import { VerifyImportedAssets } from "../addAssets/VerifyImportedAssets";
import { VerifyAssetForm } from "./VerifyAssetForm";

type Props = {
  onClose: () => void;
  assetId: number;
};

export const unverifiedAssetsFilter = {
  column: "is_verified",
  value: "False",
  condition: "in",
  order: 0,
  next_condition: "and",
};

export const VerifyAssetModal = (props: Props) => {
  const { onClose, assetId } = props;
  const { data: asset } = useApiAsset(assetId);

  const { data: unverifiedDomainsPages } = useApiAssetsWithViewPaging([
    unverifiedAssetsFilter,
  ]);

  const unverifiedDomains = useMemo(
    () =>
      unverifiedDomainsPages?.pages.map((page) => page.results || []).flat() || [],
    [unverifiedDomainsPages]
  );

  const modalHeader = `Verify ${!!assetId ? asset?.name : ""} Domain Ownership`;

  return (
    <Modal header={modalHeader} width="600px" onClose={onClose}>
      {assetId !== 0 ? (
        <Flex column gap="24px">
          <Flex column align="center" gap="8px">
            <BodyRegular>
              For running scans on your asset we must make sure you own this
              domain
            </BodyRegular>
            <BodyRegular>
              Add the token as a TXT record on your domain DNS and click to
              verify
            </BodyRegular>
          </Flex>
          <VerifyAssetForm asset={asset} />
        </Flex>
      ) : (
        <VerifyImportedAssets assets={unverifiedDomains} onClose={() => {}} />
      )}
    </Modal>
  );
};
