import { BodyRegular } from "../../../../components/elements/typography/Typography";
import { IMPACTS, MITIGATION } from "../../../../shared/consts";
import { Finding } from "../../../../types/Finding";
import { Section } from "../../../../components/elements/section/Section";
import { light } from "../../../../shared/theme";

type Props = {
  finding: Finding | undefined | null;
  noSeparator?: boolean;
  useMitigationValues?: boolean;
  isReport?: boolean;
};

export const ImpactItem = (props: Props) => {
  const {
    finding,
    noSeparator = false,
    useMitigationValues,
    isReport = false,
  } = props;

  const values = useMitigationValues ? MITIGATION : IMPACTS;
  const findingImpact = finding?.impact || 0;

  return (
    <Section title="Impact" noSeparator={noSeparator} isReport={isReport}>
      <BodyRegular
        className="text-truncate w-100"
        style={{ color: isReport ? light.black800 : "" }}
      >
        {values[useMitigationValues ? findingImpact + 1 : findingImpact]}
      </BodyRegular>
    </Section>
  );
};
