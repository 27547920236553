import styled from "styled-components";

type SliderProps = {
  checked: boolean;
  disabled: boolean;
};

export const SwitchStyle = styled.label`
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
`;

export const SliderStyle = styled.span<SliderProps>`
  position: absolute;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: 0;
  background-color: ${(props) =>
    props.disabled
      ? props.theme.black400
      : props.checked
      ? props.theme.primary
      : props.theme.blue300};
  transition: all 0.2s linear;
  border-radius: 17px;
  &:before {
    transition: all 0.2s linear;
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    top: 2px;
    left: 2px;
    margin-left: ${(props) => (props.checked ? "16px" : "0")};
    bottom: 4px;
    background-color: ${(props) => props.theme.bg2};
    border-radius: 50%;
  }
`;
