import { GraphGroupNode, GraphNode, Placement } from "./GraphTypes";

const PADDING_BETWEEN_GROUPS: number = 50; // Padding between groups
export const NODE_RADIUS: number = 10; // Radius of individual nodes
const MAX_GROUP_RADIUS: number = 500; // Maximum radius of a group
const MIN_GROUP_RADIUS: number = NODE_RADIUS * 2 + 30; // Maximum radius of a group

export function calculateGroupRadius(nodesCount: number): number {
  // Calculate the radius of the group dynamically based on the number of nodes
  return Math.max(
    MIN_GROUP_RADIUS,
    Math.min(MAX_GROUP_RADIUS, 20 + Math.ceil(nodesCount * NODE_RADIUS))
  );
}

export function findPlacementForGroup(
  radius: number,
  placements: Placement[]
): { x: number; y: number } {
  const diameter = radius;
  const rightMargin = 120; // Add margin to the right for the label
  const PADDING_BETWEEN_GROUPS = 50;

  // Define the grid search starting points
  let potentialX = 50;
  let potentialY = 50;

  // Iterate through the grid starting at the top-left, moving right and then down
  const maxIterations = 1000; // Prevent infinite loops by limiting attempts
  let iteration = 0;

  while (iteration < maxIterations) {
    // Check if the current spot can fit the group
    if (canPlace(potentialX, potentialY, diameter, placements)) {
      // If found space, return the position
      return { x: potentialX, y: potentialY };
    }

    // Move right first, increment the X position
    potentialX += diameter + PADDING_BETWEEN_GROUPS + rightMargin;

    // If moving to the right exceeds a threshold, reset X and move downwards
    const maxRight = 1000; // Limit how far right we can go
    if (potentialX > maxRight) {
      potentialX = 50; // Reset to leftmost position
      potentialY += diameter + PADDING_BETWEEN_GROUPS; // Move down
    }

    iteration++;
  }

  // If no suitable space is found after searching the grid, place it far right
  const maxX =
    Math.max(...placements.map((p) => p.x + p.width)) +
    PADDING_BETWEEN_GROUPS +
    rightMargin;
  return { x: maxX, y: 50 }; // Default to the next rightmost position
}

function canPlace(
  x: number,
  y: number,
  diameter: number,
  placements: Placement[]
): boolean {
  // Ensure this placement doesn't overlap with existing ones
  return !placements.some(
    (p) =>
      x < p.x + p.width + PADDING_BETWEEN_GROUPS &&
      x + diameter + PADDING_BETWEEN_GROUPS > p.x &&
      y < p.y + p.height + PADDING_BETWEEN_GROUPS &&
      y + diameter + PADDING_BETWEEN_GROUPS > p.y
  );
}

export function createNodesLayout(radius: number, nodes: GraphNode[]): void {
  // The padding between nodes and the group border
  const groupBorderPadding = NODE_RADIUS;
  nodes.forEach((node) => {
    let x,
      y,
      attempts = 0;
    do {
      const angle = Math.random() * 2 * Math.PI;
      const radiusWithPadding = radius - groupBorderPadding;
      const distanceFromCenter = Math.random() * radiusWithPadding;
      x = Math.round(distanceFromCenter * Math.cos(angle)) + radiusWithPadding;
      y = Math.round(distanceFromCenter * Math.sin(angle)) + radiusWithPadding;

      // Clamp the node positions to ensure they do not stray outside the group circle
      x = Math.max(NODE_RADIUS, x);
      y = Math.max(NODE_RADIUS, y);
    } while (isNodeOverlapping(x, y, nodes) && attempts++ < 10);

    node.x = x; // Relative to group's center
    node.y = y;
  });
}

function isNodeOverlapping(x: number, y: number, nodes: GraphNode[]): boolean {
  const minSpacing = NODE_RADIUS * 2; // Minimum spacing between nodes
  return nodes.some((node) => {
    const dx = node.x - x;
    const dy = node.y - y;
    return Math.sqrt(dx * dx + dy * dy) < minSpacing;
  });
}

export function createGroupsLayout(groups: GraphGroupNode[]): void {
  var placements: Placement[] = [];
  groups.forEach((group) => {
    const radius = calculateGroupRadius(
      group.isExpand ? group.nodes.length : 0
    );
    const placement = findPlacementForGroup(radius, placements);
    group.center = { x: placement.x + radius, y: placement.y + radius };
    group.radius = radius;
    placements.push({
      x: placement.x,
      y: placement.y,
      width: radius * 2,
      height: radius * 2,
    });
  });
}

/**
 * Calculates the position of a node that is being dragged outside the group.
 * If the node exceeds the group's radius, the new position is calculated on the edge of the group.
 *
 * @param groupRadius - The radius of the group
 * @param x - The current x-coordinate of the node
 * @param y - The current y-coordinate of the node
 * @returns { x: number, y: number } | undefined - Returns the corrected position or undefined if within the boundary
 */

export function getPositionOfNodeDragOutsideGroup(
  groupRadius: number,
  x: number,
  y: number
): { x: number; y: number } | undefined {
  const padding = NODE_RADIUS; // Padding between the node and the group border
  const groupCenter = {
    x: groupRadius - padding,
    y: groupRadius - padding,
  };
  const dx = x - groupCenter.x;
  const dy = y - groupCenter.y;
  const distance = Math.sqrt(dx * dx + dy * dy);

  if (distance + padding > groupRadius) {
    // Calculate the angle and set position on the edge of the circle
    const angle = Math.atan2(dy, dx);
    const x = groupCenter.x + (groupRadius - padding) * Math.cos(angle);
    const y = groupCenter.y + (groupRadius - padding) * Math.sin(angle);

    return { x, y };
  }
}
