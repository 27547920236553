import { getAsset } from "../../../../hooks/queries/assetsContext";
import {
  createItem,
  getItems,
  getSingleItem,
} from "../../../../hooks/queries/sdk";
import { Filter } from "../../../../types/AssetsView";
import { Finding } from "../../../../types/Finding";

const PAGE_SIZE = 10;

export type GraphFinding = {
  id: number;
  title: string;
  overall_risk: number;
  affected_assets?: number[];
};

export type GraphAsset = {
  id: number;
  name: string;
  product_id: number;
  type: string;
  parent_asset: number | null;
  risk_score: number;
  children_count?: number;
  related_findings?: number[];
  related_findings_objects?: GraphFinding[];
  environment: "production" | "staging" | "development" | "test";
};

export type GraphRelation = {
  finding_id: number;
  asset_id: number;
};

export class GraphDataProvider {
  assets: GraphAsset[] = [];
  findings: GraphFinding[] = [];
  relations: GraphRelation[] = [];
  assetsFilters: Filter[] = [];
  filteredAssetsIds: number[] = [];
  hasFilters: boolean = false;

  async init() {
    console.log("GraphDataProvider is starting");
    return await getItems("assets/graph").then((data) => {
      this.assets = data.assets;
      this.findings = data.findings;
      this.relations = data.relations;
      console.log("GraphDataProvider is starting precessing data");
      console.log(
        "calculate the number of children and relations for each asset"
      );
      this.preprocessData();
      console.log("GraphDataProvider is ready");
    });
  }

  preprocessData() {
    this.assets.forEach((asset) => {
      asset.children_count = this.assets.filter(
        (a) => a.parent_asset === asset.id
      ).length;
      asset.related_findings = this.relations
        .filter((r) => r.asset_id === asset.id)
        .map((r) => r.finding_id);
    });
    console.log("calculate relations for each finding");
    this.findings.forEach((finding) => {
      finding.affected_assets = this.relations
        .filter((r) => r.finding_id === finding.id)
        .map((r) => r.asset_id);
    });
  }

  async updateAssetsFilters(filters: Filter[]) {
    this.hasFilters = filters.length > 0;
    if (!this.hasFilters) {
      this.filteredAssetsIds = [];
      return;
    }
    this.assetsFilters = filters;
    await createItem("assets/filter_graph", { filters }).then((data) => {
      this.filteredAssetsIds = data;
    });
  }

  getRootAssets() {
    return this.assets.filter((asset) => asset.parent_asset === null);
  }

  getAssetsPage(page: number, pageSize: number = PAGE_SIZE) {
    return this.assets.slice(
      (page - 1) * pageSize,
      (page - 1) * pageSize + pageSize
    );
  }
  getAsset(id: number) {
    return this.assets.find((asset) => asset.id === id);
  }

  getFinding(id: number) {
    return this.findings.find((finding) => finding.id === id);
  }

  getRelatedFindings(findingIds: number[]) {
    return this.findings.filter((finding) => findingIds.includes(finding.id));
  }

  getFilteredAssetsPage(page: number, pageSize: number = PAGE_SIZE) {
    return this.assets
      .filter(
        (asset) => !this.hasFilters || this.filteredAssetsIds.includes(asset.id)
      )
      .slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
  }

  getChildrenFilteredAssetsPage(
    parentAsset: number,
    page: number,
    pageSize: number = PAGE_SIZE
  ) {
    return this.assets
      .filter(
        (asset) =>
          asset.parent_asset === parentAsset &&
          (!this.hasFilters || this.filteredAssetsIds.includes(asset.id))
      )
      .slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
  }

  getFindingAffectedAssetsPage(
    affectedAssets: number[],
    page: number,
    pageSize: number = PAGE_SIZE
  ) {
    return this.assets
      .filter(
        (asset) =>
          affectedAssets?.includes(asset.id) &&
          (!this.hasFilters || this.filteredAssetsIds.includes(asset.id))
      )
      .slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
  }

  getFullAssetData(id: number) {
    if (!id) {
      return Promise.resolve(undefined);
    }
    return getAsset(id);
  }

  getFullFindingData(id: number) {
    if (!id) {
      return Promise.resolve(undefined);
    }
    return getSingleItem("findings", id.toString()).then((data) =>
      data ? (data as Finding) : undefined
    );
  }
}
