import { useQuery } from "@tanstack/react-query";
import { getItems } from "./sdk";
import { CtiItem } from "../../types/CtiItem";

const key = "cti-items";

export const useApiCtiItems = () =>
  useQuery<CtiItem[], Error>({
    queryKey: [key],
    keepPreviousData: true,
    placeholderData: [],
    enabled: true,
    queryFn: async (): Promise<CtiItem[]> => getItems(key),
  });
