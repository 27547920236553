import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import { Box } from "../../../components/elements/box/Box";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { Icon } from "../../../components/elements/icon/Icon";
import { Toggle } from "../../../components/elements/toggle/Toggle";
import FindingsLegend, { IFindingsLegend } from "./FindingsLegend";
import FindingsOverTime from "./findingsOverTime/FindingsOverTime";
import FindingsFunnel from "./findingsFunnel/FindingsFunnel";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { findingsTimeOptions } from "../filters/Timeframe";
import { SingleValue } from "react-select";
import {
  Dropdown,
  Option,
} from "../../../components/elements/dropdowns/Dropdown";
import { Mixpanel } from "../../../shared/mixpanel";

const defaultLegend = {
  info: false,
  low: true,
  medium: true,
  high: true,
  critical: true,
};

const overTimeOptions = [
  { label: "Last 30 days", value: 30 },
  { label: "Last 90 days", value: 90 },
  { label: "Last Year", value: 365 },
];

export const FindingsChartsContainer = ({
  selectedProduct,
}: {
  selectedProduct: number | "all";
}) => {
  const theme = useContext(ThemeContext);

  const [legend, setLegend] = useState<IFindingsLegend>(defaultLegend);
  const [isFunnel, setIsFunnel] = useState<boolean>(true);
  const [timeframe, setTimeframe] = useState<SingleValue<Option>>(
    findingsTimeOptions[5]
  );

  const [themeName, setThemeName] = useState(theme.name);
  useEffect(() => {
    if (theme.name === themeName) return;
    setThemeName("");
    setTimeout(() => setThemeName(theme.name), 100);
  }, [theme.name, themeName]);

  // On charts switching - change legend and timeframe
  const handleToggle = (funnelState: boolean) => {
    setIsFunnel(!funnelState);
    const overTimeLegend = { total: true, ...defaultLegend };
    setLegend(!funnelState ? defaultLegend : overTimeLegend);
    if (funnelState) {
      let isTimeframeInScope = overTimeOptions
        .map((opt) => opt.label)
        .includes(`${timeframe?.label}`);
      !isTimeframeInScope && setTimeframe(overTimeOptions[2]);
    } else {
      let isTimeframeInScope = findingsTimeOptions
        .map((opt) => opt.label)
        .includes(`${timeframe?.label}`);
      !isTimeframeInScope && setTimeframe(findingsTimeOptions[4]);
    }
  };

  return (
    <Box
      data-tut="dashboard-funnel"
      className="w-100 d-flex flex-column gap-16"
      style={{
        overflowX: "clip",
      }}
    >
      {/*BOX HEADER */}
      <div className="d-flex flex-wrap align-items-center justify-content-between gap-8">
        <div className="d-flex align-items-center gap-16 ">
          <div className="d-flex align-items-center gap-8">
            <Icon name="findings" color={theme.primary} />
            <HeaderSecondary>Findings</HeaderSecondary>
            <Toggle
              checked={isFunnel}
              onChange={() => {
                Mixpanel.track("Toggle press", {
                  isFunnel: isFunnel,
                  isOverTime: !isFunnel,
                });
                handleToggle(isFunnel);
              }}
              offText="Over Time"
              onText="Funnel"
              width="168px"
            />
          </div>
          <SeparatorVertical style={{ height: "34px" }} />
          <Dropdown
            onChange={(option) => {
              Mixpanel.track(
                "Dashboard - Findings Chart - Timeframe selection",
                { timeSelected: option?.label || "" }
              );
              setTimeframe(option);
            }}
            options={isFunnel ? findingsTimeOptions : overTimeOptions}
            value={timeframe}
            dataTestId="timeframe-dropdown"
          />
        </div>
        <FindingsLegend legend={legend} setLegend={setLegend} />
      </div>

      <div>
        {isFunnel ? (
          <div data-testid="funnal-container">
            {themeName && (
              <FindingsFunnel
                selectedProduct={selectedProduct}
                legend={legend}
                timeframe={timeframe}
              />
            )}
          </div>
        ) : (
          <FindingsOverTime
            selectedProduct={selectedProduct}
            legend={legend}
            timeframe={timeframe}
          />
        )}
      </div>
    </Box>
  );
};
