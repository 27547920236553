import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import { Box } from "../../../components/elements/box/Box";
import { Icon } from "../../../components/elements/icon/Icon";
import {
  HeaderSecondary,
  HeaderSubHover,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { SkeletonLoading } from "../../../components/elements/loading/SkeletonLoading";
import { useApiCtiItems } from "../../../hooks/queries/ctiItemsContext";
import { BadgesLine } from "../../../components/elements/badge/BadgesLine";
import { Flex } from "../../../components/layouts/flex/Flex";
import ImgCyberPlaceholder from "../../../assets/images/cyber-placeholder.png";
import { CtiItem } from "../../../types/CtiItem";
import { Mixpanel } from "../../../shared/mixpanel";

type Props = {};

const defaultCtiItems: CtiItem[] = [
  {
    id: 1,
    title: "COMING SOON",
    image: ImgCyberPlaceholder,
    link: "",
    affected_products: [],
    created_at: "",
    updated_at: "",
  },
];

export const IntelFeed = (props: Props) => {
  const theme = useContext(ThemeContext);
  const { data: products } = useApiProducts();
  const { data: ctiItemsData } = useApiCtiItems();
  const [itemIdx, setItemIdx] = useState<number>(1);
  const [ctiItems, setCtiItems] = useState<CtiItem[]>(defaultCtiItems);
  useEffect(() => {
    if (!!ctiItemsData?.length) {
      setCtiItems(ctiItemsData);
    }
  }, [ctiItemsData]);
  const hasPrevPage = itemIdx > 1;
  const hasNextPage = itemIdx < parseInt(`${ctiItems?.length}`);

  const productsBadges =
    ctiItems[itemIdx - 1]?.affected_products
      ?.map((prodId) => products?.find((p) => p.id === prodId)?.name || "")
      .filter((prodName) => !!prodName) || [];

  return (
    <Box
      className="d-flex flex-column justify-content-start align-items-center gap-16"
      style={{ width: "100%", padding: "24px", height: "297px" }}
      data-tut="intel-feed"
    >
      <Flex justify="between" align="center" gap="8px" w100>
        <Flex gap="8px" align="center" style={{ width: "calc(100% - 64px)" }}>
          <Icon name="news" color={theme.primary} size={32} />
          <HeaderSecondary
            className="text-truncate"
            title="Threats Intelligence Feed"
          >
            Threat Intelligence Feed
          </HeaderSecondary>
        </Flex>
        <Flex align="center">
          <IconButton
            iconName="chevronLeft"
            color={theme.primary}
            size="small"
            onClick={() => {
              Mixpanel.track("IntelFeed Click");
              hasPrevPage && setItemIdx((prev) => prev - 1);
            }}
            disabled={!hasPrevPage}
            dataTestId="prev-item"
          />
          <IconButton
            iconName="chevronRight"
            color={theme.primary}
            size="small"
            onClick={() => {
              Mixpanel.track("IntelFeed Click");
              hasNextPage && setItemIdx((prev) => prev + 1);
            }}
            disabled={!hasNextPage}
            dataTestId="next-item"
          />
        </Flex>
      </Flex>
      <Flex column h100 justify="between" gap="16px" w100>
        {!!products?.length && ctiItems[itemIdx - 1]?.image ? (
          <img
            src={ctiItems[itemIdx - 1].image}
            alt="cyber-placeholder"
            height={"100px"}
            style={{
              borderRadius: "8px 0 8px 8px",
              width: "inherit",
            }}
          />
        ) : (
          <SkeletonLoading width="100%" height="100px" />
        )}

        <HeaderSubHover
          className="d-flex flex-wrap "
          style={{ width: "inherit" }}
        >
          <a
            href={ctiItems[itemIdx - 1]?.link}
            style={{
              textDecoration: "none",
              color: "inherit",
              width: "inherit",
            }}
            data-testid="cti-item-title"
            rel="noreferrer"
            target="_blank"
          >
            {ctiItems[itemIdx - 1]?.title}
          </a>
        </HeaderSubHover>

        <Flex align="center" gap="8px">
          <LabelRegular data-testid="cti-item-date">
            {ctiItems[itemIdx - 1]?.created_at.split("T")[0]}
          </LabelRegular>
          <BadgesLine badges={productsBadges} noWrap={true} />
        </Flex>
      </Flex>
    </Box>
  );
};
