import {
  MiniMap,
  ReactFlow,
  type Node,
  OnNodesChange,
  applyNodeChanges,
} from "@xyflow/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import "@xyflow/react/dist/style.css";
import { GraphAsset } from "../sigmaGraph/GraphDataProvider";
import { NodeDomain, NodeGroup } from "./GraphNodes";
import { Loading } from "../../../../components/elements/loading/Loading";
import { AssetsViewProps, Filter } from "../../../../types/AssetsView";
import { Box } from "../../../../components/elements/box/Box";
import {
  Dropdown,
  Option,
} from "../../../../components/elements/dropdowns/Dropdown";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { LabelMedium } from "../../../../components/elements/typography/Typography";
import { useApiProducts } from "../../../../hooks/queries/productsContext";
import { calculateAssetGrade } from "../../AssetUtils";
import { ThemeContext } from "styled-components";
import { AssetGrade } from "../../../../types/Asset";
import { IconButton } from "../../../../components/elements/button/icon/IconButton";
import { Checkbox } from "../../../../components/elements/checkbox/Checkbox";
import {
  useApiAssetsGraphData,
  useApiAssetsGraphFilter,
} from "../../../../hooks/queries/assetsGraphContext";
import { GraphGroupNode, GraphNode, NodeType, Placement } from "./GraphTypes";
import {
  calculateGroupRadius,
  createGroupsLayout,
  createNodesLayout,
  findPlacementForGroup,
  getPositionOfNodeDragOutsideGroup,
  NODE_RADIUS,
} from "./NodeLayoutManager";

import "./customReactFlow.css";
import { CustomControls } from "./CustomControls";
import { SeparatorHorizontal } from "../../../../components/elements/separators/SeparatorHorizontal";
import { QuickFilters } from "./QuickFilters";
import { useSearchParams } from "react-router-dom";
import {
  fromBase64AssetsView,
  toBase64AssetsView,
} from "../../../../shared/helper";
import { AssetsFiltersBadges } from "../../filters/AssetsFiltersBadges";
import { TextButton } from "../../../../components/elements/button/text/TextButton";
import { AssetsFiltersPane } from "../../filters/AssetsFiltersPane";
import { defaultAssetsView } from "../../Assets";
import { Ellipse } from "../../../../components/elements/ellipse/Ellipse";
import { emptyAssetsViewProps } from "../../filters/FiltersUtils";

const groupByOptions: Option[] = [
  { label: "Environment", value: "environment" },
  { label: "Product", value: "product" },
  { label: "Risk", value: "risk" },
  // { label: "Technology", value: "technology" },
  // { label: "Vulnerabilities", value: "vulnerabilities" },
];

const MAP_ASSET_GRADE_RISK: Record<AssetGrade, string> = {
  A: "No Risk",
  B: "Low",
  C: "Medium",
  D: "Medium",
  E: "High",
  F: "Critical",
};

const DEFAULT_SHOW_LABELS = false;
const DEFAULT_COLOR_BY_RISK = true;
const DEFAULT_EXPAND_ALL = false;

const nodeTypes: Record<NodeType, (props: any) => JSX.Element> = {
  assetsGroup: NodeGroup,
  domain: NodeDomain,
};

export const AssetsFlowGraph = () => {
  const theme = useContext(ThemeContext);
  const [nodes, setNodes] = useState<Node[]>([]);
  const [showLabels, setShowLabels] = useState(DEFAULT_SHOW_LABELS);
  const [colorByRisk, setColorByRisk] = useState(DEFAULT_COLOR_BY_RISK);
  const [expandAll, setExpandAll] = useState(DEFAULT_EXPAND_ALL);
  const [isMapLayerCollapsed, setIsMapLayerCollapsed] = useState(false);
  const [isQuickFiltersCollapsed, setIsQuickFiltersCollapsed] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [assetsView, setAssetsView] =
    useState<AssetsViewProps>(defaultAssetsView);
  const [showFiltersPanel, setShowFiltersPanel] = useState(false);

  const [groupBy, setGroupBy] = useState<Option>(
    groupByOptions.find((o) => o.value === "product") as Option
  );
  const [groupIds, setGroupIds] = useState<string[]>([]);

  const { data: products, isFetching: isFetchingProducts } = useApiProducts();
  const { data: graphData, isFetching: isFetchingGraph } =
    useApiAssetsGraphData();
  const { data: filteredAssetIds, isFetching: isFetchingFilteredAssetIds } =
    useApiAssetsGraphFilter(assetsView.filters);

  const onNodesChange: OnNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [setNodes]
  );

  useEffect(() => {
    // Sets the assets view state from URL params,
    // If no view attribute on URL params, sets the URL to default view
    let encodedView = searchParams.get("view");
    if (encodedView) {
      let decodedView = fromBase64AssetsView(encodedView);
      if (decodedView) setAssetsView(decodedView);
    }
  }, [searchParams, setSearchParams]);

  const handleAssetsViewChange = (updatedAssetsView: AssetsViewProps) => {
    setSearchParams({
      ...searchParams,
      view: toBase64AssetsView(updatedAssetsView),
    }); // trigger useEffect to update filters
  };

  const handleFiltersChange = (filters: Filter[]) => {
    assetsView.filters = filters; // update filters
    const viewBase64 = toBase64AssetsView(assetsView);
    setSearchParams({ ...searchParams, view: viewBase64 }); // trigger useEffect to update filters
  };

  const onNodeDrag = useCallback(
    (event: React.MouseEvent, node: Node) => {
      if (!!node.data?.isGroup || !node.data?.isExpand) {
        return;
      }
      const groupNode = nodes.find((n) => n.id === node.parentId);
      if (!groupNode) {
        return;
      }
      const newPos = getPositionOfNodeDragOutsideGroup(
        groupNode.data.radius as number,
        node.position.x,
        node.position.y
      );
      if (newPos) {
        setNodes((nds) =>
          nds.map((n) => {
            if (n.id === node.id) {
              // Update node position to stay within the boundary
              return {
                ...n,
                position: newPos,
              };
            }
            return n;
          })
        );
      }
    },
    [setNodes, nodes]
  );

  const getAssetsGroupedBy = useCallback(
    (assets: GraphAsset[]): Record<string, GraphNode[]> => {
      const groupAssetsBy = (
        assets: GraphAsset[],
        getKey: (asset: GraphAsset) => string | undefined
      ) => {
        return assets
          .filter((a) => getKey(a))
          .reduce(
            (acc, asset) => {
              const key = getKey(asset);
              if (key) {
                if (!acc[key]) {
                  acc[key] = [];
                }
                acc[key].push({
                  id: `a${asset.id}`,
                  groupId: key,
                  risk: asset.risk_score,
                  x: 0,
                  y: 0,
                  name: asset.name,
                });
              }
              return acc;
            },
            {} as Record<string, GraphNode[]>
          );
      };

      if (groupBy.value === "environment") {
        return groupAssetsBy(assets, (asset) => asset.environment);
      }

      if (groupBy.value === "product") {
        return groupAssetsBy(
          assets,
          (asset) =>
            products?.find((p) => p.id === asset.product_id)?.name || ""
        );
      }

      if (groupBy.value === "risk") {
        return groupAssetsBy(
          assets,
          (asset) => MAP_ASSET_GRADE_RISK[calculateAssetGrade(asset.risk_score)]
        );
      }

      return {};
    },
    [groupBy, products]
  );

  const riskToColor = useCallback(
    (riskScore: number, shouldColorByRisk: boolean): string => {
      if (!shouldColorByRisk) {
        return theme.primary;
      }
      const assetGrade = calculateAssetGrade(riskScore);
      const risk = MAP_ASSET_GRADE_RISK[assetGrade];
      return {
        "No Risk":
          groupBy.value === "risk" ? theme.graphRiskNoRisk : theme.primary,
        Low: theme.graphRiskLow,
        Medium: theme.graphRiskMedium,
        High: theme.graphRiskHigh,
        Critical: theme.graphRiskCritical,
      }[risk];
    },
    [groupBy, theme]
  );

  const mapGraphNodeToReactFlowNode = useCallback(
    (node: GraphNode, isExpand: boolean): Node => {
      return {
        id: node.id,
        data: {
          label: `${node.name}`,
          color: riskToColor(node.risk, DEFAULT_COLOR_BY_RISK),
          isGroup: false,
          risk: node.risk,
          showLabel: DEFAULT_SHOW_LABELS,
          expandX: node.x,
          expandY: node.y,
          isExpand: isExpand,
        },
        type: "domain",
        position: {
          x: isExpand ? node.x : 14,
          y: isExpand ? node.y : 14,
        },
        parentId: node.groupId,
      };
    },
    [riskToColor]
  );

  const calcOtherGroupPosition = useCallback(
    (n: Node, currentGroupsPlacements: Placement[]) => {
      const otherGroupPosition = findPlacementForGroup(
        Number(n.data?.radius),
        currentGroupsPlacements
      );
      currentGroupsPlacements.push({
        x: otherGroupPosition.x,
        y: otherGroupPosition.y,
        width: Number(n.data?.radius) * 2,
        height: Number(n.data?.radius) * 2,
      });
    },
    []
  );

  const handleCollapsed = useCallback(
    (groupId: string) => {
      setNodes((nodes) => {
        const currentGroup = nodes.find((n) => n.id === groupId);
        const groupRadius = calculateGroupRadius(0);
        var currentGroupsPlacements: Placement[] = [
          {
            x: Number(currentGroup?.position?.x),
            y: Number(currentGroup?.position?.y),
            width: groupRadius * 2,
            height: groupRadius * 2,
          },
        ];

        return (
          nodes
            // update group's node properties
            .map((n) => {
              const isOtherGroup = !!n.data.isGroup && n.id !== groupId;
              if (isOtherGroup) {
                calcOtherGroupPosition(n, currentGroupsPlacements);
              }

              return {
                ...n,
                // case child node
                ...(n.parentId === groupId && {
                  data: {
                    ...n.data,
                    isExpand: false,
                  },
                  position: {
                    x: NODE_RADIUS,
                    y: NODE_RADIUS,
                  },
                }),
                // case current group node
                ...(n.id === groupId && {
                  data: {
                    ...n.data,
                    isExpand: false,
                  },
                }),
                // case other groups need to move to make space for the expanded group
                ...(isOtherGroup && {
                  position: {
                    x: currentGroupsPlacements.slice(-1)[0].x,
                    y: currentGroupsPlacements.slice(-1)[0].y,
                  },
                }),
              };
            })
        );
      });
    },
    [calcOtherGroupPosition]
  );

  const handleExpand = useCallback(
    (groupId: string) => {
      setNodes((nodes) => {
        const groupNodes = nodes.filter((n) => n.parentId === groupId);
        const groupRadius = calculateGroupRadius(groupNodes.length);
        // map ReactFlowNode to GraphNode
        const graphNodes: GraphNode[] = groupNodes.map((n) => ({
          groupId: n.parentId || "",
          id: n.id,
          x: 0, // will be calculated later
          y: 0, // will be calculated later
          risk: Number(n?.data?.risk) || 0,
          name: `${n?.data?.label}` || "",
        }));
        createNodesLayout(groupRadius, graphNodes);

        const currentGroup = nodes.find((n) => n.id === groupId);
        var currentGroupsPlacements: Placement[] = [
          {
            x: Number(currentGroup?.position?.x),
            y: Number(currentGroup?.position?.y),
            width: groupRadius * 2,
            height: groupRadius * 2,
          },
        ];

        return [
          ...nodes
            // update group's node properties
            .map((n) => {
              const currentGraphNode = graphNodes.find((gn) => gn.id === n.id);
              const isOtherGroup = !!n.data.isGroup && n.id !== groupId;
              if (isOtherGroup) {
                calcOtherGroupPosition(n, currentGroupsPlacements);
              }
              return {
                ...n,
                // case current group's child node
                ...(n.parentId === groupId && {
                  data: {
                    ...n.data,
                    isExpand: true,
                  },
                  position: {
                    x: currentGraphNode?.x || 0,
                    y: currentGraphNode?.y || 0,
                  },
                }),
                // case this group node
                ...(n.id === groupId && {
                  data: {
                    ...n.data,
                    radius: groupRadius,
                    isExpand: true,
                  },
                }),
                // case other groups need to move to make space for the expanded group
                ...(isOtherGroup && {
                  position: {
                    x: currentGroupsPlacements.slice(-1)[0].x,
                    y: currentGroupsPlacements.slice(-1)[0].y,
                  },
                }),
              };
            }),
        ];
      });
    },

    [calcOtherGroupPosition]
  );

  const getGroupRisk = useCallback((group: GraphGroupNode) => {
    return group.nodes.reduce((acc, node) => {
      if (!acc || node.risk > acc) {
        return node.risk;
      }
      return acc;
    }, 0);
  }, []);

  useEffect(() => {
    if (
      isFetchingGraph ||
      isFetchingProducts ||
      isFetchingFilteredAssetIds ||
      !graphData?.assets?.length
    )
      return;
    const assetsToUse = filteredAssetIds?.length
      ? graphData.assets.filter((a) => filteredAssetIds.includes(a.id))
      : graphData.assets;
    const groupedAssets = getAssetsGroupedBy(assetsToUse);
    setGroupIds(Object.keys(groupedAssets));

    // map to layout manager object
    const groups: GraphGroupNode[] = Object.keys(groupedAssets).map(
      (groupId) => ({
        id: groupId,
        center: { x: 0, y: 0 },
        radius: 0,
        nodes: groupedAssets[groupId],
        isExpand: DEFAULT_EXPAND_ALL,
      })
    );
    createGroupsLayout(groups);

    const groupNodes = groups.map((group) => {
      if (group.isExpand) {
        createNodesLayout(group.radius, groupedAssets[group.id]);
      }

      const groupRisk = getGroupRisk(group);
      return {
        id: group.id,
        data: {
          label: `${group.id}`,
          radius: group.radius,
          x: group.center.x, // need to save the center position for expand/collapse
          y: group.center.y, // need to save the center position for expand/collapse
          nodesCount: group.nodes.length,
          isGroup: true,
          risk: groupRisk,
          color: riskToColor(groupRisk, DEFAULT_COLOR_BY_RISK),
          isExpand: DEFAULT_EXPAND_ALL,
          onClick: DEFAULT_EXPAND_ALL ? handleCollapsed : handleExpand,
        },
        type: "assetsGroup",
        position: {
          x: group.center.x,
          y: group.center.y,
        },
      };
    });
    const assetsNodes = Object.values(groupedAssets)
      .flat()
      .map((node) => mapGraphNodeToReactFlowNode(node, DEFAULT_EXPAND_ALL));

    setNodes([...groupNodes, ...assetsNodes]);
  }, [
    graphData,
    isFetchingFilteredAssetIds,
    filteredAssetIds,
    isFetchingGraph,
    isFetchingProducts,
    getAssetsGroupedBy,
    riskToColor,
    getGroupRisk,
    handleCollapsed,
    handleExpand,
    mapGraphNodeToReactFlowNode,
  ]);

  // updates without reset positions
  useEffect(() => {
    setNodes((nodes) =>
      nodes.map((n) => {
        return {
          ...n,
          data: {
            ...n.data,
            showLabel: showLabels,
            color: riskToColor(Number(n.data.risk), colorByRisk),
          },
        };
      })
    );
  }, [showLabels, colorByRisk, riskToColor]);

  useEffect(() => {
    setNodes((nodes) =>
      nodes.map((n) => ({
        ...n,
        data: {
          ...n.data,
          ...(!!n.data?.isExpand && {
            onClick: () => handleCollapsed(n.id),
          }),
          ...(!n.data?.isExpand && {
            onClick: () => handleExpand(n.id),
          }),
        },
      }))
    );
  }, [nodes, handleCollapsed, handleExpand]);

  return (
    <Box
      id="graph-component-container"
      className="d-flex w-100"
      style={{
        position: "relative",
        height: `calc(100vh - 164px)`,
      }}
    >
      <Flex column w100 h100 gap="24px">
        <Flex align="center" gap="16px">
          <Flex align="center" gap="8px">
            <LabelMedium className="text-truncate">View By:</LabelMedium>
            <Dropdown
              onChange={(option) => setGroupBy(option as Option)}
              options={groupByOptions}
              size="small"
              variant="border"
              width="180px"
              placeholder="Group by"
              value={groupBy}
            />
            <Flex
              style={{
                position: "relative",
              }}
            >
              <IconButton
                iconName="filter"
                color={
                  assetsView.filters.length > 0 ? theme.primary : theme.black600
                }
                size={"small"}
                onClick={() => setShowFiltersPanel(true)}
              />
              {assetsView.filters.length > 0 && (
                <Ellipse
                  color={theme.redPrimary}
                  size={8}
                  style={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    transform: "translate(50%, -50%)",
                  }}
                />
              )}
            </Flex>
          </Flex>

          <AssetsFiltersBadges
            filters={assetsView.filters}
            setFilters={handleFiltersChange}
          />
          {assetsView.filters.length > 0 && (
            <TextButton
              label="Clear all filters"
              onClick={() => handleAssetsViewChange(emptyAssetsViewProps)}
            />
          )}
        </Flex>
        <Flex w100 h100>
          <Flex
            column
            gap="16px"
            style={{
              width: "280px",
            }}
          >
            <Flex align="center" gap="8px" justify="between" w100>
              <LabelMedium>Map Layers</LabelMedium>
              <IconButton
                iconName={isMapLayerCollapsed ? "chevronDown" : "chevronUp"}
                color={theme.black700}
                onClick={() => setIsMapLayerCollapsed(!isMapLayerCollapsed)}
                size="small"
              />
            </Flex>
            {!isMapLayerCollapsed && (
              <Flex column gap="16px">
                <Checkbox
                  label="Expand / Collapse"
                  labelColor={theme.textSecondary}
                  hasMidCheck={false}
                  size="small"
                  state={expandAll ? "checked" : "unchecked"}
                  onChange={(state) => {
                    setExpandAll(state === "checked");
                    if (state === "checked") {
                      groupIds.map((groupId) => handleExpand(groupId));
                    } else {
                      groupIds.map((groupId) => handleCollapsed(groupId));
                    }
                  }}
                />
                <Checkbox
                  label="Labels"
                  size="small"
                  labelColor={theme.textSecondary}
                  hasMidCheck={false}
                  state={showLabels ? "checked" : "unchecked"}
                  onChange={(state) => setShowLabels(state === "checked")}
                />
                <Checkbox
                  label="Findings"
                  size="small"
                  labelColor={theme.textSecondary}
                  hasMidCheck={false}
                  state={colorByRisk ? "checked" : "unchecked"}
                  onChange={(state) => setColorByRisk(state === "checked")}
                />
              </Flex>
            )}
            <SeparatorHorizontal />
            <Flex align="center" gap="8px" justify="between" w100>
              <LabelMedium>Quick Filters</LabelMedium>
              <IconButton
                iconName={isQuickFiltersCollapsed ? "chevronDown" : "chevronUp"}
                color={theme.black700}
                onClick={() =>
                  setIsQuickFiltersCollapsed(!isQuickFiltersCollapsed)
                }
                size="small"
              />
            </Flex>
            {!isQuickFiltersCollapsed && <QuickFilters />}
            <TextButton
              label="+ Add custom filter"
              onClick={() => setShowFiltersPanel(true)}
            />
            {showFiltersPanel && (
              <AssetsFiltersPane
                assetsView={assetsView}
                setAssetsView={handleAssetsViewChange}
                onClose={() => setShowFiltersPanel(false)}
              />
            )}
          </Flex>

          {isFetchingGraph ||
          isFetchingProducts ||
          isFetchingFilteredAssetIds ? (
            <Flex w100 h100 align="center" justify="center">
              <Loading />
            </Flex>
          ) : (
            <ReactFlow
              nodes={nodes}
              onNodesChange={onNodesChange}
              // onConnect={onConnect}
              nodeTypes={nodeTypes}
              onNodeDrag={onNodeDrag}
              onNodeDragStop={onNodeDrag}
            >
              <MiniMap />
              <CustomControls />
              {/* <Background /> */}
            </ReactFlow>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};
