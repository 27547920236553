import { IconProps } from "./WaspIcon";

export function CopyIcon(props: IconProps) {
  const size = props.size || 20;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1312_485)">
        <mask
          id="mask0_1312_485"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="-1"
          width="25"
          height="25"
        >
          <rect
            x="0.117188"
            y="-0.146484"
            width="24"
            height="24"
            fill={props.color || "currentColor"}
          />
        </mask>
        <g mask="url(#mask0_1312_485)">
          <path
            d="M9.39531 17.6543C8.87865 17.6543 8.44531 17.4793 8.09531 17.1293C7.74531 16.7793 7.57031 16.3543 7.57031 15.8543V4.4793C7.57031 3.96263 7.74531 3.5293 8.09531 3.1793C8.44531 2.8293 8.87865 2.6543 9.39531 2.6543H17.7453C18.2453 2.6543 18.6746 2.8333 19.0333 3.1913C19.3913 3.54996 19.5703 3.9793 19.5703 4.4793V15.8543C19.5703 16.3543 19.3913 16.7793 19.0333 17.1293C18.6746 17.4793 18.2453 17.6543 17.7453 17.6543H9.39531ZM9.39531 16.1543H17.7453C17.8453 16.1543 17.9246 16.125 17.9833 16.0663C18.0413 16.0083 18.0703 15.9376 18.0703 15.8543V4.4793C18.0703 4.3793 18.0413 4.29996 17.9833 4.2413C17.9246 4.1833 17.8453 4.1543 17.7453 4.1543H9.39531C9.29531 4.1543 9.21631 4.1833 9.15831 4.2413C9.09965 4.29996 9.07031 4.3793 9.07031 4.4793V15.8543C9.07031 15.9376 9.09965 16.0083 9.15831 16.0663C9.21631 16.125 9.29531 16.1543 9.39531 16.1543ZM5.87031 21.1543C5.37031 21.1543 4.94531 20.9793 4.59531 20.6293C4.24531 20.2793 4.07031 19.8543 4.07031 19.3543V7.4043C4.07031 7.2043 4.14098 7.0293 4.28231 6.8793C4.42431 6.7293 4.60365 6.6543 4.82031 6.6543C5.02031 6.6543 5.19531 6.7293 5.34531 6.8793C5.49531 7.0293 5.57031 7.2043 5.57031 7.4043V19.3543C5.57031 19.4376 5.59931 19.5083 5.65731 19.5663C5.71598 19.625 5.78698 19.6543 5.87031 19.6543H14.8203C15.0203 19.6543 15.1953 19.7293 15.3453 19.8793C15.4953 20.0293 15.5703 20.2043 15.5703 20.4043C15.5703 20.621 15.4953 20.8 15.3453 20.9413C15.1953 21.0833 15.0203 21.1543 14.8203 21.1543H5.87031Z"
            fill={props.color || "currentColor"}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1312_485">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
