import { SetStateAction } from "react";
import { Box } from "../../../components/elements/box/Box";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import { useApiProjects } from "../../../hooks/queries/projectsContext";
import { Finding } from "../../../types/Finding";
import { AffectedAssetsList } from "../findingPane/items/AffectedAssetsList";
import { AffectedProductsList } from "../findingPane/items/AffectedProductsList";
import { AssignedToItem } from "../findingPane/items/AssignedToItem";
import { DiscoverOnItem } from "../findingPane/items/DiscoverOnItem";
import { ExploitabilityItem } from "../findingPane/items/ExploitabilityItem";
import { ImpactItem } from "../findingPane/items/ImpactItem";
import { IssuedByItem } from "../findingPane/items/IssuedByItem";
import { ScannerItem } from "../findingPane/items/ScannerItem";
import { getProject } from "../../../shared/findingsHelper";
import { Flex } from "../../../components/layouts/flex/Flex";
import { CVSSVectorItem } from "../findingPane/items/CVSSVector";
import {
  BodyRegular,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { FindingLastSeen } from "../findingPane/items/FindingLastSeen";
import { FindingAttachmentsDetails } from "./FindingAttachmentsDetails";

type Props = {
  finding: Finding | undefined;
  setShowAffectedAssetsPane?: React.Dispatch<SetStateAction<boolean>>;
};

export const MiddleBox = (props: Props) => {
  const { finding, setShowAffectedAssetsPane } = props;
  const { data: projects } = useApiProjects();
  const { data: products } = useApiProducts();

  const project = getProject(finding, projects);

  return (
    <Box className="d-flex flex-column gap-24 w-100 box">
      <Flex align="center" justify="between" w100>
        <AssignedToItem finding={finding} />
        <IssuedByItem finding={finding} />
        <ExploitabilityItem finding={finding} />
        <CVSSVectorItem finding={finding} noSeparator />
      </Flex>
      <Flex align="center" justify="between" w100>
        <DiscoverOnItem
          project={project}
          isAutomated={!!finding?.is_automated}
        />
        <ImpactItem finding={finding} />
        <FindingLastSeen finding={finding} />
        <ScannerItem finding={finding} noSeparator />
      </Flex>
      <SeparatorHorizontal />
      {!!finding?.summary && (
        <Flex column gap="8px">
          <LabelRegular>Finding Summary</LabelRegular>
          <BodyRegular className="d-flex flex-wrap">
            {finding?.summary}
          </BodyRegular>
        </Flex>
      )}
      <AffectedProductsList finding={finding} products={products} />
      <AffectedAssetsList
        finding={finding}
        setShowAffectedAssetsPane={setShowAffectedAssetsPane}
      />
      <FindingAttachmentsDetails findingId={finding?.id} />
    </Box>
  );
};
