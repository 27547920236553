import { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { Box } from "../../../components/elements/box/Box";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import {
  Dropdown,
  Option,
} from "../../../components/elements/dropdowns/Dropdown";
import { Icon } from "../../../components/elements/icon/Icon";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import {
  HeaderSubBold,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { useApiAssetsBreakdown } from "../../../hooks/queries/assetsContext";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import {
  SCREEN_LAPTOP_WIDTH,
  assetsDistributionOptions,
  defaultAssetDistribution,
} from "../../../shared/consts";
import { Filter } from "../../../types/AssetsView";
import { DistributionChart, DistributionChartItem } from "./DistributionChart";
import { TopDistributionSkeleton } from "./TopDistributionSkeleton";
import { Link, useSearchParams } from "react-router-dom";
import { Flex } from "../../../components/layouts/flex/Flex";
import { SingleValue } from "react-select";
import { Mixpanel } from "../../../shared/mixpanel";
import {
  fromBase64AssetsView,
  toBase64AssetsView,
} from "../../../shared/helper";
import { emptyAssetsView } from "../filters/FiltersUtils";

type Props = {
  filters: Filter[];
};

export const TopDistribution = (props: Props) => {
  const { filters } = props;
  const theme = useContext(ThemeContext);
  const [distribution, setDistribution] = useState<SingleValue<Option>>(
    defaultAssetDistribution
  );
  const screenWidth = useScreenWidth();
  const isLaptop = screenWidth < SCREEN_LAPTOP_WIDTH;
  const [searchParams, setSearchParams] = useSearchParams();

  const distributionStr =
    distribution?.value.toString() || defaultAssetDistribution.value.toString();

  const { data: assetsBreakdown, isFetching } = useApiAssetsBreakdown(
    distributionStr,
    "desc",
    filters
  );

  const processData = (data: {
    [key: string]: number;
  }): DistributionChartItem[] => {
    return Object.keys(data)
      .filter((key) => !!key)
      .map((key) => ({ name: key, value: data[key] }))
      .sort((a, b) => b.value - a.value)
      .slice(0, 5);
  };

  const onClickItem = (dataItemName: string) => {
    let viewEncoded = searchParams.get("view");
    let viewDecoded = viewEncoded
      ? fromBase64AssetsView(viewEncoded)
      : emptyAssetsView;

    let filtersList =
      viewDecoded?.filters.filter((f) => f.column !== distributionStr) || [];
    console.log({ filtersList });
    setSearchParams({
      ...searchParams,
      view: toBase64AssetsView({
        name: "",
        filters: [
          ...filtersList,
          {
            column: distributionStr,
            value: dataItemName,
            condition: distributionStr === "status_code" ? "is" : "contains",
            order: 0,
            next_condition: "and",
          },
        ],
      }),
    });
  };

  return (
    <Box
      className={`d-flex flex-column justify-content-between  w-100  h-100 ${
        isLaptop ? "gap-16" : "gap-24"
      }`}
      style={{ padding: "16px", paddingBottom: "24px" }}
    >
      <Flex align="center" justify="between" gap="8px" className="w-100">
        <Flex align="center" gap={isLaptop ? "4px" : "16px"}>
          <Flex align="center" gap="8px">
            <Icon name="insightsOutline" size={32} color={theme.primary} />
            <HeaderSubBold>Top 5 distribution by</HeaderSubBold>
          </Flex>
          <SeparatorVertical style={{ height: "16px" }} />
          <Dropdown
            value={distribution}
            onChange={(option) => {
              Mixpanel.track("Distribution change", {
                selected: option?.label,
              });
              setDistribution(option);
            }}
            options={assetsDistributionOptions}
          />
        </Flex>
        <Link to="/insights">
          <IconButton iconName="chevronRight" />
        </Link>
      </Flex>
      {!isFetching && !Object.keys(assetsBreakdown || {})?.length && (
        <Flex justify="center" align="center" className="w-100 h-100">
          <LabelRegular>No data available</LabelRegular>
        </Flex>
      )}
      {isFetching && !assetsBreakdown ? (
        <TopDistributionSkeleton />
      ) : (
        <DistributionChart
          data={processData(assetsBreakdown || {})}
          onClickItem={onClickItem}
        />
      )}
    </Box>
  );
};
