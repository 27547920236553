import { AdminUserFindingParams } from "../hooks/queries/findingContext";

export type Finding = {
  affected_assets: number[];
  affected_assets_displayed: string[];
  attachments: string[];
  attack_details?: string | undefined | null;
  attack_details_html?: string | undefined | null;
  attack_details_wasp?: string | undefined | null;
  comment?: string | undefined | null; // REMOVE ???
  comments_count?: number | undefined | null;
  created_at: string;
  customer: number;
  customer_jira_issue_key?: string | undefined | null;
  cwe_id?: number | undefined | null;
  cvss_score?: number | undefined | null;
  cvss_vector?: string | undefined | null;
  date_found?: string | undefined | null; // REMOVE ???
  description?: string | undefined | null;
  description_html?: string | undefined | null;
  description_wasp?: string | undefined | null;
  exploitability?: number | undefined | null;
  fix_assesment?: number | undefined | null;
  id: number;
  impact?: number | undefined | null;
  is_automated?: boolean | undefined | null;
  is_false_positive?: boolean | undefined | null;
  is_merged?: boolean | undefined | null;
  jira_created?: string;
  jira_key?: string;
  jira_updated?: string;
  labels?: string[] | undefined | null;
  mitigation?: string | undefined | null;
  mitigation_html?: string | undefined | null;
  mitigation_wasp?: string | undefined | null;
  mitigation_complexity?: number | undefined | null;
  mitigation_cost?: number | undefined | null;
  overall_risk: number;
  platform?: string | undefined | null;
  assignee?: string | undefined | null;
  products: number[];
  project: number;
  reaccuring: number;
  status: FindingStatus;
  summary?: string | undefined | null;
  title: string;
  sla_expires_at?: string | undefined | null;
  updated_at: string;
  customer_jira_assignee?: JiraUser;
  op_jira_assignee?: JiraUser;
  cve_ids?: string[];
  is_pending?: boolean;
  scan_id?: string;
  retest_not_found?: boolean;
  scanner_name?: string | null;
  source?: "WASP_TEAM" | "CUSTOMER_INSERTED";
};

export const STATUSES = [
  "presented",
  "in_progress",
  "re_test",
  "acceptable_risk",
  "fixed",
  "dismissed",
] as const;
export type FindingStatus = (typeof STATUSES)[number];

export const STATUSES_DESCRIPTIONS: { [key in FindingStatus]: string } = {
  presented: "The finding is presented",
  in_progress: "The finding is being worked on",
  re_test: "The finding issue has been fixed and is waiting for re-test",
  dismissed: "The finding is dismissed",
  acceptable_risk: "The finding is acceptable risk",
  fixed: "The finding issue has been fixed",
};

export type Attachment = {
  attachment_name: string;
  attachment_data: string; // Base64 string
};

export type FindingPage = {
  results: Finding[] | null | undefined;
  count: number | null | undefined;
  next: string | null | undefined;
  previous: string | null | undefined;
};

export type FindingsCounts = {
  total: number;
  open: number;
  fixed: number;
  presented: number;
  re_test: number;
  breached_sla: number;
  open_criticals: number;
  open_highs: number;
  open_mediums: number;
  open_lows: number;
  urgent: number;
  recently_fixed: number;
  open_infos?: number | undefined | null;
};

export type SeverityStats = {
  4: number;
  3: number;
  2: number;
  1: number;
  0: number;
};

export type FindingsFunnelStatistics = {
  all: SeverityStats;
  valid: SeverityStats;
  open: SeverityStats;
  breached_sla: SeverityStats;
};

export type FindingsOverTimeStatsItem = {
  year: number;
  month: number;
  day?: number;
  count: number;
  overall_risk: number;
};

export type JiraUser = {
  jira_id: string;
  display_name: string;
  email: string;
  avatar_url: string;
};

export const DefaultFindingsCounts: FindingsCounts = {
  total: 0,
  open: 0,
  fixed: 0,
  presented: 0,
  re_test: 0,
  breached_sla: 0,
  open_criticals: 0,
  open_highs: 0,
  open_mediums: 0,
  open_lows: 0,
  urgent: 0,
  recently_fixed: 0,
  open_infos: 0,
};

export interface FindingEdit {
  status?: FindingStatus;
  comment?: string;
  assignee?: JiraUser;
  mitigation_wasp?: string | undefined | null;
  description_wasp?: string | undefined | null;
  attack_details_wasp?: string | undefined | null;
  mitigation?: string | undefined | null;
  description?: string | undefined | null;
  attack_details?: string | undefined | null;
  attachments?: string[];
}

export interface AdminFindingEdit {
  affected_assets?: number[];
  affected_assets_displayed?: string[];
  attachments?: string[];
  attack_details?: string | undefined | null;
  attack_details_html?: string | undefined | null;
  attack_details_wasp?: string | undefined | null;
  customer?: number;
  cwe_id?: number | undefined | null;
  cvss_score?: number | undefined | null;
  cvss_vector?: string | undefined | null;
  description?: string | undefined | null;
  description_html?: string | undefined | null;
  description_wasp?: string | undefined | null;
  exploitability?: number | undefined | null;
  impact?: number | undefined | null;
  is_automated?: boolean | undefined | null;
  labels?: string[] | undefined | null;
  mitigation?: string | undefined | null;
  mitigation_html?: string | undefined | null;
  mitigation_wasp?: string | undefined | null;
  mitigation_complexity?: number | undefined | null;
  mitigation_cost?: number | undefined | null;
  overall_risk?: number;
  products?: number[];
  project?: number;
  status?: FindingStatus;
  summary?: string | undefined | null;
  title?: string;
  op_jira_assignee?: JiraUser;
  cve_ids?: string[];
  is_pending?: boolean;
  is_false_positive?: boolean | undefined | null;
  source?: "WASP_TEAM" | "CUSTOMER_INSERTED";
  onSuccessCallback?: (data: Finding) => void;
  onErrorCallback?: (error: Error) => void;
}

export type BulkUpdateFindings = {
  all: boolean;
  findings: number[];
  updates: FindingEdit;
};

export const emptyFinding: AdminUserFindingParams = {
  customer: 0,
  cvss_score: 0,
  affected_assets: [],
  affected_assets_displayed: [],
  attachments: [],
  created_at: "",
  overall_risk: -1,
  products: [],
  project: 0,
  reaccuring: 0,
  status: "presented",
  title: "",
  is_pending: true,
  is_automated: false,
  is_false_positive: false,
  source: "WASP_TEAM",
};

export interface FindingImage {
  image_name: string;
}

export type FindingLoadingMap = {
  affected_assets: boolean;
  attachments: boolean;
  attack_details: boolean;
  cwe_id: boolean;
  cvss_score: boolean;
  cvss_vector: boolean;
  description: boolean;
  exploitability: boolean;
  impact: boolean;
  is_automated: boolean;
  is_false_positive: boolean;
  labels: boolean;
  mitigation: boolean;
  mitigation_complexity: boolean;
  mitigation_cost: boolean;
  overall_risk: boolean;
  products: boolean;
  project: boolean;
  status: boolean;
  summary: boolean;
  title: boolean;
  op_jira_assignee: boolean;
  cve_ids: boolean;
  is_pending: boolean;
};

export const initialFindingLoadingMap: FindingLoadingMap = {
  affected_assets: false,
  attachments: false,
  attack_details: false,
  cwe_id: false,
  cvss_score: false,
  cvss_vector: false,
  description: false,
  exploitability: false,
  impact: false,
  is_automated: false,
  is_false_positive: false,
  labels: false,
  mitigation: false,
  mitigation_complexity: false,
  mitigation_cost: false,
  overall_risk: false,
  products: false,
  project: false,
  status: false,
  summary: false,
  title: false,
  op_jira_assignee: false,
  cve_ids: false,
  is_pending: false,
};

export const FINDING_TYPES = ["asm", "pt"] as const;
export type FindingType = (typeof FINDING_TYPES)[number];
