import React from "react";
import { Assignee } from "../../../../components/composed/assignee/Assignee";
import { Finding } from "../../../../types/Finding";
import { Section } from "../../../../components/elements/section/Section";
import { Flex } from "../../../../components/layouts/flex/Flex";

type Props = {
  finding: Finding | undefined | null;
  noSeparator?: boolean;
  width?: string;
};

export const AssignedToItem = (props: Props) => {
  const { finding, noSeparator = false } = props;
  return (
    <Section title="Assigned To" noSeparator={noSeparator}>
      <Flex className="text-truncate w-100" style={{ position: "relative" }}>
        <Assignee
          name={finding?.customer_jira_assignee?.display_name}
          imageSrc={finding?.customer_jira_assignee?.avatar_url}
          findingId={finding?.id}
          isEditable={false}
          isDisabled={!finding?.customer_jira_issue_key}
        />
      </Flex>
    </Section>
  );
};
