import styled, { ThemeContext } from "styled-components";
import { Icon } from "../../icon/Icon";
import { useContext, useEffect, useRef, useState } from "react";
import { Flex } from "../../../layouts/flex/Flex";
import { IconButton } from "../../button/icon/IconButton";
import useToastContext from "../../../../hooks/toastHook";

export type TextInputProps = {
  disabled?: boolean;
  copyToClipboard?: boolean;
  saveChangesMode?: boolean;
  isError?: boolean;
  iconName?: string;
  inverted?: boolean;
  width?: string | number;
  border?: string;
  color?: string;
  autoFocus?: boolean;
  onChange?: (e: any) => any;
  onSave?: () => void;
  onCancel?: () => void;
  onBlur?: () => void;
  type?: string;
  placeholder?: string;
  value?: string;
  dataTestId?: string;
  className?: string;
  style?: any;
  name?: string;
  isPassword?: boolean;
  isClearable?: boolean;
  onClear?: () => void | undefined;
  onEnterPressed?: () => void | undefined;
};

const InputTextStyle = styled.input<TextInputProps>`
  font-size: 14px;
  light-height: 21px;
  color: ${(props) =>
    props.isError
      ? props.theme.redPrimary
      : props.inverted
      ? props.theme.textWhite
      : props.color || props.theme.textBody};
  border: ${(props) =>
    props.isError
      ? `1px solid ${props.theme.redPrimary}`
      : props.border
      ? props.border
      : `1px solid ${
          props.inverted ? props.theme.white30 : props.theme.black500
        }`};
  height: 40px;
  border-radius: 0.6em;
  padding: 8px 16px;
  background-color: inherit;
  ${(props) =>
    props.iconName &&
    `padding-left: 40px;
    background-size: 14px;
    `}
  &:hover:enabled {
    border-color: ${(props) =>
      props.inverted ? props.theme.white80 : props.theme.primary};
  }
  &:focus {
    outline: none;
    border-color: ${(props) =>
      props.inverted ? props.theme.white100 : props.theme.primary};

    color: ${(props) =>
      props.inverted ? props.theme.white100 : props.theme.textHeader};
  }
  &:disabled {
    background-color: ${(props) =>
      props.inverted ? "inherit" : props.theme.separation};
    opacity: ${(props) => (props.inverted ? 0.5 : 1)};
  }
  &::placeholder {
    color: ${(props) =>
      props.inverted ? props.theme.white80 : props.theme.textSub};
  }
`;

export const InputText = (props: TextInputProps) => {
  const {
    onChange,
    saveChangesMode,
    onCancel,
    onSave,
    onBlur,
    value,
    iconName,
    inverted,
    dataTestId,
    type = "",
    isPassword = false,
    copyToClipboard,
    isClearable,
    onClear,
    width,
    onEnterPressed,
  } = props;

  const theme = useContext(ThemeContext);
  const addToast = useToastContext();

  const [isAstrixed, setIsAstrixed] = useState(!!isPassword);
  const [text, setText] = useState<string>(value || "");
  const [isFocused, setFocused] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleBlur = () => {
    // Delay the blur handling to check the newly focused element
    setTimeout(() => {
      // Check if the new active element is outside the component
      if (
        containerRef.current &&
        !containerRef.current.contains(document.activeElement)
      ) {
        setFocused(false);
        if (onBlur) onBlur();
      }
    }, 0);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter" && onEnterPressed) {
      onEnterPressed();
    }
  };

  useEffect(() => {
    setText(value || "");
  }, [value]);

  const copyValueToClipboard = () =>
    value &&
    navigator.clipboard
      .writeText(value)
      .then(() =>
        addToast({
          message: "Token copied to clipboard",
          type: "success",
        })
      )
      .catch((err) =>
        addToast({
          message: `Failed to copy token: ${err}`,
          type: "error",
        })
      );

  return (
    <Flex
      align="center"
      style={{ position: "relative", width: width || "" }}
      gap="8px"
      ref={containerRef}
    >
      {iconName && (
        <span style={{ position: "absolute", left: "12px" }}>
          <Icon
            name={iconName}
            color={inverted ? theme.textWhite : theme.textSecondary}
          />
        </span>
      )}
      <InputTextStyle
        {...props}
        data-testid={dataTestId}
        type={isAstrixed ? "password" : type}
        value={text}
        onChange={(e) => {
          onChange && onChange(e);
          setText(e.target.value);
        }}
        style={{ width: width, ...props.style }}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      />
      {isPassword && (
        <IconButton
          size="small"
          onClick={() => setIsAstrixed((prev) => !prev)}
          iconName={isAstrixed ? "eye" : "eyeSlash"}
        />
      )}
      {copyToClipboard && (
        <IconButton
          style={{ position: "absolute", top: "5px", right: "12px" }}
          iconName="copy"
          color={theme.black800}
          size="small"
          onClick={copyValueToClipboard}
        />
      )}
      {saveChangesMode && isFocused && (
        <>
          <IconButton
            style={{ position: "absolute", top: "5px", right: "55px" }}
            iconName="send"
            color={theme.primary}
            size="small"
            onClick={() => {
              if (onSave) onSave();
              setFocused(false);
            }}
          />
          <IconButton
            style={{ position: "absolute", top: "5px", right: "20px" }}
            iconName="cancel"
            color={theme.primary}
            size="small"
            onClick={() => {
              if (onCancel) onCancel();
              setFocused(false);
            }}
          />
        </>
      )}
      {isClearable && (
        <IconButton
          disabled={!text}
          dataTestId={`${dataTestId}-clear`}
          style={{ position: "absolute", top: "5px", right: "12px" }}
          iconName="cancel"
          // color={theme.textSecondary}
          size="small"
          onClick={() => {
            onClear && onClear();
            setText("");
          }}
        />
      )}
    </Flex>
  );
};
