import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import { Loading } from "../../../components/elements/loading/Loading";
import { Flex } from "../../../components/layouts/flex/Flex";
import { AssigneeLabelStyle } from "../../../components/composed/assignee/AssigneeStyles";
import { PushFindingJiraMenu } from "./PushFindingJiraMenu";
import { BodyRegular } from "../../../components/elements/typography/Typography";
import { Finding } from "../../../types/Finding";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";

type Props = {
  jiraIssueKey?: string;
  customerJiraUrl?: string;
  isDisabled?: boolean;
  finding: Finding | undefined;
  fontSize?: string;
  placeholder?: string;
  buttonStyle?: "secondary" | "label";
};

export const PushFindingJira = (props: Props) => {
  const {
    jiraIssueKey,
    customerJiraUrl,
    finding,
    fontSize,
    isDisabled = false,
    placeholder = "Unassigned",
    buttonStyle = "label",
  } = props;
  const theme = useContext(ThemeContext);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [customerJiraIssueLink, setCustomerJiraIssueLink] = useState<
    string | null
  >(null);

  useEffect(() => {
    // The real issue link is set in the parent component, it might take some time to load, so we need to wait for it
    setCustomerJiraIssueLink(
      jiraIssueKey && customerJiraUrl
        ? `${customerJiraUrl}/browse/${jiraIssueKey}`
        : null
    );
  }, [customerJiraUrl, jiraIssueKey]);

  return (
    <Flex
      align="center"
      gap="8px"
      style={{
        textOverflow: "ellipsis",
        overflow: "inherit",
        position: "relative",
      }}
      className="px-1"
      title={jiraIssueKey || "Sync Jira Issue"}
    >
      {isUpdating ? (
        <Loading />
      ) : customerJiraIssueLink ? (
        <BodyRegular className="text-truncate">
          <a
            href={customerJiraIssueLink || ""}
            target="_blank"
            rel="noreferrer"
            style={{
              // remove link underline
              textDecoration: "none",
              color: theme.primary,
            }}
          >
            {jiraIssueKey || "Sync Jira Issue"}
          </a>
        </BodyRegular>
      ) : buttonStyle === "secondary" ? (
        <SecondaryButton
          size="small"
          label={jiraIssueKey || "Sync Jira Issue"}
          iconName="export"
          disabled={isDisabled}
          onClick={() => (!isDisabled ? setShowMenu((state) => !state) : {})}
        />
      ) : (
        <AssigneeLabelStyle
          className="text-truncate"
          title={jiraIssueKey || "Sync Jira Issue"}
          isEditable={!isDisabled}
          style={{
            color: isDisabled
              ? theme.black600
              : showMenu
                ? theme.primary
                : theme.black800,
            fontWeight: showMenu ? 500 : "",
            cursor: !isDisabled ? "pointer" : "default",
            fontSize: fontSize || "14px",
          }}
          onClick={() => (!isDisabled ? setShowMenu((state) => !state) : {})}
        >
          {jiraIssueKey || placeholder}
        </AssigneeLabelStyle>
      )}

      {showMenu && !isDisabled && finding && (
        <Flex style={{ position: "absolute", left: "-170px", top: "40px" }}>
          <PushFindingJiraMenu
            onClose={() => setShowMenu(false)}
            findings={[finding]}
            setIsUpdating={setIsUpdating}
          />
        </Flex>
      )}
    </Flex>
  );
};
