import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Icon } from "../../../components/elements/icon/Icon";
import {
  BodyRegular,
  HeaderSecondary,
  KPISmall,
} from "../../../components/elements/typography/Typography";
import { FindingsCounts } from "../../../types/Finding";
import { StatusCounter, TopBox, TopBoxTitle } from "../FindingsStyles";
import { objectToBase64 } from "../../../shared/helper";
import { Link } from "react-router-dom";
import { Flex } from "../../../components/layouts/flex/Flex";
import { findingsDefaultFilters } from "../Findings";
import { Mixpanel } from "../../../shared/mixpanel";

const StatusCard = ({
  status,
  count,
  dataTestId,
  onClick,
  linkTo,
  isFetching,
}: {
  status: { displayName: string; key: string };
  count: number | undefined | null;
  dataTestId: string;
  onClick: () => void;
  linkTo: string;
  isFetching: boolean;
}) => {
  const theme = useContext(ThemeContext);

  return (
    <StatusCounter
      className={`d-flex flex-column justify-content-between  gap-8 flex-wrap align-items-between w-100`}
      onClick={onClick}
      style={{ overflow: "auto" }}
    >
      <Link to={linkTo} style={{ textDecoration: "none" }} className="w-100">
        <Flex
          w100
          align="center"
          justify="between"
          gap="8px"
          style={{ overflow: "auto" }}
        >
          <KPISmall
            style={{
              color: isFetching ? theme.textSub : theme.textSecondary,
            }}
            data-testid={dataTestId}
          >
            {isFetching ? 0 : count}
          </KPISmall>
          <Icon name={status.key} size={32} color={theme.primary} />
        </Flex>
        <BodyRegular
          style={{ color: theme.textSecondary }}
          className="d-block text-truncate w-100"
          title={status.displayName}
        >
          {status.displayName}
        </BodyRegular>
      </Link>
    </StatusCounter>
  );
};

const FindingsStatus = ({
  findingsCounts,
  isFetching,
}: {
  findingsCounts: FindingsCounts | undefined;
  isFetching?: boolean;
}) => {
  const theme = useContext(ThemeContext);

  return (
    <TopBox>
      <TopBoxTitle>
        <div className="d-flex align-items-center gap-8">
          <Icon name="insightsOutline" size={32} color={theme.primary} />
          <HeaderSecondary>Findings Status</HeaderSecondary>
        </div>
      </TopBoxTitle>
      <div className="d-flex justify-content-between align-items-stretch gap-24 w-100">
        {[
          { displayName: "Presented", key: "presented" },
          { displayName: "In Progress", key: "in_progress" },
          { displayName: "Ready for Re-Test", key: "re_test" },
          { displayName: "Fixed", key: "fixed" },
        ].map((status, idx) => (
          <StatusCard
            dataTestId={`${status.key}-status-card`}
            key={idx}
            status={status}
            count={
              findingsCounts &&
              findingsCounts[status.key as keyof FindingsCounts]
            }
            linkTo={`/findings?filters=${objectToBase64({
              ...findingsDefaultFilters,
              status: status.key,
            })}`}
            onClick={() => {
              Mixpanel.track("Findings Status", { status: status.displayName });
            }}
            isFetching={!!isFetching}
          />
        ))}
      </div>
    </TopBox>
  );
};

export default FindingsStatus;
