import { PrimaryBadge } from "../../../../components/elements/badge/PrimaryBadge";
import { LabelRegular } from "../../../../components/elements/typography/Typography";
import { Finding } from "../../../../types/Finding";
import { Product } from "../../../../types/Product";
import { light } from "../../../../shared/theme";
import { Flex } from "../../../../components/layouts/flex/Flex";

type Props = {
  finding: Finding | undefined | null;
  products: Product[] | undefined | null;
  isReport?: boolean;
};

export const AffectedProductsList = (props: Props) => {
  const { finding, products, isReport = false } = props;

  return (
    <Flex column gap="8px">
      <LabelRegular color={isReport ? light.black700 : ""}>
        Affected Products
      </LabelRegular>
      <Flex flexWrap gap="8">
        {finding?.products?.map((product, index) => (
          <PrimaryBadge
            content={products?.find((p) => p.id === product)?.name || "N/A"}
            key={`product-${index}`}
          />
        ))}
      </Flex>
    </Flex>
  );
};
