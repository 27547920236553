import { SingleValue } from "react-select";
import {
  Dropdown,
  Option,
} from "../../../components/elements/dropdowns/Dropdown";
import { OpUser, useApiSuperUsers } from "../../../hooks/queries/usersContext";
import { useState } from "react";

type Props = {
  onSelect: (opUser: OpUser | undefined) => void;
  placeholder?: string;
  valueByEmail?: string;
  size?: "medium" | "large" | "small";
  isClearable?: boolean;
};
export const SuperUsersDropdown = (props: Props) => {
  const {
    onSelect,
    placeholder = "",
    valueByEmail,
    size,
    isClearable = false,
  } = props;
  const [searchWord, setSearchWord] = useState("");
  const { data: users } = useApiSuperUsers({ search: searchWord });

  const options: SingleValue<Option>[] =
    users?.map((u) => ({
      label: `${u.first_name} ${u.last_name}`,
      value: `${u.email}`,
    })) || [];

  const onChange = (opt: SingleValue<Option>) => {
    setSelectedUserOption(opt);
    const user = users?.find((u) => u.email === opt?.value);
    onSelect(user);
  };

  const getOptionByEmail = (email: string) =>
    options?.filter((opt) => opt?.value === email)[0];

  const [selectedUserOption, setSelectedUserOption] = useState<
    SingleValue<Option>
  >(valueByEmail ? getOptionByEmail(valueByEmail) : null);

  return (
    <Dropdown
      size={size}
      variant="border"
      placeholder={placeholder}
      value={selectedUserOption}
      options={(options as Option[]) || []}
      onChange={onChange}
      onInputChange={(newValue) => setSearchWord(newValue)}
      searchable
      clearable={isClearable}
    />
  );
};
