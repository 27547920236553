import { useContext } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ThemeContext } from "styled-components";
import { Menu, MenuBackground } from "../../elements/menu/Menu";
import { MenuCategory } from "../../elements/menu/MenuCategory";
import { MenuItem } from "../../elements/menu/MenuItem";
import {
  GlobalSearchData,
  GlobalSearchDataItem,
} from "../../../types/GlobalSearchData";
import { Loading } from "../../elements/loading/Loading";
import { BodyBold } from "../../elements/typography/Typography";

type Props = {
  globalSearchData: GlobalSearchData;
  isFetching: boolean;
  onClose: () => void;
};

export const GlobalSearchResultsMenu = (props: Props) => {
  const { globalSearchData, isFetching, onClose: close } = props;

  const theme = useContext(ThemeContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();

  const getItemLink = (dataKey: keyof GlobalSearchData, itemId: number) => {
    switch (dataKey) {
      case "assets":
        return `/assets?assetId=${itemId}`;
      case "findings":
        return `/finding-details/${itemId}`;
      case "products":
        return `/?productId=${itemId}`;
      case "projects":
        return `/?projectId=${itemId}`;
    }
  };

  const thereIsNoData =
    !globalSearchData?.assets?.length &&
    !globalSearchData?.findings?.length &&
    !globalSearchData?.products?.length &&
    !globalSearchData?.projects?.length;

  return (
    <>
      <MenuBackground onClick={close} />
      <Menu style={{ top: "45px", width: "260px" }}>
        <div
          className="d-flex flex-column gap-4 w-100"
          style={{ maxHeight: "360px", overflowY: "scroll" }}
        >
          {isFetching && <Loading />}
          {thereIsNoData && !isFetching && (
            <BodyBold style={{ color: theme.black600 }}>
              No matching results were found
            </BodyBold>
          )}

          {!thereIsNoData &&
            Object.keys(globalSearchData)
              .filter(
                (key) =>
                  globalSearchData[key as keyof GlobalSearchData].length > 0
              )
              .map((key: string, index: number) => (
                <MenuCategory
                  title={key}
                  key={`search-category-${key}-${index}`}
                >
                  {globalSearchData[key as keyof GlobalSearchData].map(
                    (item: GlobalSearchDataItem) => (
                      <Link
                        style={{ textDecoration: "none", width: "100%" }}
                        key={`search-category-item-${item.id}-${key}-${index}`}
                        to={`${getItemLink(
                          key as keyof GlobalSearchData,
                          item.id
                        )}`}
                        onClick={() => {
                          // Handle opening asset pane when already on assets page
                          if (
                            location.pathname === "/assets" &&
                            key === "assets"
                          ) {
                            setSearchParams((prev) => ({
                              ...prev,
                              assetId: item.id,
                            }));
                            navigate(0);
                          }
                        }}
                      >
                        <MenuItem
                          style={{
                            display: "block",
                            width: "200px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          label={item.name}
                          onClick={close}
                        />
                      </Link>
                    )
                  )}
                </MenuCategory>
              ))}
        </div>
      </Menu>
    </>
  );
};
