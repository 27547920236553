import { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { Badge } from "../../../components/elements/badge/Badge";
import { BadgesLine } from "../../../components/elements/badge/BadgesLine";
import { Box } from "../../../components/elements/box/Box";
import { Section } from "../../../components/elements/section/Section";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import {
  BodyBold,
  BodyRegular,
  LabelMedium,
} from "../../../components/elements/typography/Typography";
import { getDateTime } from "../../../shared/helper";
import { Asset } from "../../../types/Asset";
import { Flex } from "../../../components/layouts/flex/Flex";
import { BASE_API_URL } from "../../../hooks/queries/utils";

type Props = {
  asset: Asset | undefined;
};

export const WebDetailsBox = (props: Props) => {
  const { asset } = props;
  const theme = useContext(ThemeContext);
  const badgeStyle = {
    backgroundColor: theme.blue100,
    color: theme.blue700,
  };

  const [isImageError, setIsImageError] = useState<boolean>(false);

  return (
    <Flex column gap="16px">
      <Box className="d-flex flex-column gap-24">
        <Flex align="center" justify="around" className="mt-2">
          <Section title="Webpage Title">
            <BodyRegular>
              {asset?.properties?.webpage_title || "N/A"}
            </BodyRegular>
          </Section>
          <Section title="Webserver">
            <BodyRegular>{asset?.properties?.webserver || "N/A"}</BodyRegular>
          </Section>
          <Section title="Status Code" noSeparator>
            <BodyRegular>{asset?.properties?.status_code || "N/A"}</BodyRegular>
          </Section>
        </Flex>
        <Flex align="center" justify="around" className="mt-2">
          <Section title="Cname records">
            <div className="d-flex flex-wrap gap-6 align-items-center">
              {asset?.properties?.cnames?.map((cn) => (
                <Badge
                  className="d-flex"
                  key={`cn-${cn}`}
                  style={{
                    maxWidth: "250px",
                    color: theme.blue700,
                    backgroundColor: theme.blue100,
                  }}
                >
                  <LabelMedium className="text-truncate" title={cn}>
                    {cn}
                  </LabelMedium>
                </Badge>
              )) || <BodyRegular>N/A</BodyRegular>}
            </div>
          </Section>
          <Section title="Open Ports">
            <div className="d-flex flex-wrap gap-6 align-items-center">
              {asset?.ports_data?.map((portData) => (
                <Tooltip
                  placement="right"
                  key={`portData-${portData.port_number}`}
                  content={
                    <Flex column gap="8px">
                      <Flex align="center" gap="4px">
                        <BodyRegular>Is Open:</BodyRegular>
                        <BodyBold>{portData.is_open ? "Yes" : "No"}</BodyBold>
                      </Flex>
                      {!portData.is_open && (
                        <Flex align="center" gap="4px">
                          <BodyRegular>Closed at:</BodyRegular>
                          <BodyBold>
                            {getDateTime(new Date(portData.closed_at || 0)) ||
                              "N/A"}
                          </BodyBold>
                        </Flex>
                      )}
                    </Flex>
                  }
                >
                  <Badge style={badgeStyle}>{portData.port_number}</Badge>
                </Tooltip>
              )) || <BodyRegular>N/A</BodyRegular>}
            </div>
          </Section>
          <Section title="Resolved IP’s" noSeparator>
            <div className="d-flex flex-wrap gap-6 align-items-center">
              <BadgesLine
                badges={asset?.properties?.ip || []}
                variant="primary"
                noWrap={false}
                gap={4}
              />
            </div>
          </Section>
        </Flex>

        <Flex align="center" justify="around" className="mt-2">
          <Section title="registration date">
            <BodyRegular>
              {asset?.registered_at ? getDateTime(asset.registered_at) : "N/A"}
            </BodyRegular>
          </Section>
          <Section title="">
            <></>
          </Section>
          <Section title="last seen">
            <BodyRegular>
              {asset?.last_seen ? getDateTime(asset.last_seen) : "N/A"}
            </BodyRegular>
          </Section>
        </Flex>

        {!isImageError && (
          <Section title="Asset Reference">
            <img
              src={`${BASE_API_URL}/assets/${asset?.id}/screenshot`}
              alt="asset screenshot"
              width={"100%"}
              loading="lazy"
              // @ts-ignore
              onError={(e) => setIsImageError(true)}
            />
          </Section>
        )}
      </Box>
    </Flex>
  );
};
