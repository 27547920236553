import { useContext, useState } from "react";
import { SingleValue, components } from "react-select";
import { ThemeContext } from "styled-components";
import { IconButton } from "../../elements/button/icon/IconButton";
import { LabelMedium } from "../../elements/typography/Typography";
import { Dropdown, Option } from "../../elements/dropdowns/Dropdown";

export const TagDropdown = ({
  value,
  options,
  onChange,
  onCancel,
  dataTestId,
  placeholder,
}: {
  value?: SingleValue<Option>;
  options?: Option[];
  onChange: (option: SingleValue<Option>) => void;
  onCancel?: () => void;
  dataTestId?: string;
  placeholder?: string;
}) => {
  const theme = useContext(ThemeContext);
  const [selectedOption, setSelectedOption] = useState<SingleValue<Option>>(
    value
      ? {
          value: `${value.value}`,
          label: `${value.label}`,
        }
      : null
  );

  const selectStyles = {
    control: () => ({ display: "flex" }),
    indicatorSeparator: () => ({ display: "none" }),
    dropdownIndicator: () => ({ display: "none" }),
    input: (base: any) => ({
      ...base,
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "18px",
      textAlign: "center",
      color: theme.blue700,
    }),
    menu: (base: any) => ({
      ...base,
      minWidth: "150px",
    }),
  };

  const SingleValue = (props: any) => (
    <components.SingleValue {...props}>
      <div
        data-testid={dataTestId}
        className="d-flex align-items-center dropdown-container"
        style={{ color: theme.blue700, fontSize: "12px" }}
      >
        {/* {children} */}
        {props.children}
      </div>
    </components.SingleValue>
  );

  return (
    <div className="d-inline-flex">
      <LabelMedium
        style={{
          background: theme.blue100,
          border: "1px solid " + theme.blue700,
          borderRadius: "24px",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "space-between",
          minHeight: "24px",
          padding: "0px 4px",
          minWidth: "115px",
        }}
      >
        <Dropdown
          options={options || []}
          value={selectedOption}
          styles={selectStyles}
          onChange={(option) => {
            onChange(option);
            setSelectedOption(option);
          }}
          creatable
          customComponents={{ SingleValue }}
          placeholder={placeholder}
        />
        <IconButton
          iconName="cancel"
          size="very-small"
          color={theme.blue700}
          onClick={() => {
            setSelectedOption(null);
            !!onCancel && onCancel();
          }}
        />
      </LabelMedium>
    </div>
  );
};
