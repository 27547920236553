export type NotificationEvent = {
  id: number;
  customer: number;
  event_type: NotificationEventType;
  event_details: { [key: string]: any };
  created_at: string;
};

export type NotificationReceiver = {
  id: number;
  account_id: number;
  notification_event: NotificationEvent;
  is_read: boolean;
};

export type NotificationEventType =
  | "FINDING_CREATED"
  | "FINDING_DELETED"
  | "FINDING_UPDATED"
  | "COMMENT_CREATED"
  | "COMMENT_MENTION"
  | "SENT_TO_JIRA"
  | "BREACHED_SLA";

export type NotificationReceiverPage = {
  results: NotificationReceiver[] | null | undefined;
  count: number | null | undefined;
  next: string | null | undefined;
  previous: string | null | undefined;
};

export type NotificationChannel = "email" | "inApp" | "slack";

export interface NotificationSettings {
  [index: string]: boolean | number | undefined;
  is_disabled?: boolean;
  on_new_finding?: boolean;
  finding_severity?: number;
  on_new_auto_finding?: boolean;
  auto_finding_severity?: number;
  on_new_comment?: boolean;
  on_comment_mention?: boolean;
  on_status_change?: boolean;
  on_new_asset?: boolean;
  weekly?: boolean;
  monthly?: boolean;
}

export const defaultEmailNotificationSettings: NotificationSettings = {
  is_disabled: false,
  on_new_finding: true,
  finding_severity: 3,
  on_new_auto_finding: true,
  auto_finding_severity: 3,
  on_new_comment: true,
  on_status_change: true,
  on_new_asset: true,
  on_comment_mention: true,
  on_new_intelligence_feed_item: true,
  weekly: true,
  monthly: true,
};

export const defaultInAppNotificationSettings: NotificationSettings = {
  is_disabled: false,
  on_new_finding: true,
  finding_severity: 0,
  on_new_auto_finding: true,
  auto_finding_severity: 0,
  on_new_comment: true,
  on_status_change: true,
  on_new_asset: true,
  on_comment_mention: true,
};
