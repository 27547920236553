import { useContext, useEffect, useState } from "react";
import { Flex } from "../../components/layouts/flex/Flex";
import { Icon } from "../../components/elements/icon/Icon";
import {
  HeaderSecondary,
  LabelMedium,
} from "../../components/elements/typography/Typography";
import { ThemeContext } from "styled-components";

import { ASMUpdatesCurrentStatus } from "./ASMUpdatesCurrentStatus";
import { ASMUpdatesTimeline } from "./ASMUpdatesTimeline";
import { useApiScanEventsPaging } from "../../hooks/queries/scansContext";
import { useApiMe } from "../../hooks/queries/meContext";
import { SkeletonLoading } from "../../components/elements/loading/SkeletonLoading";
import { RunningBadge } from "../../components/composed/badegs/RunningBadge";
import { PausedBadge } from "../../components/composed/badegs/PausedBadge";
import { TurnOnASM } from "./TurnOnASM";
import { Switch } from "../../components/elements/switch/Switch";
import { Mixpanel } from "../../shared/mixpanel";
import { useScreenWidth } from "../../hooks/utilsHooks";
import { SCREEN_MEDIUM_LAPTOP_WIDTH } from "../../shared/consts";

export const VulnerabilityScanners = [
  "nessus",
  "nuclei",
  "zap",
  "stmo",
  "dns_reaper",
  "wpscan",
  "leakage",
  "dalfox",
];

type Props = {
  hide?: boolean;
  setHide?: (show: boolean) => void;
};

export const ASMUpdates = ({ hide = false, setHide }: Props) => {
  const theme = useContext(ThemeContext);

  const screenWidth = useScreenWidth();
  const isLaptop = screenWidth < SCREEN_MEDIUM_LAPTOP_WIDTH;

  const element = document.querySelector("#top-right-box");
  const containerHeight = element?.clientHeight || 0;

  const [runningScanners, setRunningScanEvents] = useState<number>(0);
  const { data: me } = useApiMe();

  const { data: scanEventsPages, isFetching: isFetchingScanEvents } =
    useApiScanEventsPaging({ customer_id: me?.customer?.id, page_size: 50 });

  useEffect(() => {
    if (!scanEventsPages) return;
    const scanEvents = scanEventsPages?.pages
      ?.map((p) => p.results || [])
      ?.flat();
    const runningScanEvents = scanEvents.filter(
      (scanEvents) =>
        scanEvents.status === "running" &&
        VulnerabilityScanners.includes(scanEvents.scanner)
    );
    setRunningScanEvents(runningScanEvents.length);
  }, [scanEventsPages]);

  return (
    <Flex column gap="16px">
      <Flex column gap="8px">
        <Flex align="center" justify="between" gap="8px" flexWrap>
          <Flex align="center" gap="4px">
            <Icon name="asm" size={32} color={theme.primary} />
            <HeaderSecondary>ASM Updates</HeaderSecondary>
          </Flex>
          {me && !me.customer.is_asm_enabled ? (
            <PausedBadge />
          ) : (
            runningScanners > 0 && <RunningBadge />
          )}
        </Flex>
        <Flex gap="4px" align="center">
          <Switch
            checked={hide}
            onChange={(checked) => {
              !!setHide && setHide(checked);
              Mixpanel.track("Dashboard right widget choose", {
                widget: checked ? "Latest Findings" : "ASM Updates",
              });
            }}
          />
          <LabelMedium>Show Latest Findings</LabelMedium>
        </Flex>
      </Flex>

      <Flex
        column
        gap="8px"
        style={{
          height: isLaptop ? "1200px" : `${containerHeight - 120}px`,
          overflowY: "scroll",
        }}
      >
        {me && !me.customer.is_asm_enabled && (
          <Flex>
            <TurnOnASM />
          </Flex>
        )}

        {isFetchingScanEvents ? (
          <SkeletonLoading width="100%" height="100px" />
        ) : (
          <ASMUpdatesCurrentStatus
            runningVulnerabilitiesScanners={runningScanners}
          />
        )}
        <Flex column>
          <ASMUpdatesTimeline />
        </Flex>
      </Flex>
    </Flex>
  );
};
