import React, { Dispatch, SetStateAction, useContext, useRef } from "react";
import { Box } from "../../../../components/elements/box/Box";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { Icon } from "../../../../components/elements/icon/Icon";
import { HeaderSecondary } from "../../../../components/elements/typography/Typography";
import { AttachmentTag } from "../../../../components/composed/findingAttachments/AttachmentTag";
import { LinkButton } from "../../../../components/elements/button/link/LinkButton";
import { ThemeContext } from "styled-components";
import { Attachment } from "../../../../types/Finding";
import {
  base64ToFileAndDownload,
  blobToFileAndDownload,
  fileToBase64,
} from "../AttachmentUtils";
import {
  useApiCreateFindingAttachments,
  useApiDownloadSingleFindingAttachment,
  useApiFindingDeleteAttachment,
} from "../../../../hooks/queries/findingContext";
import { FormModeState } from "../../../../shared/formUtils";
import useToastContext from "../../../../hooks/toastHook";

type Props = {
  findingAttachmentsToCreate: Attachment[];
  setAttachmentsToCreate: Dispatch<SetStateAction<Attachment[]>>;
  currentAttachmentsNames: string[] | undefined;
  findingId: number | undefined;
  formMode: FormModeState;
};

export const FindingAttachments = (props: Props) => {
  const {
    findingAttachmentsToCreate,
    setAttachmentsToCreate,
    currentAttachmentsNames,
    findingId,
    formMode,
  } = props;
  const theme = useContext(ThemeContext);
  const addToast = useToastContext();
  const inputRef = useRef<HTMLInputElement>(null);
  const { mutate: downloadAttachment } =
    useApiDownloadSingleFindingAttachment();
  const { mutate: createAttachments } = useApiCreateFindingAttachments();
  const { mutate: deleteAttachment } = useApiFindingDeleteAttachment();

  const handleButtonClick = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      if (formMode === FormModeState.Create) {
        setAttachmentsToCreate([
          ...findingAttachmentsToCreate,
          {
            attachment_data: await fileToBase64(file),
            attachment_name: file.name,
          },
        ]);
      }
      if (formMode === FormModeState.Update && findingId) {
        createAttachments({
          findingId: findingId,
          attachments: [
            {
              attachment_data: await fileToBase64(file),
              attachment_name: file.name,
            },
          ],
          onSuccessCallback() {
            addToast({
              message: `Successfully uploaded new attachment.`,
              type: "success",
            });
          },
          onErrorCallback(error) {
            addToast({
              message: `Failed to upload attachments. Error:${error} `,
              type: "error",
            });
          },
        });
      }
    }
  };

  return (
    <Box>
      <Flex w100 justify="between" align="center">
        <Flex gap="8px">
          <Icon name="folder" size={30} color={theme.primary} />{" "}
          <HeaderSecondary>Attachments</HeaderSecondary>
        </Flex>
        <Flex
          gap="8px"
          align="center"
          flexWrap
          style={{
            paddingLeft: "32px",
            paddingRight: "32px",
          }}
        >
          {currentAttachmentsNames &&
            currentAttachmentsNames.map((attachment) => (
              <AttachmentTag
                label={attachment}
                onClickCancel={() => {
                  if (findingId) {
                    deleteAttachment({
                      findingId: findingId,
                      attachment_name: attachment,
                      onSuccessCallback(data) {
                        addToast({
                          message: `Deleted attachment - ${data}`,
                          type: "success",
                        });
                      },
                      onErrorCallback(error) {
                        addToast({
                          message: `Failed to delete attachment - ${error}`,
                          type: "error",
                        });
                      },
                    });
                  }
                }}
                onClickDownload={() => !!findingId &&
                    downloadAttachment({
                      attachment_name: attachment,
                      findingId: findingId,
                      onSuccessCallback(data) {
                        blobToFileAndDownload(data as Blob, attachment);
                      },
                    })
                  }
              />
            ))}
          {formMode === FormModeState.Create &&
            findingAttachmentsToCreate &&
            findingAttachmentsToCreate.map((attachment) => (
              <AttachmentTag
                label={attachment.attachment_name}
                onClickDownload={() => {
                  base64ToFileAndDownload(
                    attachment.attachment_data,
                    attachment.attachment_name
                  );
                }}
                onClickCancel={() => {
                  setAttachmentsToCreate([
                    ...findingAttachmentsToCreate.filter(
                      (a) => a.attachment_name !== attachment.attachment_name
                    ),
                  ]);
                }}
              />
            ))}
        </Flex>
        <Flex>
          <LinkButton
            iconName="add"
            label={"Add attachment"}
            onClick={handleButtonClick}
          />
          <input
            type="file"
            ref={inputRef}
            onChange={handleFileChange}
            style={{ display: "none" }} // Hide the file input
          />
        </Flex>
      </Flex>
    </Box>
  );
};
