import { SkeletonLoading } from "../../../components/elements/loading/SkeletonLoading";

type Props = {};

export const TopDistributionSkeleton = (props: Props) => {
  const Item = () => (
    <div className="d-flex flex-column gap-8 w-100">
      <SkeletonLoading height="30px" width="14px" />
      <SkeletonLoading height="20px" width="100px" />
      <SkeletonLoading height="10px" width="100%" />
    </div>
  );

  return (
    <div
      className="d-flex align-items-center gap-2 w-100"
      style={{ overflowX: "clip" }}
    >
      <Item />
      <Item />
      <Item />
      <Item />
      <Item />
    </div>
  );
};
