import { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { Box } from "../../../components/elements/box/Box";
import { LinkButton } from "../../../components/elements/button/link/LinkButton";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import {
  BodyRegular,
  HeaderSecondary,
  HeaderSubBold,
} from "../../../components/elements/typography/Typography";
import { RightPane } from "../../../components/elements/rightPane/RightPane";
import { Project } from "../../../types/Project";
import { ProjectTimes } from "../../dashboard/bottomBlock/projectList/ProjectTimes";
import { ReportsMenu } from "./ReportsMenu";
import { Link, useSearchParams } from "react-router-dom";
import { Assignee } from "../../../components/composed/assignee/Assignee";
import { Flex } from "../../../components/layouts/flex/Flex";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";
import { useIsSuperuser } from "../../../hooks/useIsSuperuser";
import { AddEditProject } from "./AddEditProject";
import { objectToBase64 } from "../../../shared/helper";
import { AssetsInScope } from "./AssetsInScope";
import { Mixpanel } from "../../../shared/mixpanel";
import { useApiSingleProject } from "../../../hooks/queries/projectsContext";
import { useApiMe } from "../../../hooks/queries/meContext";
import { addClassToWaspHtmlImages } from "../../../shared/parser";
import { TabButton } from "../../../components/elements/button/tab/TabButton";
import { ProjectsFindings } from "./ProjectsFindings";

type Props = {
  project?: Project | null;
  onClose: () => void;
  isCreateMode?: boolean;
};

type Tab = "main" | "findings";

export const ProjectPane = (props: Props) => {
  const { project, onClose, isCreateMode = false } = props;
  const theme = useContext(ThemeContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const isSuperuser = useIsSuperuser();
  const { data: me } = useApiMe();

  const [selectedTab, setSelectedTab] = useState<Tab>("main");

  const canEditProject =
    isSuperuser ||
    (me?.customer.is_multi_tenant &&
      me?.can_manage_customer &&
      project?.is_self_managed);

  const projectId = searchParams.get("projectId");

  const { data: singleProject } = useApiSingleProject(
    !project && !isCreateMode,
    parseInt(projectId || "0")
  );

  const projectOnDisplay = project || singleProject;

  const [showReportsMenu, setShowReportsMenu] = useState(false);

  const [editMode, setEditMode] = useState(isCreateMode);

  const projectStart =
    projectOnDisplay?.type === "asm"
      ? projectOnDisplay.created_at
      : projectOnDisplay?.start;

  const projectEnd =
    projectOnDisplay?.type === "asm" ? null : projectOnDisplay?.end;

  return (
    <RightPane
      onClose={() => {
        searchParams.delete("projectId");
        setSearchParams(searchParams);
        setTimeout(onClose, 300);
      }}
      width="920px"
      noEffect
    >
      <Flex column w100 gap="24px" style={{ padding: "32px" }}>
        {!editMode ? (
          <>
            <Flex justify="between" align="center">
              <Flex column justify="center" gap="8px">
                <Tooltip
                  content={projectOnDisplay?.name || ""}
                  placement="bottom"
                  isTextTruncate
                >
                  <HeaderSecondary
                    className="text-truncate"
                    style={{ maxWidth: "250px" }}
                  >
                    <span data-testid="project-pane-title">
                      {projectOnDisplay?.name}
                    </span>
                  </HeaderSecondary>
                </Tooltip>
                <Flex gap="16px" align="center" h100>
                  <BodyRegular
                    className="text-uppercase"
                    style={{ color: theme.black700 }}
                  >
                    {projectOnDisplay?.type}
                  </BodyRegular>
                  <SeparatorVertical style={{ height: "18px" }} />
                  <Assignee
                    name={projectOnDisplay?.opinnovate_poc?.name}
                    imageSrc={projectOnDisplay?.opinnovate_poc_avatar_url}
                  />
                </Flex>
              </Flex>
              <Flex
                align="center"
                gap="8px"
                style={{
                  position: "relative",
                }}
              >
                <LinkButton
                  iconName="download"
                  label="Download Report"
                  disabled={
                    me?.customer.id === 20 ||
                    me?.customer.id === 24 ||
                    me?.customer.id === 84 ||
                    !projectOnDisplay?.reports?.length
                  } // disabled button for Deep Instinct
                  onClick={() => {
                    Mixpanel.track("Download report");
                    setShowReportsMenu(!showReportsMenu);
                  }}
                  dataTestId="download-report-button"
                />
                {canEditProject && (
                  <SecondaryButton
                    label="Edit"
                    iconName="edit"
                    onClick={() => setEditMode((prev) => !prev)}
                    size="medium"
                  />
                )}
                <Link
                  to={`/findings?filters=${objectToBase64({
                    project: [projectOnDisplay?.id],
                  })}`}
                  onClick={onClose}
                  style={{ textDecoration: "none" }}
                >
                  <MainButton
                    iconName="insightsOutline"
                    label="View in Findings"
                    size="medium"
                  />
                </Link>
                {showReportsMenu && (
                  <ReportsMenu
                    project={projectOnDisplay}
                    onClose={() => setShowReportsMenu(false)}
                  />
                )}
              </Flex>
            </Flex>
            <Flex gap="32px">
              <TabButton
                label="Project Info"
                iconName="shield"
                selected={selectedTab === "main"}
                onClick={() => setSelectedTab("main")}
              />
              <TabButton
                label="Findings"
                iconName="findings"
                selected={selectedTab === "findings"}
                onClick={() => setSelectedTab("findings")}
              />
            </Flex>
            {selectedTab === "main" && (
              <>
                <Box className="d-flex flex-column gap-16 h-100">
                  <ProjectTimes
                    projectStart={projectStart || ""}
                    projectEnd={projectEnd || ""}
                    withSeparator
                    stretch
                  />
                  <SeparatorHorizontal />
                  <AssetsInScope projectId={projectOnDisplay?.id} />
                </Box>
                <Flex column gap="16px">
                  <Box>
                    <Flex
                      column
                      gap="8px"
                      style={{
                        maxHeight: "calc(100vh - 480px)",
                        overflowY: "scroll",
                      }}
                    >
                      <HeaderSubBold style={{ color: theme.black700 }}>
                        Project Scope
                      </HeaderSubBold>
                      <SeparatorHorizontal />
                      <BodyRegular
                        className="text-wrap w-100"
                        style={{
                          overflowWrap: "anywhere",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: addClassToWaspHtmlImages(
                            projectOnDisplay?.project_scope || ""
                          ),
                        }}
                      />
                    </Flex>
                  </Box>
                </Flex>
              </>
            )}

            {selectedTab === "findings" && (
              <ProjectsFindings projectOnDisplay={projectOnDisplay} />
            )}
          </>
        ) : (
          <AddEditProject
            isCreateMode={isCreateMode}
            project={projectOnDisplay}
            closeEditMode={() => setEditMode(false)}
            onClose={onClose}
          />
        )}
      </Flex>
    </RightPane>
  );
};
