import { Link } from "react-router-dom";
import { Menu, MenuBackground } from "../../elements/menu/Menu";
import { MenuCategory } from "../../elements/menu/MenuCategory";
import { MenuItem } from "../../elements/menu/MenuItem";
import { handleLogout } from "../../../providers/auth/AuthProvider";
import { Me } from "../../../types/Me";
import { Mixpanel } from "../../../shared/mixpanel";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import {
  SCREEN_MOBILE_WIDTH,
  presentationModeKey,
} from "../../../shared/consts";
import { useIsSuperuser } from "../../../hooks/useIsSuperuser";
import { useIsPresentationMode } from "../../../hooks/useIsPresentationMode";

type Props = {
  me: Me | undefined;
};

export const ProfileMenu = (props: Props) => {
  const { me } = props;
  const isSuperuser = useIsSuperuser();
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth < SCREEN_MOBILE_WIDTH;

  const isPresentationMode = useIsPresentationMode();

  const togglePresentationMode = () => {
    if (isPresentationMode) localStorage.removeItem(presentationModeKey);
    else localStorage.setItem(presentationModeKey, "true");
    window.location.reload();
  };

  return (
    <>
      <MenuBackground />
      <Menu style={{ top: "25px", left: "-70px" }}>
        <>
          {!isMobile && (
            <MenuCategory title="System">
              <Link
                to={
                  me?.can_manage_customer
                    ? "settings/products"
                    : "settings/sla-policy"
                }
                style={{ textDecoration: "none" }}
                onClick={() => Mixpanel.track("Settings page")}
              >
                <MenuItem label="Settings" iconName="settings" />
              </Link>
            </MenuCategory>
          )}

          {isSuperuser && (
            <MenuCategory title="Admin">
              <a
                href="/admin-panel"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <MenuItem label="Admin Settings" iconName="maintenance" />
              </a>

              <Link
                to={"/whats-new-control-panel"}
                style={{ textDecoration: "none" }}
              >
                <MenuItem label="Edit Whats New" iconName="maintenance" />
              </Link>
              <Link to={"asm"} style={{ textDecoration: "none" }}>
                <MenuItem label="ASM Management" iconName="maintenance" />
              </Link>
              <Link to={"asm-analytics"} style={{ textDecoration: "none" }}>
                <MenuItem label="ASM Analytics" iconName="dashboard" />
              </Link>
              <Link
                to={"/research/findings/pending"}
                style={{ textDecoration: "none" }}
              >
                <MenuItem label="Pending Findings" iconName="maintenance" />
              </Link>
              <Link to={"alert-messages"} style={{ textDecoration: "none" }}>
                <MenuItem label="Alert Messages" iconName="notification" />
              </Link>
            </MenuCategory>
          )}

          {(isSuperuser || me?.customer.is_multi_tenant) && (
            <Link
              to={"research/finding/create"}
              style={{ textDecoration: "none" }}
            >
              <MenuItem label="Create Finding" iconName="maintenance" />
            </Link>
          )}

          {me?.is_superuser && (
            <MenuItem
              label={`${isPresentationMode ? "Stop" : "Start"} Presentation`}
              iconName={isPresentationMode ? "eyeSlash" : "eye"}
              onClick={togglePresentationMode}
            />
          )}

          {!isMobile && (
            <MenuCategory title="My Profile">
              <Link
                to={"settings/my-profile"}
                style={{ textDecoration: "none" }}
              >
                <MenuItem label="My Profile" iconName="profile" />
              </Link>
            </MenuCategory>
          )}
          <MenuItem label="Logout" iconName="logout" onClick={handleLogout} />
        </>
      </Menu>
    </>
  );
};
