import React, { useContext } from "react";
import { InputText } from "../input/textInput/InputText";
import { ThemeContext } from "styled-components";

type Props = {
  disabled?: boolean;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  width?: string | number;
  dataTestId?: string;
};

export const DatePicker = (props: Props) => {
  const { disabled, value, onChange, placeholder, width, dataTestId } = props;

  const theme = useContext(ThemeContext);

  return (
    <InputText
      type={"date"}
      disabled={disabled}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      width={width}
      dataTestId={dataTestId}
      style={{ colorScheme: theme.name }}
    />
  );
};
