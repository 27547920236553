import React from "react";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Dropdown } from "../../../components/elements/dropdowns/Dropdown";
import { TrendFilters } from "./FindingsTrends";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import {
  FINDING_TYPES,
  FindingStatus,
  FindingType,
} from "../../../types/Finding";
import { SEVERITIES, StatusMapping } from "../../../shared/consts";

type Props = {
  trendFilters: TrendFilters;
  onChangeFilters: (trendFilters: TrendFilters) => void;
};

export const TrendFiltersBar = (props: Props) => {
  const { trendFilters, onChangeFilters } = props;
  const { data: products } = useApiProducts();

  return (
    <Flex gap="24px">
      <Dropdown
        value={
          trendFilters.product_id === "All"
            ? { label: "All Products", value: "All" }
            : {
                label: products
                  ? (products.find((p) => p.id === trendFilters.product_id)
                      ?.name ?? "")
                  : "",
                value: trendFilters.product_id,
              }
        }
        options={[
          { label: "All Products", value: "All" },
          ...(products
            ? products.map((p) => {
                return { label: p.name, value: p.id };
              })
            : []),
        ]}
        onChange={(op) => {
          if (op)
            onChangeFilters({
              ...trendFilters,
              product_id: op.value as number,
            });
        }}
      />
      <Dropdown
        value={
          trendFilters.finding_type === "All"
            ? { label: "All Finding Types", value: "All" }
            : {
                label: trendFilters.finding_type.toUpperCase(),
                value: trendFilters.finding_type,
              }
        }
        options={[
          { label: "All Finding Types", value: "All" },
          ...FINDING_TYPES.map((t) => ({
            label: t.toUpperCase(),
            value: t,
          })),
        ]}
        onChange={(op) => {
          if (op)
            onChangeFilters({
              ...trendFilters,
              finding_type: op.value as FindingType | "All",
            });
        }}
      />

      <Dropdown
        value={
          trendFilters.status === "All"
            ? { label: "All Statuses", value: "All" }
            : {
                label: StatusMapping[trendFilters.status],
                value: trendFilters.status,
              }
        }
        options={[
          { label: "All Statuses", value: "All" },
          ...Object.keys(StatusMapping).map((key) => ({
            value: key,
            label: StatusMapping[key as keyof typeof StatusMapping],
          })),
        ]}
        onChange={(op) => {
          if (op)
            onChangeFilters({
              ...trendFilters,
              status: op.value as FindingStatus | "All",
            });
        }}
      />
      <Dropdown
        value={
          trendFilters.risk === "All"
            ? { label: "All Risk Levels", value: "All" }
            : {
                label: SEVERITIES[trendFilters.risk],
                value: trendFilters.risk,
              }
        }
        options={[
          { label: "All Risk Levels", value: "All" },
          ...Object.keys(SEVERITIES).map((key) => ({
            value: key,
            label: SEVERITIES[key as keyof typeof SEVERITIES],
          })),
        ]}
        onChange={(op) => {
          if (op)
            onChangeFilters({
              ...trendFilters,
              risk: op.value as number | "All",
            });
        }}
      />
    </Flex>
  );
};
