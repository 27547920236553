import React, { useContext } from "react";
import { useReactFlow } from "@xyflow/react";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { Tooltip } from "../../../../components/elements/tooltip/Tooltip";
import { IconButton } from "../../../../components/elements/button/icon/IconButton";
import { GroupButton } from "../../../../components/elements/button/group/GroupButton";
import { ThemeContext } from "styled-components";

export const CustomControls = () => {
  const { zoomIn, zoomOut, fitView } = useReactFlow();
  const theme = useContext(ThemeContext);

  return (
    <div style={{ position: "absolute", right: 0, top: 0, zIndex: 1000 }}>
      <Flex gap="16px" align="center">
        <Tooltip placement="right" content="Zoom to fit">
          <IconButton
            size="small"
            iconName="reverse"
            color={theme.primary}
            id="zoom-reset-btn"
            onClick={fitView}
          />
        </Tooltip>

        <GroupButton
          buttonsProps={[
            {
              iconName: "zoomIn",
              onClick: zoomIn,
              id: "zoom-in-btn",
            },
            {
              iconName: "zoomOut",
              onClick: zoomOut,
              id: "zoom-out-btn",
            },
          ]}
        />
      </Flex>
    </div>
  );
};
