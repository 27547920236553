import React, { useContext } from "react";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Assignee } from "../../../components/composed/assignee/Assignee";
import JiraIcon from "../../../assets/images/Jira.svg";
import WaspIcon from "../../../assets/images/Brand-logo.png";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { LabelRegular } from "../../../components/elements/typography/Typography";
import moment from "moment";
import { ThemeContext } from "styled-components";

type Props = {
  userName: string;
  avatarUrl?: string;
  createdAt: string;
};

export function CardHeader({ userName, avatarUrl, createdAt }: Props) {
  const theme = useContext(ThemeContext);
  return (
    <Flex align="center" gap="16px" w100>
      <Flex className="text-truncate">
        <Assignee
          name={userName}
          imageSrc={
            userName.trim() === "(JIRA)"
              ? JiraIcon
              : userName.trim() === "WASP"
                ? WaspIcon
                : avatarUrl
          }
        />
      </Flex>
      <SeparatorVertical height="24px" />
      <LabelRegular
        style={{
          color: theme.black600,
          width: "30%",
        }}
        className="text-truncate"
        title={moment(createdAt).fromNow()}
      >
        {moment(createdAt).fromNow()}
      </LabelRegular>
    </Flex>
  );
}
