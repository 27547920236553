import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Icon } from "../../../components/elements/icon/Icon";
import { SkeletonLoading } from "../../../components/elements/loading/SkeletonLoading";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import {
  HeaderSecondary,
  LabelRegularHover,
} from "../../../components/elements/typography/Typography";
import { FindingsCounts } from "../../../types/Finding";
import { urgentFindingTooltipContent } from "../../../shared/tooltipTexts";
import {
  ActionItemCounter,
  ActionItemKPI,
  TopBox,
  TopBoxTitle,
} from "../FindingsStyles";
import { Link } from "react-router-dom";
import { Flex } from "../../../components/layouts/flex/Flex";
import { objectToBase64 } from "../../../shared/helper";
import { findingsDefaultFilters } from "../Findings";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import { SCREEN_MOBILE_WIDTH } from "../../../shared/consts";

const ActionItems = ({
  findingsCounts,
  isFetching,
}: {
  findingsCounts: FindingsCounts | undefined;
  isFetching?: boolean;
}) => {
  const theme = useContext(ThemeContext);
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth < SCREEN_MOBILE_WIDTH;

  const ActionItem = ({
    title,
    dataTestId,
    tooltipContent,
    countsKey,
    onClick,
    linkTo,
  }: {
    title: string;
    dataTestId: string;
    tooltipContent?: string;
    countsKey: string;
    onClick: () => void;
    linkTo: string;
  }) => (
    <Link
      to={isMobile ? "/" : linkTo}
      className="d-flex"
      style={{
        textDecoration: "none",
        color: "inherit",
        width: "100%",
        overflow: "auto",
      }}
    >
      <ActionItemCounter
        data-testid={dataTestId}
        onClick={onClick}
        className="w-100"
      >
        {isFetching ? (
          <SkeletonLoading height="48px" width="48px" />
        ) : (
          <ActionItemKPI>
            {findingsCounts &&
              findingsCounts[countsKey as keyof FindingsCounts]}
          </ActionItemKPI>
        )}
        <Flex
          align="center"
          gap="4px"
          style={{
            width: "100%",
          }}
        >
          <LabelRegularHover
            className="text-truncate w-100 text-center"
            title={title}
          >
            {title}
          </LabelRegularHover>
          {!isMobile && tooltipContent && (
            <Tooltip content={tooltipContent}>
              <Icon name="info" color={theme.black500} size={24} />
            </Tooltip>
          )}
        </Flex>
      </ActionItemCounter>
    </Link>
  );

  return (
    <TopBox style={{ gap: "16px", width: "100%" }}>
      <TopBoxTitle>
        <Icon name="activeIssues" size={32} color={theme.redPrimary} />
        <HeaderSecondary
          className="text-truncate"
          style={{ width: "calc(100% - 32px)" }}
        >
          Action Items
        </HeaderSecondary>
      </TopBoxTitle>
      <Flex justify="between" align="center" gap="16px" w100>
        <ActionItem
          title="Urgent Findings"
          dataTestId="urgent-counter"
          countsKey="urgent"
          tooltipContent={urgentFindingTooltipContent}
          onClick={() => {}}
          linkTo={`/findings?filters=${objectToBase64({
            ...findingsDefaultFilters,
            overall_risk: "3,4",
            breached_sla: true,
          })}`}
        />
        <SeparatorVertical style={{ height: "90px" }} />
        <ActionItem
          title="SLA Breaches"
          dataTestId="sla-counter"
          countsKey="breached_sla"
          onClick={() => {}}
          linkTo={`/findings?filters=${objectToBase64({
            ...findingsDefaultFilters,
            breached_sla: true,
          })}`}
        />
      </Flex>
    </TopBox>
  );
};

export default ActionItems;
