import { useContext, useEffect, useRef, useState } from "react";
import { Avatar } from "../../../components/elements/avatar/Avatar";
import { RichTextEditorWASP } from "../../../components/elements/richTextEditor/RichTextEditor";
import { Flex } from "../../../components/layouts/flex/Flex";
import { ThemeContext } from "styled-components";
import { CommentInput } from "../../findings/findingHistoryAndComments/CommentInput";
import { LinkButton } from "../../../components/elements/button/link/LinkButton";
import { useApiMe } from "../../../hooks/queries/meContext";
import { ImageModal } from "../../../components/composed/imageModal/ImageModal";
import { CommentCard } from "../../findings/findingHistoryAndComments/CommentCard";
import { Loading } from "../../../components/elements/loading/Loading";
import { useApiCreateAssetComment } from "../../../hooks/queries/assetsCommentsContext";
import { Asset } from "../../../types/Asset";
import {
  SortOptions,
  SortType,
} from "../../findings/findingHistoryAndComments/FindingHistory";
import { AssetComment } from "../../../types/AssetComment";
import { useInfiniteScroll } from "../../../hooks/utilsHooks";
import { addClassToWaspHtmlImages } from "../../../shared/parser";
import { LabelRegular } from "../../../components/elements/typography/Typography";
import { Dropdown } from "../../../components/elements/dropdowns/Dropdown";
import { handleAssetBase64Images } from "./ImagesHandler";
import { Mixpanel } from "../../../shared/mixpanel";
import { useApiAssetLogPaging } from "../../../hooks/queries/assetLogsContext";
import { AssetLog } from "../../../types/AssetLog";
import { AssetLogCard } from "../assetLog/AssetLogCard";
import { useSearchParams } from "react-router-dom";

type Props = {
  asset: Asset | undefined;
};

export const AssetActivityLogs = (props: Props) => {
  const { asset } = props;
  const theme = useContext(ThemeContext);
  const observerElemForFetchPage = useRef(null);
  const initialValueTriggerRTE = useRef(true);
  const [searchParams] = useSearchParams();
  const storageKey = `commentOnAsset${asset?.id}`;
  const cachedComment = localStorage.getItem(storageKey) || "";

  const { data: me } = useApiMe();

  const { mutate: createComment, isLoading } = useApiCreateAssetComment();

  const [sortedMode, setSortedMode] = useState<SortType>("newest");

  const {
    data: assetLogs,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useApiAssetLogPaging(
    {
      asset: asset?.id,
      ordering: sortedMode === "newest" ? "-created_at" : "created_at",
      "admin-mode": searchParams.has("admin-mode") ? true : false,
    },
    !!asset?.id
  );

  useInfiniteScroll(
    observerElemForFetchPage,
    hasNextPage || false,
    fetchNextPage
  );

  const getAssetsLogs = (): AssetLog[] => {
    return assetLogs?.pages.map((page) => page?.results || []).flat() || [];
  };

  const [commentMessage, setCommentMessage] = useState("");
  const [commentMd, setCommentMd] = useState("");
  const [showRichText, setShowRichText] = useState(false);

  const [showImageModal, setShowImageModal] = useState(false);
  const [clickedSource, setClickedSource] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (!asset || !cachedComment || commentMessage) return;
    initialValueTriggerRTE.current = true;
    setCommentMessage(cachedComment);
    setShowRichText(true);
  }, [asset, cachedComment, commentMessage]);

  const sendComment = () => {
    if (!asset?.id || !commentMessage) return;

    handleAssetBase64Images(asset?.id, commentMessage, commentMd, (n) => {
      console.log("progress", n);
    }).then(
      ({
        html: updateHtml,
        markdown: updatedMarkdown,
        attachments: commentAttachments,
      }) => {
        // After we've uploaded the images, we can send the comment

        // Fix the images style in the html
        updateHtml = addClassToWaspHtmlImages(updateHtml);
        createComment({
          asset_id: asset?.id,
          comment_html: updateHtml,
          comment_markdown: updatedMarkdown,
          attachments: commentAttachments,
        });
        setCommentMessage("");
        localStorage.removeItem(storageKey);
        Mixpanel.track("Asset Pane - Comment on asset");
      }
    );
  };

  const registerOnClickImageEvents = () => {
    const elements = document.getElementsByClassName(
      "wasp-image-big"
    ) as HTMLCollectionOf<HTMLImageElement>;
    if (elements.length > 0) {
      Array.from(elements).forEach((elm) => {
        elm.onclick = () => {
          setClickedSource(elm.src);
          setShowImageModal(true);
        };
      });
    }
  };

  setTimeout(registerOnClickImageEvents, 1000);

  return (
    <Flex column gap="32px">
      <Flex column gap="0px">
        <Flex align="center" gap="12px">
          <Avatar imageSrc={me?.avatar_url} />

          {!isLoading && showRichText && (
            <RichTextEditorWASP
              allowMentions
              value={commentMessage}
              valueTrigger={initialValueTriggerRTE}
              onChange={(html: string, markdown: string) => {
                setCommentMessage(html);
                setCommentMd(markdown);
                if (!!markdown) localStorage.setItem(storageKey, markdown);
                else localStorage.removeItem(storageKey);
              }}
              placeholderText="Enter your comment..."
              theme={theme}
            />
          )}
          {!isLoading && !showRichText && (
            <CommentInput onFocus={() => setShowRichText(true)} />
          )}
        </Flex>
        {showRichText && (
          <Flex justify="end">
            <LinkButton
              label="Cancel"
              iconName="cancel"
              onClick={() => setShowRichText(false)}
              dataTestId="asset-comment-cancel"
            />
            <LinkButton
              label="Send"
              onClick={sendComment}
              dataTestId="asset-comment-send"
              disabled={!commentMd}
              inProgress={isLoading}
              iconName="send"
            />
          </Flex>
        )}
      </Flex>

      <Flex align="center" justify="end">
        <LabelRegular style={{ color: theme.black600 }}>Sort:</LabelRegular>
        <Dropdown
          size="small"
          onChange={(option) => setSortedMode(option?.value as SortType)}
          options={SortOptions}
          value={SortOptions.find((o) => o.value === sortedMode)}
          dataTestId="asset-comments-sort"
        />
      </Flex>

      <Flex
        column
        gap="24px"
        style={{ height: "calc(100vh - 420px)", overflow: "scroll" }}
      >
        {getAssetsLogs().map((assetLog, i) =>
          !!assetLog.comment && (assetLog.comment as AssetComment) ? (
            <CommentCard
              key={`comment-card-${i}`}
              dataTestId={`comment-card-${i}`}
              comment={assetLog.comment}
            />
          ) : (
            <AssetLogCard key={`asset-log-${i}`} assetLog={assetLog} />
          )
        )}

        <Flex
          justify="center"
          className="loader"
          ref={observerElemForFetchPage}
        >
          {isFetchingNextPage ? (
            <Flex column justify="center">
              <Loading />
            </Flex>
          ) : null}
        </Flex>
      </Flex>

      {showImageModal && (
        <ImageModal
          onClose={() => setShowImageModal(false)}
          image={clickedSource || ""}
        />
      )}
    </Flex>
  );
};
