export const dropdownStyles = ({
  backgroundColor,
  color,
  disabled,
  isError,
  isOpen,
  variant,
  size,
  theme,
}: {
  backgroundColor?: string;
  color?: string;
  disabled: boolean;
  isError: boolean;
  isOpen: boolean;
  variant: "border" | "outline";
  size: "large" | "medium" | "small" | undefined;
  theme: { [index: string]: string };
}) => {
  return {
    control: () => ({
      display: "flex",
      borderRadius: "0.6em",
      backgroundColor: disabled
        ? theme.separation
        : backgroundColor
        ? backgroundColor
        : "transparent",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    option: (base: any, state: any) => ({
      cursor: "pointer",
      fontSize: "14px",
      fontFamily: "poppins",
      fontWeight: state.isSelected ? "800" : "400",
      fontStyle: "normal",
      padding: "0px",
      paddingInline: "16px",
      marginTop: "16px",
      zIndex: "980",
      color:
        state.isSelected || state.isFocused ? theme.primary : theme.textBody,
      "&:hover": {
        backgroundColor: "transparent",
        color: theme.primary,
      },
    }),
    menu: (base: any) => ({
      ...base,
      zIndex: "980",
      minWidth: "150px",
      paddingBottom: "16px",
      backgroundColor: theme.bg2,
      border: `1px solid ${theme.separation}`,
    }),
    container: (base: any, state: any) => ({
      ...base,
      cursor: disabled ? "not-allowed" : "pointer",
      transition: "0.4s",
      borderRadius: "0.6em",
      border:
        variant === "border"
          ? `1px solid ${
              isError
                ? theme.redPrimary
                : isOpen
                ? theme.primary
                : theme.black500
            }`
          : "",
      "&:hover": {
        border:
          variant === "border" && !isError ? `1px solid ${theme.primary}` : "",
      },
    }),

    singleValue: (base: any, state: any) => ({
      ...base,
      color: isError
        ? theme.redPrimary
        : isOpen
        ? theme.primary
        : color || theme.textSecondary,
      "&:hover": { color: isError ? theme.redPrimary : theme.primary },
      transition: "0.2s",
      fontFamily: "poppins",
      fontStyle: "normal",
      fontWeight: size === "large" ? "600" : "400",
      fontSize: size === "large" ? "16px" : size === "medium" ? "14px" : "12px",
      padding: size === "large" ? "8px" : size === "medium" ? "6px" : "4px",
      paddingLeft: "0",
      marginRight: variant === "border" ? "32px" : "",
    }),
    placeholder: (base: any, state: any) => ({
      ...base,
      fontFamily: "poppins",
      fontStyle: "normal",
      fontWeight: size === "large" ? "600" : "400",
      fontSize: size === "large" ? "16px" : size === "medium" ? "14px" : "12px",
      padding: size === "large" ? "8px" : size === "medium" ? "6px" : "4px",
    }),

    dropdownIndicator: (base: any, state: any) => ({
      ...base,
      position: variant === "border" ? "absolute" : "",
      right: variant === "border" ? "0" : "",
      transform: isOpen ? "rotateX(180deg)" : "",
      transition: "0.4s",
      padding: "0",
      color: disabled
        ? theme.separation
        : isError
        ? theme.redPrimary
        : isOpen
        ? theme.primary
        : color || theme.textSecondary,
      "&:hover": { color: theme.primary },
    }),
    clearIndicator: (base: any, state: any) => ({
      ...base,
      right: "24px",
      position: "absolute",
    }),
    input: (base: any, state: any) => ({
      ...base,
      color: theme.textBody,
    }),
  };
};
