import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import {
  LabelMedium,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import {
  calculateAssetGrade,
  getAssetGradeTooltipContent,
  getGradeBackground,
  getGradeColor,
} from "../AssetUtils";
import { Flex } from "../../../components/layouts/flex/Flex";

type Props = {
  riskScore: number;
  color?: string;
  backgroundColor?: string;
};

export const AssetGradeBadge = (props: Props) => {
  const { riskScore, color, backgroundColor } = props;
  const theme = useContext(ThemeContext);
  const grade = calculateAssetGrade(riskScore);
  const tooltipContent = getAssetGradeTooltipContent(grade);
  return (
    <Flex>
      <Tooltip
        content={
          <LabelRegular
            className="d-flex"
            style={{
              width: "350px",
              textTransform: "initial",
            }}
          >
            {tooltipContent}
          </LabelRegular>
        }
      >
        <Flex
          align="center"
          justify="center"
          style={{
            width: "56px",
            height: "26px",
            borderRadius: "16px",
            backgroundColor:
              backgroundColor || theme[getGradeBackground(grade)],
          }}
        >
          <LabelMedium color={color || theme[getGradeColor(grade)]}>
            {grade}
          </LabelMedium>
        </Flex>
      </Tooltip>
    </Flex>
  );
};
