import React from "react";
import { useApiSecurityBenchmark } from "../../hooks/queries/findingContext";
import { Flex } from "../../components/layouts/flex/Flex";
import {
  HeaderSecondary,
  LabelBold,
} from "../../components/elements/typography/Typography";

export const SecurityBenchmark = () => {
  const { data } = useApiSecurityBenchmark();

  return (
    <Flex justify="between">
      <Flex column gap="16px">
        <HeaderSecondary>Benchmarks</HeaderSecondary>
        <Flex column gap="8px">
          <LabelBold>Risk score:</LabelBold>
          <LabelBold>Coverage score:</LabelBold>
          <LabelBold>MTTR:</LabelBold>
          <LabelBold>Num. of Open Findings:</LabelBold>
          <LabelBold>Avg. Age of Open Finding:</LabelBold>
        </Flex>
      </Flex>
      <Flex column gap="16px">
        <HeaderSecondary>Your data</HeaderSecondary>
        <Flex column gap="8px">
          <LabelBold>
            {data ? data.risk_score["current_customer_score"] : "--"}
          </LabelBold>
          <LabelBold>
            {data ? data.coverage_score["current_customer_score"] : "--"}
          </LabelBold>
          <LabelBold>
            {data ? data.mttr["current_customer_mttr"] : "--"}
          </LabelBold>
          <LabelBold>
            {data
              ? data.count_open_findings["current_customer_count_open_findings"]
              : "--"}
          </LabelBold>
          <LabelBold>
            {data
              ? data.avg_age_open_finding[
                  "current_customer_avg_open_age_finding"
                ]
              : "--"}
          </LabelBold>
        </Flex>
      </Flex>
      <Flex column gap="16px">
        <HeaderSecondary>Industry data</HeaderSecondary>
        <Flex column gap="8px">
          <LabelBold>
            {data ? data.risk_score["avg_risk_score"] : "--"}
          </LabelBold>
          <LabelBold>
            {data ? data.coverage_score["avg_coverage_score"] : "--"}
          </LabelBold>
          <LabelBold>{data ? data.mttr["avg_mttr"] : "--"}</LabelBold>
          <LabelBold>
            {data ? data.count_open_findings["avg_open_findings"] : "--"}
          </LabelBold>
          <LabelBold>
            {data
              ? data.avg_age_open_finding["avg_avg_open_age_finding"]
              : "--"}
          </LabelBold>
        </Flex>
      </Flex>
    </Flex>
  );
};
