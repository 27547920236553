import React, { useContext } from "react";
import { BadgesLine } from "../../../components/elements/badge/BadgesLine";
import { PrimaryBadge } from "../../../components/elements/badge/PrimaryBadge";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { Finding } from "../../../types/Finding";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Badge } from "../../../components/elements/badge/Badge";
import { ThemeContext } from "styled-components";

type Props = {
  finding: Finding | undefined | null;
  showTicketBadge?: boolean;
};

export const FindingBadges = (props: Props) => {
  const theme = useContext(ThemeContext);
  const { finding, showTicketBadge } = props;

  const needSeparator =
    showTicketBadge || !!finding?.cve_ids?.length || !!finding?.cwe_id;

  return (
    <Flex align="center" gap="16px" flexWrap>
      {showTicketBadge && <PrimaryBadge content={`Ticket #${finding?.id}`} />}

      {finding?.cve_ids?.map((cve_id) => (
        <PrimaryBadge content={`CVE-${cve_id}`} key={`CVE-${cve_id}`} />
      ))}

      {!!finding?.cwe_id && (
        <PrimaryBadge content={`CWE-#${finding?.cwe_id}`} />
      )}

      {needSeparator && <SeparatorVertical style={{ height: "16px" }} />}

      <BadgesLine badges={finding?.labels || []} variant="secondary" gap={16} />
      {needSeparator && <SeparatorVertical style={{ height: "16px" }} />}

      {finding?.is_false_positive && (
        <Badge color={theme.red50} backgroundColor={theme.redPrimary}>
          False Positive
        </Badge>
      )}
    </Flex>
  );
};
