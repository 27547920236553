import { Dispatch, SetStateAction, useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import useToastContext from "../../../hooks/toastHook";
import { useMutationWithDelay } from "../../../hooks/utilsHooks";
import { Loading } from "../../../components/elements/loading/Loading";
import { Menu } from "../../../components/elements/menu/Menu";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { BodyRegular } from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useApiMe } from "../../../hooks/queries/meContext";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import {
  Dropdown,
  Option,
} from "../../../components/elements/dropdowns/Dropdown";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { useApiPushFindingToCustomerJira } from "../../../hooks/queries/findingContext";
import { ConfirmModal } from "../../../components/composed/confirmModal/ConfirmModal";
import { Finding } from "../../../types/Finding";
import { Backdrop } from "../../../components/elements/rightPane/RightPane";

type Props = {
  onClose: () => void;
  findings: Finding[];
  setIsUpdating?: Dispatch<SetStateAction<boolean>>;
};

export const PushFindingJiraMenu = (props: Props) => {
  const { findings, onClose } = props;

  const theme = useContext(ThemeContext);

  const { data: me, isFetching } = useApiMe();
  const { runMutation } = useMutationWithDelay(3000);
  const addToast = useToastContext();
  const {
    mutate: sendTicket,
    isLoading,
    status,
  } = useApiPushFindingToCustomerJira();
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [isDelayed, setIsDelayed] = useState<boolean>(false);
  const [showCannotSendModal, setShowCannotSendModal] =
    useState<boolean>(false);

  const handleSendToJiraBulk = (projectKey: string, projectName: string) => {
    if (findings.some((finding) => finding.customer_jira_issue_key)) {
      setShowCannotSendModal(true);
      return;
    }
    handleSendJiraTicket(projectKey, projectName);
  };

  const handleSendJiraTicket = (projectKey: string, projectName: string) => {
    if (!findings) {
      addToast({ message: `Missing findings`, type: "error" });
      return;
    }
    const findingsToSend = findings.filter(
      (finding) => !finding.customer_jira_issue_key
    );
    setIsDelayed(true);
    runMutation(() => {
      setIsDelayed(false);
      sendTicket({
        findingsId: findingsToSend.map((f) => f.id) || [-1],
        projectKey: projectKey || "",
        onSuccessCallback: () => {
          addToast({
            message: `${
              findingsToSend?.length || 0
            } Tickets were sent to Jira successfully`,
            type: "success",
          });
          onClose();
        },
        onErrorCallback: () => {
          addToast({
            message: `Failed to send to "${projectName}"`,
            type: "error",
          });
        },
      });
    }, `Sending to ticket to "${projectName}"...`);
  };

  const projectOptions =
    me?.customer?.jira_projects_mapping
      ?.filter((p) => !p.auto_send && p.enabled)
      .map((p) => ({
        label: p.project_name,
        value: p.project_key,
      })) || [];

  return (
    <>
      <Backdrop transparent blurEnabled={false} onClick={onClose} />
      <Menu
        style={{
          width: "320px",
          zIndex: 200,
          padding: "16px",
        }}
      >
        <Flex column w100 gap="16px" padding="0" justify="center">
          <Flex justify="between" align="center">
            <BodyRegular color={theme.black900}>Send to Jira</BodyRegular>

            <IconButton
              iconName="cancel"
              size="small"
              onClick={onClose}
              color={theme.primary}
            />
          </Flex>

          <SeparatorHorizontal />
          {isFetching ? (
            <Loading />
          ) : (
            <Dropdown
              value={selectedOption}
              onChange={(option) => setSelectedOption(option)}
              key={projectOptions?.length || 0}
              options={projectOptions}
              variant="border"
              size="medium"
              dataTestId="assign-project-dropdown"
              isMenuPositionFixed
            />
          )}

          <Flex justify="end">
            <MainButton
              label="Send"
              onClick={() =>
                handleSendToJiraBulk(
                  selectedOption?.value?.toString() || "",
                  selectedOption?.label?.toString() || ""
                )
              }
              inProgress={isLoading || isDelayed}
              disabled={!selectedOption}
              dataTestId="assign-project-send-button"
              size="medium"
            />
          </Flex>
        </Flex>
      </Menu>
      {showCannotSendModal && (
        <ConfirmModal
          isLoading={status === `loading`}
          isSuccess={status === `success`}
          confirmButtonText="Send Tickets"
          body={`Some of the selected tickets are already assigned to Jira. Do you want to send the tickets that aren’t assigned anyway?`}
          successMessage={`${
            findings.filter((f) => !f.customer_jira_issue_key)?.length || 0
          } Tickets were sent to Jira successfully`}
          title={`Tickets Can Be Sent to Jira Only Once `}
          onClose={() => setShowCannotSendModal(false)}
          onConfirm={() =>
            handleSendJiraTicket(
              selectedOption?.value?.toString() || "",
              selectedOption?.label?.toString() || ""
            )
          }
          cancelButtonText="No, Thanks"
        />
      )}
    </>
  );
};
