import { useContext, useRef } from "react";
import { useApiAssetsPaging } from "../../../hooks/queries/assetsContext";
import { useInfiniteScroll } from "../../../hooks/utilsHooks";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  HeaderSubBold,
} from "../../../components/elements/typography/Typography";
import { ThemeContext } from "styled-components";
import { Loading } from "../../../components/elements/loading/Loading";
import { TagBadge } from "../../../components/composed/tagsLine/TagBadge";
import { AssetsDropdown } from "./AssetsDropdown";
import { Option } from "../../../components/elements/dropdowns/Dropdown";
import { SingleValue } from "react-select";

type Props = {
  projectId?: number;
  selectedAssetsIds?: number[];
  includedAssetsOptions?: SingleValue<Option>[];
  onAddAssetTag?: (opt: SingleValue<Option>) => void;
  onDeleteAssetOption?: (opt: SingleValue<Option>) => void;
};

export const AssetsInScope = (props: Props) => {
  const {
    projectId,
    selectedAssetsIds,
    includedAssetsOptions,
    onAddAssetTag,
    onDeleteAssetOption,
  } = props;
  const theme = useContext(ThemeContext);
  const observerElemForFetchPage = useRef(null);
  const queryParams = !!projectId
    ? { projects: projectId }
    : { id: selectedAssetsIds };

  const {
    data: assets,
    isFetchingNextPage: isFetchingAssets,
    hasNextPage: hasAssetsNextPage,
    fetchNextPage: fetchAssetsNextPage,
  } = useApiAssetsPaging(
    { ...queryParams, page_size: 25 },
    !!projectId || !!selectedAssetsIds?.length //On edit mode start query only if assets selected
  );

  const pagedAssets =
    !!projectId || !!selectedAssetsIds?.length
      ? assets?.pages?.map((page) => page?.results || []).flat() || []
      : [];

  useInfiniteScroll(
    observerElemForFetchPage,
    !!hasAssetsNextPage,
    fetchAssetsNextPage
  );

  return (
    <Flex column gap="16px">
      <Flex align="center" gap="12px" w100>
        <HeaderSubBold>Assets in Scope</HeaderSubBold>
      </Flex>

      {!!onAddAssetTag && (
        <AssetsDropdown
          selected={
            includedAssetsOptions?.map((opt) => parseInt(`${opt?.value}`)) || []
          }
          onChange={(opt) => onAddAssetTag && onAddAssetTag(opt)}
        />
      )}

      <Flex
        flexWrap
        gap="8px"
        style={{ maxHeight: "140px", overflowY: "scroll" }}
      >
        {pagedAssets.length === 0 && !includedAssetsOptions?.length ? (
          <BodyRegular style={{ color: theme.black600 }}>
            No assets in scope
          </BodyRegular>
        ) : null}

        {pagedAssets?.map((asset) => (
          <TagBadge
            key={`asset-in-scope-${asset.id}`}
            tag={asset.name}
            onDeleteOption={onDeleteAssetOption}
            isEditable={!!onDeleteAssetOption}
          />
        ))}

        {includedAssetsOptions?.map((opt) => (
          <TagBadge
            option={opt}
            key={`asset-option-${opt?.value}`}
            onDeleteOption={onDeleteAssetOption}
            isEditable={!!onDeleteAssetOption}
          />
        ))}

        <div className="d-flex" ref={observerElemForFetchPage} />
        {isFetchingAssets && <Loading />}
      </Flex>
    </Flex>
  );
};
