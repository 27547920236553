import React from "react";
import { RichTextEditor } from "rich-text-editor";
import { Flex } from "../../layouts/flex/Flex";
import { MainButton } from "../button/main/MainButton";
import { useApiAccountsPaging } from "../../../hooks/queries/accountsContext";
import { ShortUser } from "../../../types/Me";

type Props = {
  saveChangesMode?: boolean;
  label?: string;
  required?: boolean;
  value?: string | null;
  valueTrigger?: React.MutableRefObject<boolean>;
  clearTrigger?: React.MutableRefObject<boolean>;
  placeholderText?: string;
  onChange: (value: string, markdown: string) => void;
  onSave?: () => void;
  onCancel?: () => void;
  theme?: any;
  allowMentions?: boolean;
  optionalMentionUsers?: ShortUser[];
};
export const RichTextEditorWASP = (props: Props) => {
  const {
    saveChangesMode,
    label,
    required,
    onChange,
    onSave,
    onCancel,
    value,
    valueTrigger,
    clearTrigger,
    placeholderText,
    theme,
    allowMentions,
    optionalMentionUsers = [],
  } = props;

  // Get all customers accounts for mentioning users on comments
  const { data: accountsPages } = useApiAccountsPaging({}, !!allowMentions);

  const optionalMentionEmails =
    optionalMentionUsers.map((user) => user.email) || [];

  const getAccounts = (): ShortUser[] => {
    let accounts =
      accountsPages?.pages
        .map((page) => page.results || [])
        .flat()
        .map((a) => ({
          name: a.user.name,
          email: a.email,
          avatar_url: a.user.avatar_url || "",
        }))
        .filter((a) => !optionalMentionEmails.includes(a.email)) || [];

    return [...accounts, ...optionalMentionUsers];
  };

  return (
    <>
      <RichTextEditor
        label={label}
        required={required}
        value={value}
        valueTrigger={valueTrigger}
        clearTrigger={clearTrigger}
        placeholderText={placeholderText}
        onChange={onChange}
        theme={theme}
        users={getAccounts()}
      />
      {saveChangesMode && (
        <Flex gap="8px" justify="end">
          <MainButton
            variant="secondary"
            size="small"
            onClick={() => {
              if (onCancel) {
                // This is a workaround
                // We first clear the Rich Text Editor by triggering clear
                if (clearTrigger !== undefined) clearTrigger.current = true;
                // Then we allow insertion of initial values
                if (valueTrigger !== undefined) valueTrigger.current = true;
                // We change the value in the onCancel func
                onCancel();
              }
            }}
            label={"Cancel"}
          />
          <MainButton
            iconName="send"
            size="small"
            onClick={() => {
              if (onSave) onSave();
            }}
            label={"Save"}
          />
        </Flex>
      )}
    </>
  );
};
