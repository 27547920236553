import { Finding } from "../../../types/Finding";
import { Project } from "../../../types/Project";
import { AssignedToItem } from "./items/AssignedToItem";
import { DateItem } from "./items/DateItem";
import { DiscoverOnItem } from "./items/DiscoverOnItem";
import { ExploitabilityItem } from "./items/ExploitabilityItem";
import { ImpactItem } from "./items/ImpactItem";
import { IssuedByItem } from "./items/IssuedByItem";
import { MitigationComplexityItem } from "./items/MitigationComplexityItem";
import { CVSSItem } from "./items/CVSSItem";
import { SeverityItem } from "./items/SeverityItem";
import { SlaItem } from "./items/SlaItem";
import { StatusItem } from "./items/StatusItem";
import { ScannerItem } from "./items/ScannerItem";
import { Flex } from "../../../components/layouts/flex/Flex";

type Props = {
  finding: Finding | undefined | null;
  project: Project | undefined;
};

export const FindingHeaderItems = (props: Props) => {
  const { finding, project } = props;
  const widthOfElement = "100%";
  return (
    <>
      <Flex align="center" gap="16px" w100>
        <DateItem finding={finding} headerStyle="sub" width={widthOfElement} />
        <SlaItem finding={finding} headerStyle="sub" width={widthOfElement} />
        <StatusItem finding={finding} width={widthOfElement} />
        <SeverityItem
          finding={finding}
          headerStyle="sub"
          width={widthOfElement}
          noSeparator
        />
      </Flex>
      <Flex align="center" gap="16px" w100>
        <AssignedToItem finding={finding} width={widthOfElement} />
        <IssuedByItem finding={finding} width={widthOfElement} />
        <ExploitabilityItem finding={finding} width={widthOfElement} />
        <CVSSItem finding={finding} width={widthOfElement} noSeparator />
      </Flex>
      <Flex align="center" gap="16px" w100>
        <DiscoverOnItem
          project={project}
          width={widthOfElement}
          isAutomated={!!finding?.is_automated}
        />
        <ImpactItem finding={finding} />
        <MitigationComplexityItem finding={finding} width={widthOfElement} />
        <ScannerItem finding={finding} width={widthOfElement} noSeparator />
      </Flex>
    </>
  );
};
